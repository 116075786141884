import React from 'react';
import {  useRoutes } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import './App.scss';
import addIcons from './actions/addIcons';
import Login from './views/Login/Login';
import ForgotPassword from './views/ForgotPassword/ForgotPassword';
import ResetPassword from './views/ResetPassword/ResetPassword';
import Dashboard from './views//Dashboard/Dashboard';
import Classroom from './views/Classroom/Classroom';
import Classrooms from './views/Classrooms/Classrooms';
import Teachers from './views/Teachers/Teachers';
import Students from './views/Students/Students';
import GuestLayout from './views/Layouts/GuestLayout';
import SuperAdminLayout from './views/Layouts/SuperAdminLayout';
import AdminLayout from './views/Layouts/AdminLayout';
import Users from './views/Admin/AccessControl/Users/Users';
import { hasPermission } from './utils/user';
import { PERMISSIONS } from './config';

addIcons();


const routes = (isSignedIn, permissions) => [
  {
    element: <GuestLayout />,
    children: [
      { 
        path: '/login', 
        exact: true, 
        name: 'Login', element: !isSignedIn ? <Login /> : <Navigate to="/" /> 
      },
      { 
        path: '/forgot-password', 
        exact: true, name: 'ForgotPassword', 
        element: !isSignedIn ? <ForgotPassword /> : <Navigate to="/" /> 
      },
      { 
        path: '/reset-password', 
        exact: true, 
        name: 'ResetPassword',
        element: !isSignedIn ? <ResetPassword /> : <Navigate to="/" /> 
      },
      { 
        element: <AdminLayout/>,
        children: [
          { 
            path: '/', index: true, 
            exact: true, 
            name: 'Dashboard', 
            element: isSignedIn ? <Dashboard /> : <Navigate to="/login" 
            /> 
          },
          { 
            path: '/classroommanagement/classrooms', 
            index: true, 
            exact: true, 
            element: isSignedIn ? hasPermission(permissions, [PERMISSIONS.VIEW_CLASSROOM]) ? <Classrooms /> : <Navigate to="/" /> : <Navigate to="/login" /> 
          },
          { 
            path: '/classroommanagement/teachers', 
            index: true, 
            exact: true, 
            element: isSignedIn ? hasPermission(permissions, [PERMISSIONS.VIEW_TEACHER]) ? <Teachers /> : <Navigate to="/" /> : <Navigate to="/login" 
            /> 
          },
          { 
            path: '/classroommanagement/students', 
            index: true, 
            exact: true, 
            element: isSignedIn ? hasPermission(permissions, [PERMISSIONS.VIEW_STUDENT]) ? <Students /> : <Navigate to="/" /> : <Navigate to="/login" /> 
          },
          { 
            path: '/classroommanagement/*', 
            name: 'Classroom Management', 
            element: isSignedIn ? <Navigate to="/classroommanagement/classrooms" />  : <Navigate to="/login" 
            />
          },
          { 
            path: '/classroommanagement/classrooms/:id', 
            name: 'Classroom', element: isSignedIn ? hasPermission(permissions, [PERMISSIONS.VIEW_CLASSROOM]) ? <Classroom /> : <Navigate to="/" /> : <Navigate to="/login" />
          },
        ]
      },
      {
        element: <SuperAdminLayout />,
        children: [
          { 
            path: '/admin/accesscontrol/users', 
            name: 'Users', 
            element: isSignedIn ? hasPermission(permissions, [PERMISSIONS.VIEW_PRINCIPAL, PERMISSIONS.VIEW_SCHOOL_MODERATOR, PERMISSIONS.VIEW_TEACHER]) ? <Users /> : <Navigate to="/" /> : <Navigate to="/login" /> 
          },
          { 
            path: '/admin/*', name: 
            'Admin', element: isSignedIn ? <Navigate to="/admin/accesscontrol/users" /> : <Navigate to="/login" /> 
          },
        ]
      }
    ]
  }
];

function App() {
  // applay rbac on routing here
  const isSignedIn = useSelector(state => state.app.isSignedIn);
  const permissions = useSelector(state => state.app.permissions);
  const routing = useRoutes(routes(isSignedIn, permissions))
  return routing;
};

export default App;
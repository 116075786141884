import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';
import { Popover, PopoverBody } from 'reactstrap';
import Avatar from 'react-avatar';
import { APIENDPOINT, axiosInstance } from '../../config';
import { logout} from '../../actions/appSlice';
import logoAdmin from '../../assets/img/logo_admin.png';
import iconUser from '../../assets/img/icon-identity-mgt_active.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function SuperAdminLayout() {

    const dispatch = useDispatch()
  
    const token = useSelector(state => state.app.token);
    const role = useSelector(state => state.app.role);
    const { firstname, lastname } = useSelector(state => state.app.user);
  
    const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);
    const [isLoggingOut, sestIsLoggingOut] = useState(false);
  
    const logOut = async() => {
      try {
        sestIsLoggingOut(true);
        await axiosInstance({
          url: `${APIENDPOINT}/logout`,
          method: 'POST',
          headers: { 
            'Authorization': `Bearer ${token}`
          }
        });
  
        toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
          <div>
            <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
          </div>
          <div>
            <h6 className="fw-bold mb-0 text-estorya">Successfully Logged Out</h6>
          </div>
        </div>);
      } catch(e) {
        console.log(e);
      } finally {
        dispatch(logout());
        sestIsLoggingOut(false);
      }
    };
  
    return (
      <div id="main" style={{background:'linear-gradient(to bottom, #123E14 0%, #102111 100%)'}}>
        <nav className="d-flex" style={{ height: '75px' }}>
          <div className="container-fluid d-flex flex-grow-1">
            <div className="row no-gutters d-flex flex-grow-1 align-items-center">
              <div className="col-2 h-100 d-flex align-items-center">
                <div className="d-inline-block">
                  <NavLink to="/admin" className="navbar-brand">
                    <img alt="eStorya: Administration" src={logoAdmin} width="280" />
                  </NavLink>
                </div>
              </div>
              <div className="col d-flex align-items-center justify-content-end h-100 bg-light">
                <button type="button" id="userPopoverButton" className="btn">
                  <Avatar name={`${firstname} ${lastname}`} size="30" round={true} />
                  <FontAwesomeIcon icon="chevron-down" size="sm" className="ms-2" />
                </button>
                <Popover placement="bottom-end" isOpen={isUserPopoverOpen} target="userPopoverButton" toggle={() => { setIsUserPopoverOpen(!isUserPopoverOpen); }} trigger="legacy">
                  <PopoverBody style={{ width: '280px' }}>
                    <div className="d-flex align-items-start">
                      <div className="me-2">
                        <Avatar name={`${firstname} ${lastname}`} size="45" round={true} />
                      </div>
                      <div>
                        <h6 className="mb-1">Hello {firstname}!</h6>
                        <small className='text-estorya'>{role}</small>
                        <p>Welcome to eStorya</p>
                      </div>
                    </div>
                    <div className="d-flex align-items-start">
                      <NavLink to="/" className="btn btn-sm btn-estorya me-2">Back to eStorya </NavLink><br/>
                      <button type="button" className="btn btn-sm btn-danger" disabled={isLoggingOut} onClick={logOut}>Logout</button>
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
          </div>
        </nav>
        <div className="container-fluid d-flex" style={{ height: `calc(100vh - 75px)` }}>
          <div className="row no-gutters flex-grow-1">
            <div className="col-2 p-0">
              <nav className="nav flex-column mt-4 main-nav">
                <li className="nav-item px-3 mb-3">
                  <NavLink to="/" className="btn btn-light text-estorya w-100 "><FontAwesomeIcon icon="chevron-left" className="me-2" size="xs" /> Back to eStorya</NavLink>
                </li>
                <li className="nav-item">
                  <span className="text-white nav-link fw-bold text-uppercase py-2">Access Control</span>
                </li>
                <li className="nav-item">
                  <NavLink to="/admin/accesscontrol" className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
                    <img alt="" src={iconUser} className="me-2" />
                    <span>Users</span>
                  </NavLink>
                </li>
              </nav>
            </div>
            <div className="col bg-light p-0" style={{ borderRadius: '0px' }}>
              <div className="main">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

  export default SuperAdminLayout;
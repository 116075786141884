import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import iconExit from './../Students/AddStudent/icon-exit.svg';
import toast from 'react-hot-toast';
import { APIENDPOINT, axiosInstance } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function DeleteStudentModal({toDeleteStudentModalIsOpen, setToDeleteStudentModalIsOpen, toDeleteStudent, setToDeleteStudent, token, setDeletedStudent}) {
    const [toDeleteStudentModalIsDeleting, setToDeleteStudentModalIsDeleting] = useState(false)
  
    const deleteStudent = async () => {
        setToDeleteStudentModalIsDeleting(true)
        try {
            await axiosInstance.delete(
                `${APIENDPOINT}/users/unassign/${toDeleteStudent.id}`,
                {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
                }
            );
        
            setDeletedStudent(toDeleteStudent)
            toast.custom(
                <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
                    <div>
                        <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
                    </div>
                    <div>
                        <h6 className="fw-bold mb-0 text-estorya">Successfully Remove a Student</h6>
                    </div>
                </div>
            );
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.custom(
                <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
                    <div>
                        <FontAwesomeIcon icon="times-circle" size="2x" className="me-3 text-danger" />
                    </div>
                    <div>
                        <h6 className="fw-bold mb-0 text-danger">There's an error on deleting the Student</h6>
                        <p className="mb-0">Please try again.</p>
                    </div>
                </div>
            );
        }
        setToDeleteStudentModalIsDeleting(false)
        setToDeleteStudent(null)
        setToDeleteStudentModalIsOpen(false)
    }

    return (
        <Modal isOpen={toDeleteStudentModalIsOpen} toggle={() => { setToDeleteStudentModalIsOpen(!toDeleteStudentModalIsOpen); }} centered={true} contentClassName="border-0 shadow">
        <div className="modal-content border-0 overflow-hidden">
          <div className="modal-header border-0">
            <h6 className="modal-title fw-bold">Remove a Student?</h6>
            <button type="button" className="position-absolute btn btn-light btn-sm bg-transparent border-0" style={{ right: '10px', top: '10px' }} onClick={() => setToDeleteStudentModalIsOpen(false)}><img alt="" src={iconExit} /></button>
          </div>
          <div className="modal-body">
            <p className="mb-0">
              You are about to remove a student from this School. Are you sure you want to proceed?
            </p>
          </div>
          <div className="modal-footer border-0 d-flex">
            <button type="button" className="btn btn-light" onClick={() => { setToDeleteStudentModalIsOpen(false); }} disabled={toDeleteStudentModalIsDeleting}>Cancel</button>
            <button type="button" className="btn btn-danger" onClick={() => { deleteStudent(); }} disabled={toDeleteStudentModalIsDeleting}>Proceed</button>
          </div>
        </div>
      </Modal>
    )
}

export default DeleteStudentModal;
import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import toast from 'react-hot-toast';
import AddStudentOptions from './AddStudentOptions';
import SingleCreationForm from './SingleCreation/SingleCreationForm';
import SingleCreationResult from './SingleCreation/SingleCreationResult';
import BulkCreationForm from './BulkCreation/BulkCreationForm';
import BulkCreationErrorResult from './BulkCreation/BulkCreationErrorResult';
import { APIENDPOINT, axiosInstance } from '../../../config';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExistingStudentForm from './ExistingStudent/ExistingStudentForm';
import ExisitingStudentResult from './ExistingStudent/ExisitingStudentResult';
import { toRoman } from '../../../utils/utls';

const ADDSTUDENTMODES = {
    ADDSINGLE:'add-single-record',
    ADDBULK: 'add-bulk-record',
    ADDEXISTING: 'add-existing-record'
}

function AddStudentModal({studentModalIsOpen, setStudentModalIsOpen, setShowAddStudentNotificationPanel, setSingleCreationStudent, setBulkCreationStudents, setExistingStudentAdded}) {

    const token = useSelector(state => state.app.token);
    const [selectedOption, setSelectedOption] = useState(ADDSTUDENTMODES.ADDSINGLE)
    const [showForm, setShowForm] = useState(false)
    const [showCreationResult, setShowCreationResult] = useState(false)
    const [showCreationErrorResult, setShowCreationErrorResult] = useState(false)
    const [singleCreationResult, setSingleCreationResult] = useState({})
    const [bulkCreationErrorResult, setBulkCreationErrorResult] = useState([]);
    const [exisitingStudentResult, setExistingStudentResult] = useState([])
    const [classroomOptions, setClassroomOptions] = useState([])

    useEffect(()=>{
        const fetchClassrooms = () =>{
            axiosInstance.get(`${APIENDPOINT}/classrooms?page=1&limit=-1&direction=asc&sortBy=grade_level_id`, { headers: { 'Authorization': `Bearer ${token}`} }).then((res)=>{
                if(res?.data?.data?.data){
    
                    let classroomOptionsTemp = []
                    res?.data?.data?.data.map((classroom)=>{
                        classroomOptionsTemp.push({
                            value: classroom.id , 
                            label:`Grade ${classroom.grade_level_id ? toRoman(classroom.grade_level_id) : '-'} - ${classroom.name}`
                        })
                        return true
                    })
                    setClassroomOptions(classroomOptionsTemp)
                }
            })
        }
        
        fetchClassrooms();
    },[token])

    const closeModal = () => {
        setStudentModalIsOpen(false)
        setSelectedOption(ADDSTUDENTMODES.ADDSINGLE)
        setShowForm(false)
        setShowCreationResult(false)
        setShowCreationErrorResult(false)
        setSingleCreationResult({})
        setBulkCreationErrorResult({})
    }

    const showResult = (creationResult) => {
        if(selectedOption === ADDSTUDENTMODES.ADDSINGLE) {
            setSingleCreationResult(creationResult)
            setSingleCreationStudent(creationResult)
        }

        if(selectedOption === ADDSTUDENTMODES.ADDEXISTING) {
            setExistingStudentResult(creationResult)
            setExistingStudentAdded(creationResult)
        }
        
        setShowForm(false)
        setShowCreationErrorResult(false)
        setShowCreationResult(true)
    }

    const showErrorResult = (errorResult) => {
        if(selectedOption === ADDSTUDENTMODES.ADDBULK) {
            setBulkCreationErrorResult(errorResult)
        }
        setShowForm(false)
        setShowCreationResult(false)
        setShowCreationErrorResult(true)
    }

    const reUpload = () => {
        setSelectedOption(ADDSTUDENTMODES.ADDBULK)
        setShowForm(true)
        setShowCreationResult(false)
        setShowCreationErrorResult(false)
        setSingleCreationResult({})
        setBulkCreationErrorResult({})
    }

    const showAddStudentNotificationPanel = (creationResult) => {
        setShowAddStudentNotificationPanel(true)
        setBulkCreationStudents(creationResult)
        closeModal()
        toast.custom(
            <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
                <div>
                    <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
                </div>
                <div>
                    <h6 className="fw-bold mb-1 text-estorya">Successfully created accounts</h6>
                    <p className="mb-0">You created <strong>{creationResult.length}</strong> students accounts to access Estorya</p>
                </div>
            </div>
        );
    }

    return (
        <Modal isOpen={studentModalIsOpen} contentClassName='modal-size' centered={true}>
            <div className="modal-content border-0 overflow-hidden">
                {(!showForm && !showCreationResult && !showCreationErrorResult)&&
                    <AddStudentOptions 
                        ADDSTUDENTMODES={ADDSTUDENTMODES}
                        selectedOption={selectedOption}
                        setSelectedOption={setSelectedOption}
                        setShowForm={setShowForm}
                        closeModal={closeModal}
                    />
                }
                {(showForm && selectedOption === ADDSTUDENTMODES.ADDSINGLE)&&
                    <SingleCreationForm
                        ADDSTUDENTMODES={ADDSTUDENTMODES}
                        closeModal={closeModal}
                        showResult={showResult}
                        setSelectedOption={setSelectedOption}
                        classroomOptions={classroomOptions}
                        
                    />
                }

                {(showCreationResult && selectedOption === ADDSTUDENTMODES.ADDSINGLE) &&
                    <SingleCreationResult
                        closeModal={closeModal}
                        singleCreationResult={singleCreationResult}
                    />
                }

                {(showForm && selectedOption === ADDSTUDENTMODES.ADDBULK)&&
                    <BulkCreationForm
                        ADDSTUDENTMODES={ADDSTUDENTMODES}
                        closeModal={closeModal}
                        showErrorResult={showErrorResult}
                        setSelectedOption={setSelectedOption}
                        showAddStudentNotificationPanel={showAddStudentNotificationPanel}
                        classroomOptions={classroomOptions}
                    />
                }

                {(showCreationErrorResult && selectedOption === ADDSTUDENTMODES.ADDBULK)&&
                    <BulkCreationErrorResult
                        closeModal={closeModal}
                        reUpload={reUpload}
                        bulkCreationErrorResult={bulkCreationErrorResult}
                    />
                }

                {(showForm && selectedOption === ADDSTUDENTMODES.ADDEXISTING)&&
                    <ExistingStudentForm
                        closeModal={closeModal}
                        classroomOptions={classroomOptions}
                        showResult={showResult}
                    />
                }
                
                {(showCreationResult && selectedOption === ADDSTUDENTMODES.ADDEXISTING) &&
                    <ExisitingStudentResult
                        closeModal={closeModal}
                        exisitingStudentResult={exisitingStudentResult}
                    />
                }

        </div>
        </Modal>
    )
}

export default AddStudentModal;
import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {toast } from 'react-hot-toast';
import { Popover, PopoverBody } from 'reactstrap';
import Avatar from 'react-avatar';
import { APIENDPOINT, axiosInstance, PERMISSIONS } from '../../config';
import { logout, collapseSidebar, expandSidebar } from '../../actions/appSlice';
import hamburger from '../../assets/img/hamburger.svg';
import logo from '../../assets/img/logo_white.svg';
import iconDashboard from '../../assets/img/icon-dashboard-normal-black.svg';
import iconClassroom from '../../assets/img/icon-classroom-solid.svg';
import iconEstorya from "../../assets/img/icon-estorya.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ROLES } from '../../config';
import { hasPermission } from '../../utils/user';

function AdminLayout() {

    const dispatch = useDispatch()
  
    const token = useSelector(state => state.app.token);
    const permissions = useSelector(state => state.app.permissions);
    const { firstname, lastname } = useSelector(state => state.app.user);
    const role = useSelector(state => state.app.role);
  
    const isSidebarOpen = useSelector(state => state.app.isSidebarOpen);
    const expandCollapseSidebar = () => {
      isSidebarOpen ? dispatch(collapseSidebar()) : dispatch(expandSidebar());
    };
  
    const [isUserPopoverOpen, setIsUserPopoverOpen] = useState(false);
    const [isLoggingOut, sestIsLoggingOut] = useState(false);
  
    const logOut = async() => {
      try {
        sestIsLoggingOut(true);
        await axiosInstance({
          url: `${APIENDPOINT}/logout`,
          method: 'POST',
          headers: { 
            'Authorization': `Bearer ${token}`
          }
        });
        toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
          <div>
            <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
          </div>
          <div>
            <h6 className="fw-bold mb-0 text-estorya">Successfully Logged Out</h6>
          </div>
        </div>);
        
        dispatch(logout());
      } catch(e) {
        console.log(e);
      } finally {
        dispatch(logout());
        sestIsLoggingOut(false);
      }
    };
  
    const [offCanvasNavIsOpen, setOffCanvasIsOpen] = useState(false);
  
    function renderNav() {
      return (
        <nav className={`main-nav nav flex-column  ${isSidebarOpen ? 'mt-4' : ''}`}>
          <li className="nav-item d-none d-sm-none d-md-block d-lg-block d-xl-block">
            {!isSidebarOpen &&
              <NavLink to="/" className={`btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center text-estorya`} style={{backgroundColor:'transparent'}}>
                <img alt="Project estorya" src={iconEstorya} width="30" style={{filter:'none'}}/>
              </NavLink>
            }
            <NavLink to="/" className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
              <img alt="" src={iconDashboard} className="me-2" />
              {isSidebarOpen &&
                <span>Dashboard</span>
              }
              
            </NavLink>
            {hasPermission(permissions, [PERMISSIONS.VIEW_CLASSROOM, PERMISSIONS.VIEW_STUDENT]) &&
              <NavLink to="/classroommanagement" className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
                <img alt="" src={iconClassroom} className="me-2" />
                {isSidebarOpen &&
                  <span>
                    {role == ROLES.TEACHER ? (
                      'Student Management'
                    ) : (
                      'Classroom Management'
                    )}
                  </span>
                }
              </NavLink>
            }
            {/* {[ROLES.SUPERADMIN, ROLES.SCHOOLMODERATOR].includes(role) &&
              <NavLink disabled to="/archive"  onClick={(e) => e.preventDefault()} className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
                <img alt="" src={iconArchive} className="me-2" />
                {isSidebarOpen &&
                  <span>Archive</span>
                }
              </NavLink>
            } */}
          </li>
  
          {/* for small screen size */}
          {isSidebarOpen &&
            <li className="nav-item d-sm-block d-md-none d-lg-none d-xl-none">
  
              <NavLink to="/" className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
                <img alt="" src={iconDashboard} className="me-2" />
                <span>Dashboard</span>
              </NavLink>
              {hasPermission(permissions, [PERMISSIONS.VIEW_CLASSROOM]) &&
                <NavLink to="/classroommanagement" className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
                  <img alt="" src={iconClassroom} className="me-2" />
                  <span>
                    {role == ROLES.TEACHER ? (
                      'Student Management'
                    ) : (
                      'Classroom Management'
                    )}
                  </span>
                </NavLink>
              }
              {/* {[ROLES.SUPERADMIN, ROLES.SCHOOLMODERATOR].includes(role) &&
                <NavLink disabled to="/archive"  onClick={(e) => e.preventDefault()} className={({ isActive }) => `btn btn-outline-estoryamenuactive rounded-0 text-start nav-link py-3 d-flex align-items-center ${isActive ? 'active text-estorya' : 'text-white'}`}>
                  <img alt="" src={iconArchive} className="me-2" />
                  <span>Archive</span>
                </NavLink>
              } */}
             
            </li>
          }
        </nav>
      )
    }
  
    return (
      <div id="main">
        <nav className="d-flex" style={{ height: '75px' }}>
          <div className="container-fluid d-flex flex-grow-1">
            <div className="row no-gutters d-flex flex-grow-1 align-items-center">
              <div className="col-2 d-flex align-items-center justify-content-between">
                {isSidebarOpen && 
                  <>
                    <div className="d-inline-block px-3 d-none d-sm-none d-md-block d-lg-block d-xl-block">
                      <NavLink to="/" className="navbar-brand">
                        <img alt="Project estorya" src={logo} width="150" />
                      </NavLink>
                    </div>
                    <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                      <button 
                        style={{zIndex:'10', position:'relative'}}
                        type="button" 
                        className="btn px-2" onClick={expandCollapseSidebar}><img alt="hamburger" src={hamburger} /></button>
                    </div>
                  </>
                }
                {!isSidebarOpen && 
                  <div className="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                    <button 
                      style={{zIndex:'10', position:'relative'}}
                      type="button" 
                      className="btn px-2" onClick={expandCollapseSidebar}><img alt="hamburger" src={hamburger} /></button>
                  </div>
                }
  
                {/* for small screen */}
                <div className="d-inline-block px-3  d-sm-block d-md-none d-lg-none d-xl-none">
                  <NavLink to="/" className="navbar-brand">
                      <img alt="Project estorya" src={logo} width="150" />
                    </NavLink>
                </div>
                <div className=" d-sm-block d-md-none d-lg-none d-xl-none">
                  <button 
                    style={{zIndex:'10', position:'relative'}}
                    type="button" 
                    className="btn px-2" onClick={expandCollapseSidebar}><img alt="hamburger" src={hamburger} /></button>
                </div>
              </div>
              <div className="col d-flex align-items-center justify-content-end">
  
                <button type="button" id="userPopoverButton" className="btn">
                  <Avatar name={`${firstname} ${lastname}`} size="30" round={true} />
                  <FontAwesomeIcon icon="chevron-down" size="sm" className="ms-2 text-white" />
                </button>
                <Popover placement="bottom-end" isOpen={isUserPopoverOpen} target="userPopoverButton" toggle={() => { setIsUserPopoverOpen(!isUserPopoverOpen); }} trigger="legacy">
                  <PopoverBody style={{ width: '280px' }}>
                    <div className="d-flex align-items-start">
                      <div className="me-2">
                        <Avatar name={`${firstname} ${lastname}`} size="45" round={true} />
                      </div>
                      <div>
                        <h6 className="mb-1">Hello, <span style={{ textTransform: 'capitalize' }}>{firstname}</span>!</h6>
                        <small className='text-estorya'>{role}</small>
                        <p>Welcome to eStorya</p>
                      </div>
                    </div>
                    
                    <div className="d-flex align-items-start">
                      {hasPermission(permissions, [PERMISSIONS.VIEW_PRINCIPAL, PERMISSIONS.VIEW_SCHOOL_MODERATOR, PERMISSIONS.VIEW_TEACHER]) &&
                        <NavLink to="/admin" className="btn btn-sm btn-estorya me-2">Go To Administration</NavLink>
                      }
                      <button type="button" className="btn btn-sm btn-danger" disabled={isLoggingOut} onClick={logOut}>Logout</button>
                    </div>
                  </PopoverBody>
                </Popover>
              </div>
            </div>
          </div>
        </nav>
        <div className="container-fluid d-flex" style={{ height: `calc(100vh - 75px)` }}>
          <div className="row no-gutters flex-grow-1">
            <div className={`${isSidebarOpen ? 'col-lg-2 col-md-2 col-sm-12': 'col-lg-auto col-md-auto col-sm-12'} p-0 `}>
              {renderNav()}
            </div>
  
            <div className="col bg-white p-0" style={{ borderRadius:'20px 0 0 0'  }}>
              <div className="main">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  };

export default AdminLayout;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import iconExit from './../icon-exit.svg';
import iconCopy from './icon-copy.svg';



function SingleCreationResult ({closeModal, singleCreationResult}) {
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleFinish = () => {
        closeModal();
        toast.custom(
            <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
                <div>
                    <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
                </div>
                <div>
                    <h6 className="fw-bold mb-1 text-estorya">Successfully created an account</h6>
                    <p className="mb-0">You created <strong>1</strong> student account to access Estorya</p>
                </div>
            </div>
        );
    }
    
    return(
        <>
            <div className="modal-header border-0">
                <h6 className="modal-title fw-bold">Successfully created an account</h6>
                <button 
                    type="button" 
                    className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                    style={{ right: '10px', top: '10px' }} 
                    onClick={() => closeModal()}>
                    <img alt="" src={iconExit} />
                </button>
            </div>
            <div className="modal-body">
                <p className='fw-bold'> You have successfully add a student account to access Estorya App.</p>
                <div className='row'>
                    <div className='col'>
                        <p className='text-muted mb-0'>Student Name</p>
                        <p className='fw-bold'>
                            {singleCreationResult?.lastname}, {singleCreationResult?.firstname} {singleCreationResult?.middlename} {singleCreationResult?.extension}
                        </p>
                    </div>
                    <div className='col'>
                        <p className='text-muted mb-0'>Learner Reference Number</p>
                        <p className='fw-bold'>{singleCreationResult?.lrn_id}</p>
                    </div>
                    <div className='col'>
                        <p className='text-muted mb-0'>Email</p>
                        <p className='fw-bold'>{singleCreationResult?.email}</p>
                    </div>  
                </div>
                <p className='fw-bold'>Copy the generated credentials for the student.</p>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='fw-normal text-muted'>Email Address</th>
                                <th className='fw-normal text-muted'>Password</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='d-flex'>
                                        <p className='mb-0 fs-6'>{singleCreationResult?.email}</p>
                                        <img alt='' onClick={()=>copyToClipboard(singleCreationResult?.email)} className='ms-2 cursor-pointer' src={iconCopy}/>
                                    </div> 
                                </td>
                                <td>
                                    <div className='d-flex'>
                                        <p className='mb-0 fs-6'>{singleCreationResult?.password}</p>
                                        <img alt='' onClick={()=>copyToClipboard(singleCreationResult?.password)} className='ms-2 cursor-pointer' src={iconCopy}/>
                                    </div> 
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> 
            <div className="modal-footer d-flex">
                <button onClick={()=>handleFinish()} type="button" className="btn btn-estorya" >Finished</button>
            </div>
        </>
    )
}

export default SingleCreationResult;
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';

function GuestLayout() {
  return (
    <>
      <Toaster position='top-right' />
      <Outlet />
    </>
  )
};

export default GuestLayout;
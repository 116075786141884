import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconExit from './../icon-exit.svg';

function BulkCreationErrorResult ({closeModal, reUpload, bulkCreationErrorResult}) {
    const [errors, setErrors] = useState([])

    useEffect(()=>{
        let errorsTemp = []
        if(bulkCreationErrorResult?.duplicate_lrn_ids_in_csv) {
            bulkCreationErrorResult.duplicate_lrn_ids_in_csv.map((student)=>{
                errorsTemp.push({
                    lrn:student?.LRN_ID,
                    name: student?.LASTNAME + ", " + student?.FIRSTNAME + " " + student?.MIDDLENAME + " " + student?.EXTENSION,
                    remark: 'Duplicate LRN in CSV'
                })

                return true;
            })
        }

        if(bulkCreationErrorResult?.duplicate_lrn_ids_in_db) {
            bulkCreationErrorResult.duplicate_lrn_ids_in_db.map((student)=>{
                errorsTemp.push({
                    lrn:student?.lrn_id,
                    name: student?.lastname + ", " + student?.firstname + " " + student?.middlename + " " + student?.extension,
                    remark: 'Duplicate LRN'
                })
                return true;
            })
        }

        if(bulkCreationErrorResult?.duplicate_emails_in_db) {
            bulkCreationErrorResult.duplicate_emails_in_db.map((student)=>{
                errorsTemp.push({
                    lrn:student?.lrn_id,
                    name: student?.lastname + ", " + student?.firstname + " " + student?.middlename + " " + student?.extension,
                    remark: 'Duplicate Email'
                })
                return true;
            })
        }

        if(bulkCreationErrorResult?.duplicate_emails_in_csv) {
            bulkCreationErrorResult.duplicate_emails_in_csv.map((student)=>{
                errorsTemp.push({
                    lrn:student?.lrn_id,
                    name: student?.lastname + ", " + student?.firstname + " " + student?.middlename + " " + student?.extension,
                    remark: 'Duplicate Email'
                })
                return true;
            })
        }

        setErrors(errorsTemp)
    },[bulkCreationErrorResult])

    return(
        <>
            <div className="modal-header border-0">
                <h6 className="modal-title fw-bold">Error Found</h6>
                <button 
                    type="button" 
                    className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                    style={{ right: '10px', top: '10px' }} 
                    onClick={() => closeModal()}>
                    <img alt="" src={iconExit} />
                </button>
            </div>
            <div className="modal-body">
            <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm">
                <div>
                    <FontAwesomeIcon icon="exclamation-circle" size="2x" className="me-3 text-danger" />
                </div>
                <div>
                  <p className="mb-0">We found existing Students and duplicate LRNs in the CSV file listed below. Please remove the student in the file to proceed.</p>
                </div>
              </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='fw-bold'>LRN</th>
                                <th className='fw-bold'>New Student Name</th>
                                <th className='fw-bold'>Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            {errors.map((error, key)=>{
                                return(
                                    <tr key={key}>
                                        <td>
                                            <p className={`mb-0 text-danger fw-bold`}>{error.lrn}</p>
                                        </td>
                                        <td>
                                            <p className='mb-0'>{error.name}</p>
                                        </td>
                                        <td>
                                            <p className='mb-0'>{error.remark}</p>
                                        </td>
                                    </tr>
                                )
                            })}
                          
                        </tbody>
                    </table>
                </div>
            </div> 
            <div className="modal-footer d-flex">
                <button onClick={reUpload}  type="button" className="btn btn-estorya" >Re-upload</button>
            </div>
        </>
    )
}

export default BulkCreationErrorResult;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import Avatar from 'react-avatar';
import Lottie from 'react-lottie-player';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Modal } from 'reactstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import toast from 'react-hot-toast';
import { DebounceInput } from 'react-debounce-input';

// import { faker } from '@faker-js/faker';

import empty from '../../assets/img/empty.png';
import DataFilters from '../../utils/DataFilters';
import iconActions from './icon-actions.svg';
import { toRoman } from '../../utils/utls';
import { APIENDPOINT, axiosInstance, PERMISSIONS, ROLES, SCHOOLID } from '../../config';
import loadingAnimation from '../../assets/lottie/loadingAnimation.json';
import iconExit from './icon-exit.svg';
import iconPersonSearch from './icon-person-search.svg';
import { hasPermission } from '../../utils/user';
import ClassroomManagementLayout from '../Layouts/ClassroomManagementLayout';



const ADDCLASSROOMMODES = {
  ADD: 'add',
  EDIT: 'edit'
};

const defaultEmptyClassroom = {
  mode: ADDCLASSROOMMODES.ADD,
  id: null,
  name: '',
  currentName: '',
  grade_level_id: 1,
  teacher: null,
  students: [],
  currentStudents: [],
  teachers:[]
};

const CustomTeachersLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      {/* <img alt="" src={iconRbac} className="me-1" /> */}
      <span className="align-middle">{props.children}</span>
    </components.MultiValueLabel>
  );
};

const SelectedValuesContainer = ({ isDisabled, getValue, ...props }) => {
  const { getOptionValue, formatOptionLabel, removeStudent } = props.selectProps;

  const getValueLabel = (opt) => formatOptionLabel?.(opt, "value") || opt.label;
  const getKey = (opt, index) => `${getOptionValue(opt)}-${index}`;

  const toMultiValue = (opt, index) => (
    <components.MultiValue
      getValue={getValue}
      {...props}
      components={{
        Container: components.MultiValueContainer,
        Label: components.MultiValueLabel,
        Remove: components.MultiValueRemove
      }}
      //isFocused={isOptionFocused}
      isDisabled={isDisabled}
      key={getKey(opt, index)}
      index={index}
      removeProps={{
        onClick: () => removeStudent(opt),
        onTouchEnd: () => removeStudent(opt),
        onMouseDown: (e) => {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      data={opt}
    >
      {getValueLabel(opt)}
    </components.MultiValue>
  );

  return (
    <div className="card my-2 d-flex" style={{ border: '1px solid #e4e7ea', minHeight: '200px' }}>
      {getValue().length === 0 ?
        <div className="text-center flex-grow-1 d-flex align-items-center justify-content-center">
          <div>
            <h5 className="fw-bold">Add students to this classroom</h5>
            <p className="mb-0">
            Type in your student’s name on the search box above.<br/>
            Select the name to add it to this list.
            </p>
          </div>
        </div>
        :
          <div className="flex-grow-1 flex-wrap p-2">
            {getValue().map(toMultiValue)}
          </div>
        }
    </div>
  );
};

const SelectContainer = ({
  children,
  className,
  innerProps,
  isFocused,
  ...commonProps
}) => {
  const selectContainerProps = {
    ...commonProps
  };

  return (
    <components.SelectContainer
      className={className}
      innerProps={innerProps}
      isFocused={isFocused}
      {...selectContainerProps}
    >
      {children}
      <SelectedValuesContainer {...commonProps} />
    </components.SelectContainer>
  );
};

const ValueContainer = ({children}) => {
  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon="fa-search" className="d-inline-block px-2 text-muted" />
      {children}
    </div>
  ) 
};

function ClassroomListItem({ classroom, toEditClassroom, toDeleteClassroom }) {

  // const token = useSelector(state => state.app.token);
  const role = useSelector(state => state.app.role);
  const permissions = useSelector(state => state.app.permissions);
  const classroomStudentsTruncated = classroom.students && classroom.students.length > 3 ? classroom.students.slice(0, 3) : classroom.students;

  return (
    <tr className={`border-light`}>
      <td className="align-middle text-right">
        <NavLink to={`/classroommanagement/classrooms/${classroom.id}`} className="text-estorya text-capitalize text-decoration-none fw-bold">Grade {classroom.grade_level_id ? toRoman(classroom.grade_level_id) : '-'} - {classroom.name}</NavLink>
        {classroom.archived && <span className="ms-2 text-muted fw-bold">(Archived)</span>}
      </td>
      <td className="align-middle text-center">
        Grade {classroom.grade_level_id ? toRoman(classroom.grade_level_id ) : '-'}
      </td>
      <td className="align-middle text-center text-capitalize">
        {classroom.name}
      </td>
      <td className="align-middle text-capitalize">
        {classroom.teachers.map((teacher, idx) => {
          return (<span key={idx} className='rounded p-2 border border-light mr-2'>{teacher.firstname} {teacher.lastname}</span>)
        })}
        {classroom.teachers.length <= 0 &&
            <>{classroom.teacher ? <span className='rounded p-2 border border-light mr-2'>{classroom.teacher?.firstname} {classroom.teacher?.lastname}</span> : '-'}</>
        }
      </td>
      <td className="align-middle text-capitalize">
        {classroomStudentsTruncated.map((student, idx) => {
          return <Avatar key={`classroom-${classroom.id}-student-${idx}`} name={student ? `${student.firstname} ${student.lastname}` : ''} src={student.picture} round={true} size={30} style={{ marginLeft: idx === 0 ? '0px' : '-5px' }}/>
        })}
        {classroomStudentsTruncated.length < classroom.students.length &&
          <div className="d-inline-block align-middle rounded-pill bg-estoryadark text-white text-center" style={{ width: 30, height: 30, marginLeft: '-5px', lineHeight: '30px', fontSize: '10px' }} title={`${classroom.students.length - classroomStudentsTruncated.length}+ more students`}>{classroom.students.length - classroomStudentsTruncated.length}+</div>
        }
        {classroomStudentsTruncated.length === 0 && 
          <button className="btn btn-sm text-start border-0 text-estorya rounded-0" onClick={() => { toEditClassroom(classroom); }}><img src={iconPersonSearch} alt="Assign Students" className="me-1" />Assign Students</button>
        }
      </td>
      <td className="align-middle text-capitalize">
        {classroom.created_at ? moment(classroom.created_at).format('DD-MMM-YYYY') : '-'}
      </td>
      <td className="align-middle">
        <UncontrolledDropdown>
          <DropdownToggle color="outline-light">
            <img src={iconActions} alt="options" />
          </DropdownToggle>
          <DropdownMenu className="py-1" style={{ minWidth: '150px' }}>
            <NavLink to={`/classroommanagement/classrooms/${classroom.id}`} className="px-3 py-1 btn w-100 text-start border-0 btn-outline-dark rounded-0">View</NavLink>
            {hasPermission(permissions, [PERMISSIONS.UPDATE_CLASSROOM]) &&
              <button 
                className="px-3 py-1 btn w-100 text-start border-0 btn-outline-dark rounded-0" 
                onClick={() => { toEditClassroom(classroom); }}>
                  
                  {role === ROLES.TEACHER ? (
                    'Assign Students'
                  ): (
                    'Update'
                  )

                  }
                </button>
            }
        
            {hasPermission(permissions, [PERMISSIONS.DELETE_CLASSROOM]) &&
              <button className="px-3 py-1 btn w-100 text-start border-0 btn-outline-danger rounded-0" onClick={() => { toDeleteClassroom(classroom); }}>Archive</button>
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr> 
  )
};

function Classrooms() {

  const token = useSelector(state => state.app.token);
  const role = useSelector(state => state.app.role);
  const permissions = useSelector(state => state.app.permissions);
  const location = useLocation()
  // const [classrooms, ] = useState(sampleClassrooms.map(classroom => ({ ...classroom, checked: false })));
  const [classrooms, setClassrooms] = useState([]);
  const [loadingClassrooms, setLoadingClassrooms] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [classroomsPerPage, setClassroomsPerPage] = useState(20);
  const [search, setSearch] = useState('');
  const [classroomModalIsOpen, setClassroomModalIsOpen] = useState(location.state?.classroomModalIsOpen ? location.state.classroomModalIsOpen : false);
  const [classroomModalIsSaving, setClassroomModalIsSaving] = useState(false);
  const [classroomModalValues, setClassroomModalValues] = useState(defaultEmptyClassroom);
  const [teachers, setTeachers] = useState([]);
  const [loadingTeachers, setLoadingTeachers] = useState(false);
  const [students, setStudents] = useState([]);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [toArchiveClassroom, setToArchiveClassroom] = useState(null);
  const [toArchiveClassroomModalIsOpen, setToArchiveClassroomModalIsOpen] = useState(false);
  const [toArchiveClassroomModalIsArchiving, setToArchiveClassroomModalIsArchiving] = useState(false);
  const [sortBy, setSortBy] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filters, setFilters] = useState({
    classroomsFilters: [],
    gradeLevelFilters: [],
    teachersFilters: [],
  });
  const [classroomsFilters, setClassroomsFilters] = useState([]);
  const [loadingClassroomsFilters, setLoadingClassroomsFilters] = useState(false);
  const [gradeLevelFilters, ] = useState([
    {id: 1, name: `Grade ${toRoman(1)}`},
    {id: 2, name: `Grade ${toRoman(2)}`},
    {id: 3, name: `Grade ${toRoman(3)}`},
    {id: 4, name: `Grade ${toRoman(4)}`},
    {id: 5, name: `Grade ${toRoman(5)}`},
    {id: 6, name: `Grade ${toRoman(6)}`},
  ]);
  const [teachersFilters, setTeachersFilters] = useState([]);
  const [loadingTeachersFilters, setLoadingTeachersFilters] = useState(false);

  const fetchClassrooms = useCallback(async () => {
    try {
      setLoadingClassrooms(true);

      let classroomFilters = '';
      if(filters.classroomsFilters.length > 0) {
        classroomFilters = filters.classroomsFilters.map(obj => obj.id).join(',');
      }

      let gradeLevelFilters = '';
      if(filters.gradeLevelFilters.length > 0) {
        gradeLevelFilters = filters.gradeLevelFilters.map(obj => obj.id).join(',');
      }

      let teachersFilters = '';
      if(filters.teachersFilters.length > 0) {
        teachersFilters = filters.teachersFilters.map(obj => obj.id).join(',');
      }

      const response = await axiosInstance.get(`${APIENDPOINT}/classrooms?page=${currentPage}&limit=${classroomsPerPage}&sortBy=${sortBy}&direction=${sortDirection}${classroomFilters.length > 0 ? `&classroomIds=${classroomFilters}` : ''}${gradeLevelFilters.length > 0 ? `&gradeLevelIds=${gradeLevelFilters}` : ''}${teachersFilters.length > 0 ? `&teacherIds=${teachersFilters}` : ''}${search.length > 0 ? `&key=${search}` : ''}`, { headers: { 'Authorization': `Bearer ${token}`} });

      setLoadingClassrooms(false);
      setClassrooms(response.data.data.data.map(classroom => {
        return {
          ...classroom,
          students: classroom.students.map(student => ({ ...student, value: student.id, label: `${student.firstname} ${student.lastname}` }))
        }
      }));
      setTotal(response.data.data.total);
    } catch (error) {
      setLoadingClassrooms(false);
      console.error('Error fetching data:', error);
    }
  }, [currentPage, classroomsPerPage, sortBy, sortDirection, search, filters.classroomsFilters, filters.gradeLevelFilters, filters.teachersFilters, token]);

  useEffect(() => {
    if(currentPage && classroomsPerPage && token) {
      fetchClassrooms();
    }
  }, [currentPage, classroomsPerPage, token, fetchClassrooms]);

  const pageCount = Math.ceil(total / classroomsPerPage);

  const handlePageClick = (i) => {
    setCurrentPage(i.selected + 1);
  };

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        setLoadingTeachers(true);
        setLoadingTeachersFilters(true);
        
        const response = await axiosInstance.get(`${APIENDPOINT}/users?role=Teacher&page=${1}&limit=${100}`, { headers: { 'Authorization': `Bearer ${token}`} });

        setLoadingTeachers(false);
        setLoadingTeachersFilters(false);
        setTeachers(response.data.data.data.map(teacher => ({...teacher, value: teacher.id, label: `${teacher.firstname} ${teacher.lastname}` })));
        setTeachersFilters(response.data.data.data.map(teacher => ({...teacher, name: `${teacher.firstname} ${teacher.lastname}` })).sort((teacherA, teacherB) => { return teacherA.name.localeCompare(teacherB.name); }));
      } catch (error) {
        setLoadingTeachers(false);
        setLoadingTeachersFilters(false);
        console.error('Error fetching data:', error);
      }
    };

    const fetchStudents = async () => {
      try {
        setLoadingStudents(true);
        const response = await axiosInstance.get(`${APIENDPOINT}/users?direction=asc&sortBy=firstname&role=Student&hasNoClassroom=1&page=${1}&limit=${5000}`, { headers: { 'Authorization': `Bearer ${token}`} });

        setLoadingStudents(false);
        setStudents(response.data.data.data.sort((studentA, studentB) => { return studentA.firstname.localeCompare(studentB.firstname); }).map(student => ({...student, value: student.id, label: `${student.firstname} ${student.lastname}` })));
      } catch (error) {
        setLoadingStudents(false);
        console.error('Error fetching data:', error);
      }
    };

    const fetchClassroomsForFilter = async () => {
      try {
        setLoadingClassroomsFilters(true);
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms?page=${1}&limit=${100}`, { headers: { 'Authorization': `Bearer ${token}`} });

        setLoadingClassroomsFilters(false);
        setClassroomsFilters(response.data.data.data.map(classroom => ({ ...classroom, nameRaw: classroom.name, name: `Grade ${classroom.grade_level_id ? toRoman(classroom.grade_level_id) : '-'} - ${classroom.name}` })).sort((classroomA, classroomB) => { return classroomA.name.localeCompare(classroomB.name); }));
      } catch (error) {
        setLoadingClassroomsFilters(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchTeachers();
    fetchStudents();
    fetchClassroomsForFilter();
  }, [token]);

  const isClassroomNameAlreadyExists = useMemo(() => {
    if(classroomModalValues.name.length > 0) {
      let classroomNames = classroomsFilters.map(classroom => classroom.nameRaw);
      if(classroomModalValues.mode === ADDCLASSROOMMODES.EDIT) {
        classroomNames = classroomNames.filter(name => name !== classroomModalValues.currentName)
      }

      return classroomNames.indexOf(classroomModalValues.name) !== -1;
    }
    return false;
  }, [classroomModalValues.name, classroomModalValues.mode, classroomModalValues.currentName, classroomsFilters]);
  
  const canCreateClassroom = (classroomModalValues.grade_level_id && classroomModalValues.name && !isClassroomNameAlreadyExists);

  const removeStudent = useCallback((removedStudent) => {
    setClassroomModalValues({ 
      ...classroomModalValues, 
      students: classroomModalValues.students.filter((student) => student.value !== removedStudent.value)
    });
  }, [classroomModalValues]);

  const addOrEditClassroom = (e) => {
    e.preventDefault();
    if(classroomModalValues.mode === ADDCLASSROOMMODES.ADD) {
      addClassroom();
    } else {
      editClassroom();
    }
  };

  const addClassroom = async() => {
    setClassroomModalIsSaving(true);
    console.log(classroomModalValues);

    try {
      const formData = new FormData();
      formData.append("name", classroomModalValues.name);
      formData.append("description", `Grade ${toRoman(classroomModalValues.grade_level_id)} - ${classroomModalValues.name}`);
      for (let i = 0; i < classroomModalValues.teachers.length; i++) {
        formData.append('teacher_ids[]', classroomModalValues.teachers[i].id);
      }
      formData.append("teacher_id", null);
      formData.append("grade_level_id", classroomModalValues.grade_level_id);
      formData.append("school_id", SCHOOLID);

      const createClassroomResponse = await axiosInstance.post(
        `${APIENDPOINT}/classrooms`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const newId = createClassroomResponse.data.data?.classroom.id;
      if(classroomModalValues.students.length > 0) {
        const classroomStudentsFormData = new FormData();
        for (let i = 0; i < classroomModalValues.students.length; i++) {
          classroomStudentsFormData.append('student_id[]', classroomModalValues.students[i].id);
        }
        await axiosInstance.post(
          `${APIENDPOINT}/classrooms/${newId}/students`,
          classroomStudentsFormData,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
      }

      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-estorya">Successfully created your classroom</h6>
          <p className="mb-0">You can now assign students and view statistics for 
          this classroom. <NavLink to={`/classroommanagement/classrooms/${newId}`} className='text-estorya'>View classroom.</NavLink></p>
        </div>
      </div>);

      fetchClassrooms();
    } catch(e) {
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="times-circle" size="2x" className="me-3 text-danger" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-danger">There's an error on creating your classroom</h6>
          <p className="mb-0">Please try again later.</p>
        </div>
      </div>);
    }

    setClassroomModalIsSaving(false);
    setClassroomModalIsOpen(false);
    setClassroomModalValues(defaultEmptyClassroom);
  };

  const toEditClassroom = async(classroom) => {
    let teachers = [];
    if( classroom.teachers.length <= 0) {
      teachers.push(classroom.teacher)
    } else {
      teachers = classroom.teachers
    }
    console.log(classroom)
    setClassroomModalValues({
      mode: ADDCLASSROOMMODES.EDIT,
      id: classroom.id,
      name: classroom.name,
      currentName: classroom.name,
      grade_level_id: classroom.grade_level_id ? classroom.grade_level_id : 1,
      teacher: classroom.teacher,
      teachers: teachers,
      currentStudents: classroom.students,
      students: classroom.students,
    });
    setClassroomModalIsOpen(true);
  };

  const editClassroom = async() => {
    setClassroomModalIsSaving(true);

    try {
      const formData = new FormData();
      formData.append("name", classroomModalValues.name);
      formData.append("description", `Grade ${toRoman(classroomModalValues.grade_level_id)} - ${classroomModalValues.name}`);
      // formData.append("teacher_id", classroomModalValues.teacher.id);
      formData.append("teacher_id", null);
      for (let i = 0; i < classroomModalValues.teachers.length; i++) {
        formData.append('teacher_ids[]', classroomModalValues.teachers[i].id);
      }
      formData.append("grade_level_id", classroomModalValues.grade_level_id);
      formData.append("school_id", SCHOOLID);

      const queryString = new URLSearchParams(formData).toString();

      await axiosInstance.put(
        `${APIENDPOINT}/classrooms/${classroomModalValues.id}?${queryString}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      const classroomStudentDifferences = classroomModalValues.currentStudents.filter(({ id: id1 }) => !classroomModalValues.students.some(({ id: id2 }) => id1 === id2));
      const classroomNewStudents = classroomModalValues.students.filter(student => !student.class_student_id);

      if(classroomStudentDifferences.length > 0) {
        await Promise.all(classroomStudentDifferences.map((student) => 
          axiosInstance.delete(
            `${APIENDPOINT}/classrooms/students/${student.class_student_id}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            }
          )
        ));
      }

      if(classroomNewStudents.length > 0) {
        const classroomStudentsFormData = new FormData();
        for (let i = 0; i < classroomNewStudents.length; i++) {
          classroomStudentsFormData.append('student_id[]', classroomNewStudents[i].id);
        }
        await axiosInstance.post(
          `${APIENDPOINT}/classrooms/${classroomModalValues.id}/students`,
          classroomStudentsFormData,
          {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          }
        );
      }

      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-estorya">Successfully updated your classroom</h6>
        </div>
      </div>);

      fetchClassrooms();
    } catch(e) {
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="times-circle" size="2x" className="me-3 text-danger" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-danger">There's an error on updating your classroom</h6>
          <p className="mb-0">Please try again.</p>
        </div>
      </div>);
    }

    setClassroomModalIsSaving(false);
    setClassroomModalIsOpen(false);
    setClassroomModalValues(defaultEmptyClassroom);
  };

  const toDeleteClassroom = (classroom) => {
    setToArchiveClassroom(classroom);
    setToArchiveClassroomModalIsOpen(true);
  };

  const deleteClassroom = async() => {
    setToArchiveClassroomModalIsArchiving(true);
    try {
      await axiosInstance.delete(
        `${APIENDPOINT}/classrooms/${toArchiveClassroom.id}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );

      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-estorya">Successfully archived your classroom</h6>
        </div>
      </div>);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="times-circle" size="2x" className="me-3 text-danger" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-danger">There's an error on archiving your classroom</h6>
          <p className="mb-0">Please try again.</p>
        </div>
      </div>);
    }

    setToArchiveClassroomModalIsOpen(false);
    setToArchiveClassroomModalIsArchiving(false);
    fetchClassrooms();
  };

  const updateSort = (attribute) => {
    if(sortBy === attribute) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(attribute);
      setSortDirection('asc');
    }
  };

  return (
    <ClassroomManagementLayout>
    <div id="classrooms" className="animated fadeIn flex-grow-1 d-flex bg-white" style={{ height: 'calc(100vh - 400px)' }}>
      <Helmet>
        <title>{`eStorya: Classroom Management - Classrooms`}</title>
      </Helmet>

      <div className="flex-grow-1 d-flex bg-white p-4">
        <div className="container-fluid d-flex flex-column">
          <div className="mb-0">
            <div className="row align-items-center justify-content-between" style={{ height: 40 }}>
              <div className="col-auto">
                <h5 className="fw-bold mb-0">Classrooms <span className="text-muted fw-normal ms-3">{total} result/s</span></h5>
              </div>
              {hasPermission(permissions, [PERMISSIONS.CREATE_CLASSROOM]) &&
                <div className="col-auto">
                  <button type="button" className="btn btn-estorya" onClick={() => { setClassroomModalValues(defaultEmptyClassroom); setClassroomModalIsOpen(true); }}><FontAwesomeIcon icon="fa-solid fa-plus" size="xs" className="me-2" /> Add a classroom</button>
                </div>
              }
            </div>
          </div>
          <div className="mb-0">
            <div className="row align-items-center justify-content-between" style={{ minHeight: '60px' }}>

              <div className="col-auto">
                <div className="input-group">
                  <label className="input-group-text bg-transparent border-right-0" htmlFor="searchClassroom"><FontAwesomeIcon icon="search" /></label>
                  {/* <input type="text" className="form-control border-left-0" id="searchClassroom" placeholder="Search for Classroom" style={{ width: '20rem' }} value={search} onChange={(e) => { setSearch(e.target.value) }} /> */}
                  <DebounceInput className="form-control border-left-0" id="searchClassroom" placeholder="Search for Classroom" style={{ width: '20rem' }} value={search} debounceTimeout={1000} onChange={(e) => { setSearch(e.target.value); setCurrentPage(1) }} />
                </div>
              </div>

              <div className="col-auto">
                <div className="row">

                  {/* <div className="col-auto">
                    <button className="disabled btn btn-light" disabled>
                      <FontAwesomeIcon icon="filter" className="text-muted" />
                    </button>
                  </div> */}

                  {/* <div className="col-auto p-0 my-2 mx-2" style={{ borderLeft: '1px solid #CCCCCC' }}>
                  </div> */}

                  <div className="col-auto">
                    <div className="input-group">
                      <label htmlFor="page" className="form-label input-group-text mb-0 border-0 ps-0 bg-transparent">Rows:</label>
                      <select id="page" className="form-select rounded" value={classroomsPerPage} onChange={(e) => { setCurrentPage(1); setClassroomsPerPage(parseInt(e.target.value, 10)) }}>
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>

                  {/* Pagination */}
                  <nav className="col-auto d-flex align-items-center">
                    <ReactPaginate
                      previousLabel={<FontAwesomeIcon icon="chevron-left" />}
                      nextLabel={<FontAwesomeIcon icon="chevron-right" />}
                      pageClassName="page-item"
                      previousLinkClassName="page-link rounded text-estorya me-1"
                      nextLinkClassName="page-link rounded text-estorya ms-1"
                      pageLinkClassName="page-link border-estorya text-estorya rounded mx-1"
                      breakLinkClassName="page-link rounded text-estorya mx-1"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination mb-0"
                      activeLinkClassName="bg-estorya text-white"
                      disabledClassName="disabled"
                      activeClassName="active"
                      forcePage={currentPage - 1}
                    />
                  </nav>
                </div>
              </div>

            </div>
          </div>
          
          {loadingClassrooms ?
            <div className="flex-grow-1 d-flex align-items-center justify-content-center">
              <div className="text-center">
                <Lottie
                  className="mt-2 mb-4"
                  loop
                  animationData={loadingAnimation}
                  speed={0.75}
                  play
                  style={{ width: 300, height: 300, margin: "0 auto" }}
                />          
              </div>
            </div>
            :
            <>
              
              <div className="flex-grow-1 overflow-auto" style={{ height: 'calc(100vh - 395px)' }}>
                <table className="table mb-0">
                  <thead>
                    <tr className="table-light">
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Classroom</span>
                        {/* <DataFilters
                          name="Classrooms"
                          filters={classroomsFilters}
                          selectedItems={filters.classroomsFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, classroomsFilters: filterData });
                          }}
                          searchable={true}
                          loading={loadingClassroomsFilters}
                        /> */}
                      </th>
                      <th className="text-center border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0', width: '150px' }}>
                        <span className="me-2">Grade Level</span>
                        <DataFilters
                          name="Grade Level"
                          filters={gradeLevelFilters}
                          selectedItems={filters.gradeLevelFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, gradeLevelFilters: filterData });
                          }}
                          searchable={true}
                          loading={false}
                        />
                      </th>
                      <th className="text-center border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0', width: '150px' }}>
                        <span className="me-2">Section Name</span>
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Assigned Teacher/s</span>
                        <DataFilters
                          name="Teachers"
                          filters={teachersFilters}
                          selectedItems={filters.teachersFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, teachersFilters: filterData });
                          }}
                          searchable={true}
                          loading={loadingTeachersFilters}
                        />
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Students</span>
                        {/* <button type="button" className="border-0 p-0 m-0 bg-transparent text-muted" onClick={() => { updateSort('student'); }}><FontAwesomeIcon icon={sortBy === 'student' ? sortDirection === 'asc' ? 'sort-up' : 'sort-down' : 'sort'} size="xs" /></button> */}
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Date Created</span>
                        <button type="button" className="border-0 p-0 m-0 bg-transparent text-muted" onClick={() => { updateSort('created_at'); }}><FontAwesomeIcon icon={sortBy === 'created_at' ? sortDirection === 'asc' ? 'sort-up' : 'sort-down' : 'sort'} size="xs" /></button>
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0', width: '30px', zIndex: 1 }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {classrooms.map(classroom => {
                      return (
                        <ClassroomListItem key={`classroom-${classroom.id}`} classroom={classroom} toEditClassroom={toEditClassroom} toDeleteClassroom={toDeleteClassroom} />
                      )
                    })}
                  </tbody>
                </table>
                {classrooms.length === 0 &&
                  <div className="flex-grow-1 d-flex align-items-center justify-content-center" style={{height:'500px'}}>
                    <div className="text-center">                
                      <img src={empty} alt="empty" width={150} />
                      <h5 className="fw-bold mb-3">Sorry, we couldn’t find any match.</h5>
                      {hasPermission(permissions, [PERMISSIONS.CREATE_CLASSROOM]) &&
                        <p>You can add new classroom by clicking on<br/>the <strong>Add New Classroom</strong> button.</p>
                      }
                      {[ROLES.SUPERADMIN, ROLES.SCHOOLMODERATOR].includes(role) &&
                        <p>Ask the administrator to add you a classroom.</p>
                      }
                      {hasPermission(permissions, [PERMISSIONS.CREATE_CLASSROOM]) &&
                        <button type="button" className="btn btn-estorya" onClick={() => { setClassroomModalValues(defaultEmptyClassroom); setClassroomModalIsOpen(true); }}><FontAwesomeIcon icon="plus" className="me-2" />Add a Classroom</button>
                      }
                    </div>
                  </div>
                }
              </div>
              
             
            </>
          }
        </div>
      </div>

      <Modal isOpen={classroomModalIsOpen} size="lg" centered={true} contentClassName="border-0 shadow">
        <div className="modal-content border-0 overflow-hidden">
          <form autoComplete="off" onSubmit={addOrEditClassroom}>
            <div className="modal-header border-0">
              {classroomModalValues.mode === ADDCLASSROOMMODES.ADD &&
                <h6 className="modal-title fw-bold">Add a new Classroom</h6>
              }

              {(classroomModalValues.mode === ADDCLASSROOMMODES.EDIT) &&
                <h6 className="modal-title fw-bold">Edit Classroom</h6>
              }
              {/* {(classroomModalValues.mode === ADDCLASSROOMMODES.EDIT && role === ROLES.SUPERADMIN) &&
                <h6 className="modal-title fw-bold">Edit Classroom</h6>
              }
              {(classroomModalValues.mode === ADDCLASSROOMMODES.EDIT && role === ROLES.TEACHER) &&
                <h6 className="modal-title fw-bold">Assign Students</h6>
              } */}
              <button type="button" className="position-absolute btn btn-light btn-sm bg-transparent border-0" style={{ right: '10px', top: '10px' }} onClick={() => setClassroomModalIsOpen(false)}><img alt="" src={iconExit} /></button>
            </div>
            <div className="modal-body">

              <div className="mb-3 row">
                <div className="col">
                  <label className="form-label fw-bold">Classroom Name</label>
                  <h5 className="fs-4">{canCreateClassroom ? <span className="fw-bold">Grade {toRoman(classroomModalValues.grade_level_id)} - {classroomModalValues.name}</span> : <span className="fst-italic text-estoryalight">set a grade level and section name first</span>}</h5>
                </div>
                {isClassroomNameAlreadyExists}
              </div>

              <div className="mb-2 row">
                <div className="col">
                  <label htmlFor="level" className="form-label fw-bold">Grade Level <span className="text-danger">*</span></label>
                  <select id="level" className="form-select" value={classroomModalValues.grade_level_id} onChange={(e) => { setClassroomModalValues({...classroomModalValues, grade_level_id: e.target.value}) }} disabled={role === ROLES.TEACHER}>
                    <option value={1}>Grade {toRoman(1)}</option>
                    <option value={2}>Grade {toRoman(2)}</option>
                    <option value={3}>Grade {toRoman(3)}</option>
                    <option value={4}>Grade {toRoman(4)}</option>
                    <option value={5}>Grade {toRoman(5)}</option>
                    <option value={6}>Grade {toRoman(6)}</option>
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="lastName" className="form-label fw-bold">Section <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className={`form-control ${isClassroomNameAlreadyExists ? 'is-invalid' : ''}`}
                    id="lastName"
                    value={classroomModalValues.name}
                    disabled={role === ROLES.TEACHER}
                    onChange={(e) => { setClassroomModalValues({...classroomModalValues, name: e.target.value}) }}
                  />
                  {isClassroomNameAlreadyExists &&
                    <div className="invalid-feedback">
                      Section name already exist
                    </div>
                  }
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="teacher" className="form-label fw-bold">Assign Teacher/s <span className="text-danger">*</span></label>
                <Select
                  name="teacher"
                  inputId="teacher"
                  options={teachers}
                  isLoading={loadingTeachers}
                  placeholder="Select a Teacher"
                  isDisabled={role === ROLES.TEACHER}
                  isClearable={true}
                  isMulti
                  components={{MultiValueLabel: CustomTeachersLabel}}
                  styles={{
                    menuPortal: (base, state) => ({
                      ...base,
                      zIndex: "9999"
                    }),
                    multiValue: (base) => ({
                      ...base,
                      border: 'solid 1px #5B8DF5',
                      borderRadius: '0.25rem',
                      backgroundColor: '#5B8DF533',
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: '#5B8DF5',
                      fontWeight: 'bold'
                    }),
                    multiValueRemove: (base) => ({
                      ...base,
                      color: '#5B8DF5',
                    }),
                    menuList: (base) => ({
                      ...base,
                    })
                  }}
                  value={classroomModalValues.teachers}
                  getOptionLabel={option => `${option.firstname} ${option.lastname}`}
                  onChange={(e) => { setClassroomModalValues({...classroomModalValues, teachers: e}); }}
                  menuPortalTarget={document.body}
                />
              </div>
              <div className="mb-2">
                <label className="form-label fw-bold">Add Student(s) to group</label>
                <Select
                  isMulti
                  value={classroomModalValues.students}
                  onChange={(e) => { setClassroomModalValues({...classroomModalValues, students: e}) }}
                  removeStudent={removeStudent}
                  options={students}
                  isLoading={loadingStudents}
                  components={{ SelectContainer, ValueContainer }}
                  controlShouldRenderValue={false}
                  placeholder="Search for Student's name"
                  // menuIsOpen={true}
                  styles={{
                    valueContainer: (css) => ({ ...css, display: "grid" }),
                    indicatorsContainer: (base, state) => ({
                      ...base,
                      display: 'none'
                    }),
                    menu: (base, state) => ({
                      ...base,
                      top: "35px"
                    }),
                    multiValue: (base) => ({
                      ...base,
                      display: 'inline-flex',
                      border: 'solid 1px #5B8DF5',
                      borderRadius: '0.25rem',
                      backgroundColor: '#5B8DF533',
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: '#5B8DF5',
                      fontWeight: 'bold'
                    }),
                    multiValueRemove: (base) => ({
                      ...base,
                      color: '#5B8DF5',
                    }),
                    menuList: (base) => ({
                      ...base,
                      maxHeight: '200px'
                    })
                  }}
                />
              </div>
            </div> 
            
            {classroomModalValues.mode === ADDCLASSROOMMODES.ADD &&
              <div className="modal-footer d-flex justify-content-between">
                <p><strong>Note:</strong> You can proceed without adding a student.</p>
                <button type="submit" className="btn btn-estorya" disabled={!canCreateClassroom || classroomModalIsSaving}>Create Classroom</button>
              </div>
            }
            {classroomModalValues.mode === ADDCLASSROOMMODES.EDIT &&
              <div className="modal-footer d-flex justify-content-end">
                <button type="submit" className="btn btn-estorya" disabled={!canCreateClassroom || classroomModalIsSaving}>Save Changes</button>
              </div>
            }
            
          </form>
        </div>
      </Modal>

      <Modal isOpen={toArchiveClassroomModalIsOpen} toggle={() => { setToArchiveClassroomModalIsOpen(!toArchiveClassroomModalIsOpen); }} centered={true} contentClassName="border-0 shadow">
        <div className="modal-content border-0 overflow-hidden">
          <div className="modal-header border-0">
            <h6 className="modal-title fw-bold">Archive Classroom (Grade {toRoman(toArchiveClassroom?.grade_level_id)} - {toArchiveClassroom?.name})</h6>
            <button type="button" className="position-absolute btn btn-light btn-sm bg-transparent border-0" style={{ right: '10px', top: '10px' }} onClick={() => setToArchiveClassroomModalIsOpen(false)}><img alt="" src={iconExit} /></button>
          </div>
          <div className="modal-body">
            <p className="mb-0">
              Are you sure you want to archive this classroom <strong>(Grade {toRoman(toArchiveClassroom?.grade_level_id)} - {toArchiveClassroom?.name})</strong>?<br/>
              You cannot undo this action.
            </p>
          </div>
          <div className="modal-footer border-0 d-flex">
            <button type="button" className="btn btn-light" onClick={() => { setToArchiveClassroomModalIsOpen(false); }} disabled={toArchiveClassroomModalIsArchiving}>Cancel</button>
            <button type="button" className="btn btn-danger" onClick={() => { deleteClassroom(); }} disabled={toArchiveClassroomModalIsArchiving}>Archive Classroom</button>
          </div>
        </div>
      </Modal>

      {/* <div className="flex-grow-1 d-flex bg-light p-4">
        Classrooms
        <pre>
        {JSON.stringify(sampleClassrooms, "", 2)}
        </pre>
      </div> */}
    </div>
    </ClassroomManagementLayout>

  );
};

export default Classrooms;
import React from 'react';
import { NavLink } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import iconCLassroomSolid from '../../assets/img/icon-classroom-solid-black.svg';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/user.js';
import { PERMISSIONS, ROLES } from '../../config.js';


function ClassroomManagementLayout({children }) {
  const role = useSelector(state => state.app.role);
  const permissions = useSelector(state => state.app.permissions);

  return (
    <div id="classroommanagement" className="animated fadeIn flex-grow-1 d-flex flex-column" style={{ minHeight: 'calc(100vh - 75px)' }}>

      <Helmet>
        <title>{`eStorya: Dashboard`}</title>
      </Helmet>

      <div className="header container-fluid align-items-center d-flex px-4" style={{ height: '140px', backgroundColor: '' }}>
        <div className="row">
          <div className="col">
            <div className="text-muted mb-3 d-flex align-items-center"><img alt="" src={iconCLassroomSolid} className="me-1" /> 
              {role == ROLES.TEACHER ? (
                'Student Management'
              ) : (
                'Classroom Management'
              )}
             
            </div>
            <h3 className="fw-bold text-estorya">
            {role == ROLES.TEACHER ? (
                'Student Management'
              ) : (
                'Classroom Management'
              )}
            </h3>
            <p className="mb-0 text-muted">
              {role == ROLES.TEACHER ? (
                'Manage your students'
              ) : (
                'Manage your teachers and students'
              )}
              
            </p>
          </div>
        </div>
      </div>

      <ul className="nav page-nav container-fluid border-bottom border-light px-4">
        {hasPermission(permissions,[PERMISSIONS.VIEW_CLASSROOM]) &&
          <li className="nav-item me-4">
            <NavLink className="nav-link px-0" to={`/classroommanagement/classrooms`} >Classrooms</NavLink>
          </li>
        }

        {hasPermission(permissions,[PERMISSIONS.VIEW_TEACHER]) &&
            <li className="nav-item me-4">
             <NavLink className="nav-link px-0" to={`/classroommanagement/teachers`} >Teachers</NavLink>
           </li>
        }
        {hasPermission(permissions,[PERMISSIONS.VIEW_STUDENT]) &&
          <li className="nav-item me-4">
           <NavLink className="nav-link px-0" to={`/classroommanagement/students`} >Students</NavLink>
          </li>
        }
        
     
        
      </ul>

        {children }
    </div>
  );
};

export default ClassroomManagementLayout;
import React from 'react';
import { Helmet } from 'react-helmet-async';


function AccessControlLayout({children}) {
  return (
    <div id="accesscontrol" className="animated fadeIn flex-grow-1 d-flex flex-column" style={{ minHeight: 'calc(100vh - 75px)' }}>
        <Helmet>
        <title>{`eStorya: Administration`}</title>
        </Helmet>

        {children}
    </div>
  )
};

export default AccessControlLayout;
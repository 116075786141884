import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    isSignedIn: false,
    user: {},
    role: null,
    token: null,
    isSidebarOpen: true,
    permissions:[]
  },
  reducers: {
    login: (state, {payload: { user, role, token, permissions }}) => {
      state.isSignedIn = true;
      state.user = user;
      state.role = role;
      state.token = token;
      state.permissions = permissions;
    },
    logout: (state) => {
      state.isSignedIn = false;
      state.user = {};
      state.role = null;
      state.token = null;
    },
    expandSidebar: (state) => {
      state.isSidebarOpen = true;
    },
    collapseSidebar: (state) => {
      state.isSidebarOpen = false;
    },
  },
})

export const { login, logout, expandSidebar, collapseSidebar } = appSlice.actions

export default appSlice.reducer
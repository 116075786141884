import React, { useState, useCallback } from 'react';
import {
    Modal
  } from 'reactstrap';
  import { Progress } from 'reactstrap';
import iconExit from './icon-exit.svg';
import number1Img from './number_1.svg';
import number2Img from './number_2.svg';
import iconXls from './icon-xls.svg';
import csvTemplateFile from './csv-template.csv'
import {useDropzone} from 'react-dropzone';

const containerStyle = {
    width: '100%',
    border: '2px dashed #d3d3d3',
    padding: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    textAlign: 'center',
};

const AddUserBulkModal = ({
    addUsers,
    addUserBulkModalIsOpen,
    setAddUserBulkModalIsOpen,
    addUsersIsSubmitting,
    file,
    setFile,
    progressStatus,
    canSaveAddUsers,
    openAddUserModal,
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
      }, []);
  
      const { getRootProps, getInputProps } = useDropzone({ 
        onDrop, 
        maxFiles: 1, 
        multiple: false, 
        accept: {  
          'application/vnd.ms-excel': ['.xls'],  
          'text/csv': ['.csv'],   
        },
        maxSize: 10 * 1024 * 1024,
        disabled: addUsersIsSubmitting
      });

    return (
        <Modal isOpen={addUserBulkModalIsOpen} size="lg" centered={true} contentClassName="border-0 shadow">
        <div className="modal-content border-0 overflow-hidden">
                <div className="modal-header border-0">
                    <h6 className="modal-title fw-bold">Add Multiple Users</h6>
                    <button 
                        type="button" 
                        className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                        style={{ right: '10px', top: '10px' }} 
                        disabled={addUsersIsSubmitting}
                        onClick={() =>setAddUserBulkModalIsOpen(false)}>
                        <img alt="" src={iconExit} />
                    </button>
                </div>
                <div className="modal-body">
                <p className='fw-bold'>Follow the instructions carefully on how to add multiple teacher.</p>
                {/* step 1 */}
                <div className='d-flex align-items-center mb-3'>
                    <img alt='' className='me-2' src={number1Img}/>
                    <p className='text-muted mb-0'>Download the <strong>Teacher User Template Guide</strong> to input your teacher information. <br/>
                    <i>You only need to do this once, you can use the same format for another list of teachers.</i></p>
                </div>
                <div className='d-flex justify-content-between mb-3 p-3 border rounded-3'>
                    <div className='d-flex flex-wrap align-items-center'>
                    <img alt='' src={iconXls} className='me-2'/>
                    <p className='mb-0'><strong>Teacher User Template Guide.xls</strong></p>
                    </div>
                    <a href={csvTemplateFile} download="Teacher User Template Guide.csv" className='btn btn-outline-estorya' >Download</a>
                </div>
                {/* step 3 */}
                <div className='d-flex align-items-center mb-3'>
                    <img alt='' className='me-2' src={number2Img}/>
                    <p className='text-muted mb-0'>Fill the sheet with your students’ information. Once complete, upload the file below.</p>
                </div>
                <div {...getRootProps()} style={containerStyle} className='rounded-3'>
                    <input {...getInputProps()} />
                    {(!addUsersIsSubmitting && file == null)&&
                    <div>
                        <p className='fw-bold'>
                        Drag and drop the file or <span className='text-estorya'>browse</span>
                        </p>
                        <p style={{ color: '#6c757d', fontSize: '14px' }}>
                        Document must be less than 10MB. Supports .xls and .csv.
                        </p>
                    </div>
                    }
                    {(!addUsersIsSubmitting && file != null)&&
                    <div className='d-flex'>
                        <img alt='' className='me-2' height={40} src={iconXls}/>
                        <div className='text-start'>
                        <p className='mb-0 fw-bold'>{file?.name}</p>
                        <p className='mb-0'> {file?.size} bytes</p>
                        </div>
                    </div>
                    }
                    {addUsersIsSubmitting &&
                    <div style={{width:'100%'}}>
                        <Progress
                        className="my-3"
                        value={progressStatus}
                        />
                        <p className='text-muted'>{progressStatus}% Uploading</p>
                    </div>
                    }
                
                </div>
                
                </div> 
                <div className="modal-footer d-flex justify-content-between">
                    <p disabled={true} onClick={()=>{
                        openAddUserModal()
                        setAddUserBulkModalIsOpen(false)
                    }} className='mb-0 text-estorya cursor-pointer'>
                    Add single Teacher?
                    </p> 
                    <button 
                    onClick={addUsers}
                    type="button" 
                    className="btn btn-estorya" 
                    disabled={!canSaveAddUsers || addUsersIsSubmitting}>
                        Add User
                    </button>
                </div>
        </div>
        </Modal>
    );
};

export default AddUserBulkModal;

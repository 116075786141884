import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APIENDPOINT_AUTH, axiosInstance } from '../../config';
// import { ROLES } from '../../config';
import logo from '../../assets/img/logo.svg';
import './ForgotPassword.css';

function ForgotPassword() {

  const [submitting, setSubmitting] = useState(false);
  const [isResetLinkSent, setIsResetLinkSent] = useState(false);
  const navigate = useNavigate();

  const validate = async(e) => {
    e.preventDefault();

    const { email: { value: email }} = e.target.elements;
    setSubmitting(true);
    try {

      await axiosInstance({
        url:`${APIENDPOINT_AUTH}/password/email`,
        method: 'POST',
        data: { email },
      });
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '400px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-1 text-estorya">Password Reset Email Has Been Sent</h6>
          <p className="mb-0">The email you will receive will include the instructions. Click the link provided in the email.</p>
        </div>
      </div>);

      // navigate("/login");
    } catch(e) {
      console.log(e);
    } finally {
      setSubmitting(false);
    }
    
    setIsResetLinkSent(true)
  };

  return (
    <div id="login" className="animated fadeIn flex-grow-1 d-flex flex-column bg-estoryalight" style={{ minHeight: 'calc(100vh)' }}>
      <Helmet>
        <title>{`eStorya: Forgot Password`}</title>
      </Helmet>

      <div className="container-fluid flex-grow-1 d-flex">
        <div className="row flex-grow-1 no-gutters justify-content-center align-items-center">
          <div className="col-lg-3 col-md-5 col-sm-6 col-xs-12">
            <div className="card shadow border-0" style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)', backdropFilter: 'blur(5px)' }}>
              {!isResetLinkSent &&
                <div className="card-body p-5">
                  <img alt="eStorya" src={logo} className="mb-4 d-block mx-auto w-75" />
                  <h5 className='text-estorya text-center mb-4'>Forgot your password?</h5>
                  <p>
                    Please enter your email address and we'll send you a link to reset your password.
                  </p>
                  <form onSubmit={validate} className="mb-4">
                    <div className="mb-3">
                      <label htmlFor="email" className="form-label fw-bold">E-Mail</label>
                      <input type="email" className="form-control" id="email" disabled={submitting} required/>
                    </div>
                    <button type="submit" className="btn w-100 btn-estorya mb-2" disabled={submitting}>Send Reset Link</button>
                  </form>
                  {/* <a href="https://skunkworks.ai/Privacy-Policy" className="text-secondary text-center d-block">Back to Login</a> */}
                  <NavLink to="/login" className="text-secondary text-center d-block">Back to Login</NavLink>
                </div>
              }

              {isResetLinkSent &&
                <div className="card-body p-5">
                  <img alt="eStorya" src={logo} className="mb-4 d-block mx-auto w-75" />
                  <h5 className='text-estorya text-center mb-4'>Password reset link has 
                  been sent</h5>
                  <p>
                    You should receive an email shortly with further instructions.
                  </p>
                    <NavLink to="/login" className="btn w-100 btn-estorya mb-4" >Back to Login</NavLink>
                </div>
              }
             
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ForgotPassword;
import React, { useState, useCallback } from 'react';
import { Progress } from 'reactstrap';
import { useSelector } from 'react-redux';
import {useDropzone} from 'react-dropzone';
import Select from 'react-select';
import { APIENDPOINT, axiosInstance } from '../../../../config';
import iconExit from './../icon-exit.svg';
import number1Img from './number_1.svg';
import number2Img from './number_2.svg';
import number3Img from './number_3.svg';
import iconXls from './icon-xls.svg';
import csvTemplateFile from './csv-template.csv'

const containerStyle = {
  width: '100%',
  border: '2px dashed #d3d3d3',
  padding: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  textAlign: 'center',
};


function BulkCreationForm ({ADDSTUDENTMODES, closeModal, setSelectedOption, showErrorResult, showAddStudentNotificationPanel, classroomOptions}) {
    
  const token = useSelector(state => state.app.token);
  const [addStudentsIsSubmitting, setAddStudentsIsSubmitting] = useState(false)
  const [file, setFile] = useState(null);
  const [classroom, setClassroom] = useState('')
  const [progressStatus, setProgressStatus] = useState(50)

  const canSaveAddStudents = (file && classroom);

  const addStudents = async(e) => {
    e.preventDefault();
    setAddStudentsIsSubmitting(true);

    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append('classroom_id', classroom.value)

    axiosInstance({
      url:`${APIENDPOINT}/bulk/users/student`,
      method: 'POST',
      data: formData,
      headers: { 'Authorization': `Bearer ${token}`}
    }).then((res)=>{
      setProgressStatus(100)
      setTimeout(() => {
        showAddStudentNotificationPanel(res?.data?.data?.users)
        setProgressStatus(50)
      }, 1000);
    }).catch((e)=>{
      setProgressStatus(100)
      setTimeout(() => {
        setFile(null)
        setAddStudentsIsSubmitting(false);
        showErrorResult(e.response.data)
        setProgressStatus(50)
      }, 1000);
    })
  };

    const onDrop = useCallback((acceptedFiles) => {
      setFile(acceptedFiles[0]);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ 
      onDrop, 
      maxFiles: 1, 
      multiple: false, 
      accept: {  
        'application/vnd.ms-excel': ['.xls'],  
        'text/csv': ['.csv'],   
      },
      maxSize: 10 * 1024 * 1024,
      disabled: addStudentsIsSubmitting
    });
    
    return(
      <>
        <div className="modal-header border-0">
            <h6 className="modal-title fw-bold">Add Multiple Students</h6>
            <button 
                type="button" 
                className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                style={{ right: '10px', top: '10px' }} 
                disabled={addStudentsIsSubmitting}
                onClick={() => closeModal()}>
                <img alt="" src={iconExit} />
            </button>
        </div>
        <div className="modal-body">
          <p className='fw-bold'>Follow the instructions carefully on how to add multiple students.</p>
          {/* step 1 */}
          <div className='d-flex align-items-center mb-3'>
            <img alt='' className='me-2' src={number1Img}/>
            <p className='text-muted mb-0'>Download the <strong>Student User Template Guide</strong> to input your students information. <br/>
            <i>You only need to do this once, you can use the same format for another list of students.</i></p>
          </div>
          <div className='d-flex justify-content-between mb-3 p-3 border rounded-3'>
            <div className='d-flex flex-wrap align-items-center'>
              <img alt='' src={iconXls} className='me-2'/>
              <p className='mb-0'><strong>Student User Template Guide.xls</strong></p>
            </div>
            <a href={csvTemplateFile} download="Student User Template Guide.csv" className='btn btn-outline-estorya' >Download</a>
          </div>
          {/* step 2 */}
          <div className='d-flex align-items-center mb-3'>
            <img alt='' className='me-2' src={number2Img}/>
            <p className='text-muted mb-0'>Select which classroom you want to assign these students.</p>
          </div>
          <div className="mb-3">
            <label htmlFor="classroom" className="form-label fw-bold">Assign Classroom<span className="text-danger">*</span></label>
            <Select
              value={classroom}
              onChange={(e)=> setClassroom(e)}
              options={classroomOptions}
              menuPlacement="top"
            />
          </div>
          {/* step 3 */}
          <div className='d-flex align-items-center mb-3'>
            <img alt='' className='me-2' src={number3Img}/>
            <p className='text-muted mb-0'>Fill the sheet with your students’ information. Once complete, upload the file below.</p>
          </div>
          <div {...getRootProps()} style={containerStyle} className='rounded-3'>
            <input {...getInputProps()} />
            {(!addStudentsIsSubmitting && file == null)&&
              <div>
                <p className='fw-bold'>
                  Drag and drop the file or <span className='text-estorya'>browse</span>
                </p>
                <p style={{ color: '#6c757d', fontSize: '14px' }}>
                  Document must be less than 10MB. Supports .xls and .csv.
                </p>
              </div>
            }
             {(!addStudentsIsSubmitting && file != null)&&
              <div className='d-flex'>
                <img alt='' className='me-2' height={40} src={iconXls}/>
                <div className='text-start'>
                  <p className='mb-0 fw-bold'>{file?.name}</p>
                  <p className='mb-0'> {file?.size} bytes</p>
                </div>
              </div>
            }
            {addStudentsIsSubmitting &&
              <div style={{width:'100%'}}>
                <Progress
                  className="my-3"
                  value={progressStatus}
                />
                <p className='text-muted'>{progressStatus}% Uploading</p>
              </div>
            }
           
          </div>
         
        </div> 
        <div className="modal-footer d-flex justify-content-between">
            <p disabled={true} onClick={()=>{
              if(!addStudentsIsSubmitting){
                setSelectedOption(ADDSTUDENTMODES.ADDSINGLE)
              }
            }} className='mb-0 text-estorya cursor-pointer'>
              Add single student instead?
            </p> 
            <button 
              onClick={addStudents}
              type="button" 
              className="btn btn-estorya" 
              disabled={!canSaveAddStudents || addStudentsIsSubmitting}>
                Add Students
              </button>
        </div>
      </>
    )
}

export default BulkCreationForm;
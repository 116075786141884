import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie-player';
import { DebounceInput } from 'react-debounce-input';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Modal } from 'reactstrap';

// import { faker } from '@faker-js/faker';

import empty from '../../assets/img/empty.png';
import DataFilters from '../../utils/DataFilters';
import iconActions from './icon-actions.svg';
import { toRoman } from '../../utils/utls';
import { APIENDPOINT, axiosInstance } from '../../config';
// import iconPersonSearch from './icon-person-search.svg';
import loadingAnimation from '../../assets/lottie/loadingAnimation.json';
import ClassroomManagementLayout from '../Layouts/ClassroomManagementLayout';


// const sampleTeachers = Array.from({ length: faker.number.int({ min: 1, max: 10 }) } , (_, i) => {  
//   const firstName = faker.person.firstName();
//   const lastName = faker.person.lastName();
//   return { 
//     id: i, 
//     emailAddress: `${firstName}.${lastName}@school.edu.ph`,
//     name: `${firstName} ${lastName}`,
//     level: faker.number.int({ min: 1, max: 6 }),
//     sectionName: `${faker.word.adjective()} ${faker.color.human()}`,
//     assignedClassrooms: Array.from({ length: faker.number.int({ min: 0, max: 2 }) } , () => {
//       return {
//         level: faker.number.int({ min: 1, max: 6 }),
//         name: `${faker.word.adjective()} ${faker.color.human()}`
//       }
//     }),
//     date_joined: faker.date.past(),
//     date_created: faker.date.past()
//   }
// });

function TeacherListItem({ teacher }) {
  const getGradeLevels = () =>{
    
    let gradeLevels = [];
    return gradeLevels = teacher.classroom_teacher.map(item => item.grade_level_id).filter((value, index, self) => value !== null && self.indexOf(value) === index).sort((a, b) => a - b);

  }

  return (
    <tr className={`border-light`}>
      <td className="align-middle">
        {teacher.firstname} {teacher.lastname}
      </td>
      <td className="align-middle text-lowercase">
        {teacher.email}
      </td>
      <td className="align-middle text-center">
        {/* {getGradeLevels()} */}
        {getGradeLevels().map((id, index) => (
            <span key={id}>
                {toRoman(id)}
                {index < getGradeLevels().length - 2 ? ', ' : ''}
                {index === getGradeLevels().length - 2 ? ' & ' : ''}
            </span>
        ))}
        {/* Grade {teacher.grade_level_id ? toRoman(teacher.grade_level_id) : '-'} */}
      </td>
      <td className="align-middle text-capitalize">
        {teacher.teacher_class_rooms.length > 0 ? 
          <>
            {teacher.teacher_class_rooms.map((assignedClassroom, idx) => (
              <span key={`teacher-${teacher.id}-classroom-${idx}`} className="d-inline-block px-2 py-1 me-2 rounded border border-estorya bg-estorya bg-opacity-10 text-estorya small">Grade {toRoman(assignedClassroom.grade_level_id)} - {assignedClassroom.name}</span>
            ))}
          </>
          :
          'No assigned classrooms'
        }
      </td>
     
      <td className="align-middle text-center text-capitalize">
        {teacher.created_at ? moment(teacher.created_at).format('DD-MMM-YYYY') : '-'}
      </td>
      <td className="align-middle">
        <UncontrolledDropdown>
          <DropdownToggle color="outline-light">
            <img src={iconActions} alt="options" />
          </DropdownToggle>
          <DropdownMenu className="py-1" style={{ minWidth: '150px' }}>
            <button className="px-3 py-1 btn w-100 text-start border-0 btn-outline-dark rounded-0" disabled>Edit Teacher</button>
            <button className="px-3 py-1 btn w-100 text-start border-0 btn-outline-danger rounded-0" disabled>Remove Teacher</button>
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr> 
  )
};

function Teachers() {

  const token = useSelector(state => state.app.token);
  // const [teachers, ] = useState(sampleTeachers.map(teacher => ({ ...teacher, checked: false })));
  const [teachers, setTeachers] = useState([]);
  const [loadingTeachers, setLoadingTeachers] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [teachersPerPage, setTeachersPerPage] = useState(20);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filters, setFilters] = useState({
    classroomsFilters: [],
    gradeLevelFilters: [],
  });
  const [classroomsFilters, setClassroomsFilters] = useState([]);
  const [loadingClassroomsFilters, setLoadingClassroomsFilters] = useState(false);
  const [gradeLevelFilters, ] = useState([
    {id: 1, name: `Grade ${toRoman(1)}`},
    {id: 2, name: `Grade ${toRoman(2)}`},
    {id: 3, name: `Grade ${toRoman(3)}`},
    {id: 4, name: `Grade ${toRoman(4)}`},
    {id: 5, name: `Grade ${toRoman(5)}`},
    {id: 6, name: `Grade ${toRoman(6)}`},
  ]);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        setLoadingTeachers(true);

        let classroomFilters = '';
        if(filters.classroomsFilters.length > 0) {
          classroomFilters = filters.classroomsFilters.map(obj => obj.id).join(',');
        }

        let gradeLevelFilters = '';
        if(filters.gradeLevelFilters.length > 0) {
          gradeLevelFilters = filters.gradeLevelFilters.map(obj => obj.id).join(',');
        }

        const response = await axiosInstance.get(`${APIENDPOINT}/users?role=Teacher&page=${currentPage}&limit=${teachersPerPage}&sortBy=${sortBy}&direction=${sortDirection}${classroomFilters.length > 0 ? `&teacherClassroomIds=${classroomFilters}` : ''}${gradeLevelFilters.length > 0 ? `&teacherGradeLevelIds=${gradeLevelFilters}` : ''}${search.length > 0 ? `&key=${search}` : ''}`, { headers: { 'Authorization': `Bearer ${token}`} });

        setLoadingTeachers(false);
        setTeachers(response.data.data.data);
        setTotal(response.data.data.total);
      } catch (error) {
        setLoadingTeachers(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchTeachers();
  }, [currentPage, teachersPerPage, sortBy, sortDirection, filters.classroomsFilters, filters.gradeLevelFilters, search, token]);

  useEffect(() => {
    const fetchClassroomsForFilter = async () => {
      try {
        setLoadingClassroomsFilters(true);
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms?page=${1}&limit=${100}`, { headers: { 'Authorization': `Bearer ${token}`} });

        setLoadingClassroomsFilters(false);
        setClassroomsFilters(response.data.data.data.map(classroom => ({ ...classroom, name: `Grade ${classroom.grade_level_id ? toRoman(classroom.grade_level_id) : '-'} - ${classroom.name}` })).sort((classroomA, classroomB) => { return classroomA.name.localeCompare(classroomB.name); }));
      } catch (error) {
        setLoadingClassroomsFilters(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchClassroomsForFilter();
  }, [token]);

  const pageCount = Math.ceil(total / teachersPerPage);

  const handlePageClick = (i) => {
    setCurrentPage(i.selected + 1);
  };

  const updateSort = (attribute) => {
    if(sortBy === attribute) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(attribute);
      setSortDirection('asc');
    }
  };

  // useEffect(() => {
  //   const fetchTeachersForFilter = async () => {
  //     try {
  //       // setLoadingTeachersFilters(true);
        
  //       const response = await axiosInstance.get(`${APIENDPOINT}/users?role=Teacher&page=${1}&limit=${100}`, { headers: { 'Authorization': `Bearer ${token}`} });

  //       // setLoadingTeachersFilters(false);
  //       // setTeachersEmailFilters(response.data.data.data.map(teacher => ({...teacher, name: `${teacher.email}` })));
  //       // setTeachersFilters(response.data.data.data.map(teacher => ({...teacher, name: `${teacher.firstname} ${teacher.lastname}` })));
  //     } catch (error) {
  //       setLoadingTeachersFilters(false);
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   fetchTeachersForFilter();
  // }, [token]);

  return (
    <ClassroomManagementLayout>
    <div id="teachers" className="animated fadeIn flex-grow-1 d-flex bg-white" style={{ height: 'calc(100vh - 400px)' }}>
      <Helmet>
        <title>{`eStorya: Classroom Management - Teachers`}</title>
      </Helmet>

      <div className="flex-grow-1 d-flex bg-white p-4">
        <div className="container-fluid d-flex flex-column">
          <div className="mb-0">
            <div className="row align-items-center justify-content-between" style={{ height: 40 }}>
              <div className="col-auto">
                <h5 className="fw-bold mb-0">Teachers <span className="text-muted fw-normal ms-3">{total} total</span></h5>
              </div>
            </div>
          </div>
          <div className="mb-0">
            <div className="row align-items-center justify-content-between" style={{ minHeight: '60px' }}>

              <div className="col-auto">
                <div className="input-group">
                  <label className="input-group-text bg-transparent border-right-0" htmlFor="searchTeacher"><FontAwesomeIcon icon="search" /></label>
                  {/* <input type="text" className="form-control border-left-0" id="searchTeacher" placeholder="Search for Teacher" style={{ width: '20rem' }} value={search} onChange={(e) => { setSearch(e.target.value) }} /> */}
                  <DebounceInput type="text" className="form-control border-left-0" id="searchTeacher" placeholder="Search for Teacher" style={{ width: '20rem' }} value={search} debounceTimeout={1000} onChange={(e) => { setSearch(e.target.value); setCurrentPage(1) }} />
                </div>
              </div>

              <div className="col-auto">
                <div className="row">

                  {/* <div className="col-auto">
                    <button className="disabled btn btn-light" disabled>
                      <FontAwesomeIcon icon="filter" className="text-muted" />
                    </button>
                  </div> */}

                  {/* <div className="col-auto p-0 my-2 mx-2" style={{ borderLeft: '1px solid #CCCCCC' }}>
                  </div> */}

                  <div className="col-auto">
                    <div className="input-group">
                      <label htmlFor="page" className="form-label input-group-text mb-0 border-0 ps-0 bg-transparent">Rows:</label>
                      <select id="page" className="form-select rounded" value={teachersPerPage} onChange={(e) => { setCurrentPage(1); setTeachersPerPage(parseInt(e.target.value, 10)) }}>
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>

                  {/* Pagination */}
                  <nav className="col-auto d-flex align-items-center">
                    <ReactPaginate
                      previousLabel={<FontAwesomeIcon icon="chevron-left" />}
                      nextLabel={<FontAwesomeIcon icon="chevron-right" />}
                      pageClassName="page-item"
                      previousLinkClassName="page-link rounded text-estorya me-1"
                      nextLinkClassName="page-link rounded text-estorya ms-1"
                      pageLinkClassName="page-link border-estorya text-estorya rounded mx-1"
                      breakLinkClassName="page-link rounded text-estorya mx-1"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination mb-0"
                      activeLinkClassName="bg-estorya text-white"
                      disabledClassName="disabled"
                      activeClassName="active"
                      forcePage={currentPage - 1}
                    />
                  </nav>
                </div>
              </div>

            </div>
          </div>
          
          {loadingTeachers ?
            <div className="flex-grow-1 d-flex align-items-center justify-content-center">
              <div className="text-center">
                <Lottie
                  className="mt-2 mb-4"
                  loop
                  animationData={loadingAnimation}
                  speed={0.75}
                  play
                  style={{ width: 300, height: 300, margin: "0 auto" }}
                />          
              </div>
            </div>
            :
            <>
              <div className="flex-grow-1 overflow-auto" style={{ height: 'calc(100vh - 395px)' }}>
                <table className="table mb-0">
                  <thead>
                    <tr className="table-light">
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Teacher</span>
                        {/* <DataFilters
                          name="Teachers"
                          filters={teachersFilters}
                          selectedItems={filters.teachersFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, teachersFilters: filterData });
                          }}
                          searchable={true}
                          loading={loadingTeachersFilters}
                        /> */}
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Email Address</span>
                        {/* <DataFilters
                          name="Email Addresses"
                          filters={teachersEmailFilters}
                          selectedItems={filters.teachersEmailFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, teachersEmailFilters: filterData });
                          }}
                          searchable={true}
                          loading={loadingTeachersFilters}
                        /> */}
                      </th>
                      <th className="text-center border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Grade Level</span>
                        <DataFilters
                          name="Grade Level"
                          filters={gradeLevelFilters}
                          selectedItems={filters.gradeLevelFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, gradeLevelFilters: filterData });
                          }}
                          searchable={true}
                          loading={false}
                        />
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Classroom</span>
                        <DataFilters
                          name="Classrooms"
                          filters={classroomsFilters}
                          selectedItems={filters.classroomsFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, classroomsFilters: filterData });
                          }}
                          searchable={true}
                          loading={loadingClassroomsFilters}
                        />
                      </th>
                      
                      <th className="text-center border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Date Joined</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted" onClick={() => { updateSort('created_at'); }}><FontAwesomeIcon icon={sortBy === 'created_at' ? sortDirection === 'asc' ? 'sort-up' : 'sort-down' : 'sort'} size="xs" /></button>
                      </th>
                      <th className="border-light position-sticky" style={{ top: '0', width: '30px', zIndex: 1 }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {teachers.map(teacher => {
                      return (
                        <TeacherListItem key={`teacher-${teacher.id}`} teacher={teacher} />
                      )
                    })}
                  </tbody>
                </table>
                {teachers.length === 0 &&
                  <div className="flex-grow-1 d-flex align-items-center justify-content-center" style={{height:'500px'}}>
                    <div className="text-center">                
                      <img src={empty} alt="empty" width={150} />
                      <h5 className="fw-bold mb-3">Sorry, we couldn’t find any match.</h5>
                      <p>You can add new learner by clicking on<br/>the <strong>Add New Teacher</strong> button.</p>
                      <button type="button" className="disabled btn btn-estorya" onClick={() => {  }}><FontAwesomeIcon icon="plus" className="me-2" />Add new Teacher</button>
                    </div>
                  </div>
                }
              </div>
              
            </>
          }
        </div>
      </div>

      {/* <div className="flex-grow-1 d-flex bg-light p-4">
        Classrooms
        <pre>
        {JSON.stringify(sampleClassrooms, "", 2)}
        </pre>
      </div> */}
    </div>
    </ClassroomManagementLayout>
  );
};

export default Teachers;
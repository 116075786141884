import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { LOCAL, APIENDPOINT_AUTH, axiosInstance, APIENDPOINT } from '../../config';
// import { ROLES } from '../../config';
import { login } from './../../actions/appSlice'
import logo from '../../assets/img/logo.svg';
import iconShowPassword from './Icon-show-eye-fill.svg';
import iconHidePassword from './icon-hide-eye-fill.svg';
import './Login.css';

function Login() {

  const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  const validate = async(e) => {
    e.preventDefault();

    const { email: { value: email }, password: { value: password } } = e.target.elements;
    setSubmitting(true);
    setErrors([]);
    try {

      const formData = new FormData();
      formData.append("username", email);
      formData.append("password", password);

      let loginResponse;

      if(!LOCAL) {
        loginResponse = await axiosInstance({
          url:`${APIENDPOINT_AUTH}/portal-authenticate`,
          method: 'POST',
          // data: { email, password },
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          // withCredentials: true,
        });
      } else {
        loginResponse = await axiosInstance({
          url:`${APIENDPOINT_AUTH}/authenticate`,
          method: 'POST',
          data: { email, password }
        });
      }

      const responsePermissions = await axiosInstance.get(`${APIENDPOINT}/users/${loginResponse.data.data.user.id}/authz`, { headers: { 'Authorization': `Bearer ${loginResponse.data.data.token}`} });
      let permissions = []
      if(responsePermissions?.data?.data?.user[0]?.permissions) {
        permissions = responsePermissions.data.data.user[0].permissions
      }
      dispatch(login({ user: loginResponse.data.data.user, role: loginResponse.data.data.user.role, token: loginResponse.data.data.token, permissions: permissions }));
      toast.custom(
        <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
          <div>
            <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
          </div>
          <div>
            <h6 className="fw-bold mb-0 text-estorya">Successfully Logged In</h6>
            <p className="mb-0">Welcome to eStorya</p>
          </div>
        </div>,{
          duration: 2000,
        }
       );
    } catch(e) {
      setErrors(['Invalid username or password.'])
      console.log(e);
    } finally {
      setSubmitting(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div id="login" className="animated fadeIn flex-grow-1 d-flex flex-column bg-estoryalight" style={{ minHeight: 'calc(100vh)' }}>
      <Helmet>
        <title>{`eStorya`}</title>
      </Helmet>

      <div className="container-fluid flex-grow-1 d-flex">
        <div className="row flex-grow-1 no-gutters justify-content-center align-items-center">
          <div className="col-lg-3 col-md-5 col-sm-6 col-xs-12">
            <div className="card shadow border-0" style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)', backdropFilter: 'blur(5px)' }}>
              <div className="card-body p-5">
                <img alt="eStorya" src={logo} className="mb-4 d-block mx-auto w-75" />
                {errors.length > 0 &&
                  <div className="alert alert-warning">
                    {errors.map((error, errorIdx) => (<span key={`error-${errorIdx}`}>{error}</span>))}
                  </div>
                }
                <form onSubmit={validate} className="mb-4">
                  <div className="mb-3">
                    <label htmlFor="email" className="form-label fw-bold">Email</label>
                    <input type="email" className="form-control" id="email" disabled={submitting} />
                  </div>
                  <div className="mb-3 position-relative">
                    <label htmlFor="password" className="form-label fw-bold">Password</label>
                    <input type={!showPassword ? 'password' : 'text'} className="form-control" id="password" disabled={submitting} />
                    <button type="button" className="position-absolute px-2 py-2 border-0 bg-transparent" style={{ right: '1px', marginTop: '-37px' }} onClick={() => { setShowPassword(!showPassword); }}><img src={!showPassword ? iconShowPassword : iconHidePassword} alt="show/hide password" /></button>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="mb-3 form-check">
                      <input type="checkbox" className="form-check-input" id="remember" />
                      <label className="form-check-label" htmlFor="remember" disabled={submitting}>Remember Me</label>
                    </div>
                    <div className="mb-4">
                      <NavLink to="/forgot-password" className="text-estorya">Forgot Password</NavLink>
                    </div>
                  </div>
                  <button type="submit" className="btn w-100 btn-estorya" disabled={submitting}>Login</button>
                </form>
                <a href="https://skunkworks.ai/Privacy-Policy" className="text-secondary text-center d-block">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Login;
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconExit from './../icon-exit.svg';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import { APIENDPOINT, axiosInstance } from '../../../../config';
import { useCallback } from 'react';


const SelectedValuesContainer = ({ isDisabled, getValue, ...props }) => {
  const { getOptionValue, formatOptionLabel, removeStudent } = props.selectProps;

  const getValueLabel = (opt) => formatOptionLabel?.(opt, "value") || opt.label;
  const getKey = (opt, index) => `${getOptionValue(opt)}-${index}`;
  
  const toMultiValue = (opt, index) => (
    <components.MultiValue
      getValue={getValue}
      {...props}
      components={{
        Container: components.MultiValueContainer,
        Label: components.MultiValueLabel,
        Remove: components.MultiValueRemove
      }}
      isDisabled={isDisabled}
      key={getKey(opt, index)}
      index={index}
      removeProps={{
        onClick: () => removeStudent(opt),
        onTouchEnd: () => removeStudent(opt),
        onMouseDown: (e) => {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
      data={opt}
    >
      {getValueLabel(opt)}
    </components.MultiValue>
  );
  
  return (
    <div className="card my-2 d-flex" style={{ border: '1px solid #e4e7ea', minHeight: '200px' }}>
      {getValue().length === 0 ?
        <div className="text-center flex-grow-1 d-flex align-items-center justify-content-center">
          <div>
            <h5 className="fw-bold">Add students to this school</h5>
            <p className="mb-0">
              Type in your student’s email on the search box above. 
            </p>
          </div>
        </div>
        :
          <div className="flex-grow-1 flex-wrap p-2">
            {getValue().map(toMultiValue)}
          </div>
        }
    </div>
  );
};
  
const SelectContainer = ({
  children,
  className,
  innerProps,
  isFocused,
  ...commonProps
}) => {
  const selectContainerProps = {
    ...commonProps
  };

  return (
    <components.SelectContainer
      className={className}
      innerProps={innerProps}
      isFocused={isFocused}
      {...selectContainerProps}
    >
      {children}
      <SelectedValuesContainer {...commonProps} />
    </components.SelectContainer>
  );
};
  
const ValueContainer = ({children}) => {
  return (
    <div className="d-flex align-items-center">
      <FontAwesomeIcon icon="fa-search" className="d-inline-block px-2 text-muted" />
      {children}
    </div>
  ) 
};
  

function ExistingStudentForm ({closeModal, showResult, classroomOptions}) {
    const token = useSelector(state => state.app.token);
    const {school_id} = useSelector(state => state.app.user)
    const [addStudentsIsSubmitting, setAddStudentsIsSubmitting] = useState(false)
    const [studentOptions, setStudentOptions] = useState([])
    const [loadingUnassignedStudents, setLoadingUnassignedStudents] = useState(false)
    const [selectedStudents, setSelectedStudents] = useState({
      students:[],
      classroom:''
    });
    const [inputValue, setInputValue] = useState('');
    const canSaveAddStudents = (
      selectedStudents.students.length
      && selectedStudents.classroom);


    const fetchUnassignedStudents = useCallback(async (keyFilter) => {
      setLoadingUnassignedStudents(true)
      axiosInstance.get(`${APIENDPOINT}/users?page=1&limit=100&direction=asc&sortBy=lastname&role=Student&isUnassigned=1&key=${keyFilter}`, { headers: { 'Authorization': `Bearer ${token}`} }).then((res)=>{
        if(res?.data?.data?.data){
          setStudentOptions(res.data.data.data.sort((studentA, studentB) => { return studentA.firstname.localeCompare(studentB.firstname); }).map(student => ({...student, value: student.id, label: `${student.email}` })));
        }
        setLoadingUnassignedStudents(false)
      })
    }, [token]);

    useEffect(()=>{
      fetchUnassignedStudents('')
    },[])

    useEffect(() => {
      // if (inputValue === '') {
      //   return; 
      // }
     
      const hasPartialMatch = studentOptions.some(option =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
  
      if (hasPartialMatch) {
        return; 
      }

      const delayDebounceFn = setTimeout(() => {
        fetchUnassignedStudents(inputValue);
      }, 500); 
      
      return () => clearTimeout(delayDebounceFn);
    }, [inputValue, fetchUnassignedStudents]);

    const removeStudent = useCallback((removedStudent) => {
      setSelectedStudents({ 
        ...selectedStudents, 
        students: selectedStudents.students.filter((student) => student.value !== removedStudent.value)
      });
    }, [selectedStudents]);

    const addStudents = () => {
 
      setAddStudentsIsSubmitting(true);
      let studentIds = []
      selectedStudents.students.map((selectedStudent) =>{
        studentIds.push(selectedStudent.value)
        return true;
      })
      let payload = {
        students:studentIds, 
        classroom_id:selectedStudents.classroom.value
      }

      axiosInstance({
        url:`${APIENDPOINT}/school/${school_id}/students`,
        method: 'POST',
        data: payload,
        headers: { 'Authorization': `Bearer ${token}`}
      }).then((res)=>{
        showResult(selectedStudents.students)
      }).catch((e)=>{
        setAddStudentsIsSubmitting(false)
      })
    }; 

    return(
        <>
            <div className="modal-header border-0">
                <h6 className="modal-title fw-bold">Add New Student</h6>
                <button 
                    type="button" 
                    className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                    style={{ right: '10px', top: '10px' }} 
                    disabled={addStudentsIsSubmitting}
                    onClick={() => closeModal()}>
                    <img alt="" src={iconExit} />
                </button>
            </div>
            <div className="modal-body">
                <div className="mb-3">
                    <label htmlFor="classroom" className="form-label fw-bold">Assign Classroom<span className="text-danger">*</span></label>
                    <Select
                        value={selectedStudents.classroom}
                        onChange={(e)=> { setSelectedStudents({...selectedStudents, classroom: e}) }}
                        options={classroomOptions}
                        // menuPlacement="top"
                    />
                </div>
                <div className="mb-2">
                <label className="form-label fw-bold">Add Student(s) to group</label>
                <Select
                  isMulti
                  value={selectedStudents.students}
                  onChange={(e) => { setSelectedStudents({...selectedStudents, students: e}) }}
                  onBlur={(e)=>{setInputValue("")}}
                  removeStudent={removeStudent}
                  options={studentOptions}
                  backspaceRemovesValue={false}
                  components={{ SelectContainer, ValueContainer }}
                  controlShouldRenderValue={false}
                  onInputChange={(value) => {setInputValue(value)}}
                  inputValue={inputValue}
                  placeholder="Search email address"
                  isLoading={loadingUnassignedStudents}
                  styles={{
                    valueContainer: (css) => ({ ...css, display: "grid" }),
                    indicatorsContainer: (base, state) => ({
                      ...base,
                      display: 'none'
                    }),
                    menu: (base, state) => ({
                      ...base,
                      top: "35px"
                    }),
                    multiValue: (base) => ({
                      ...base,
                      display: 'inline-flex',
                      border: 'solid 1px #5B8DF5',
                      borderRadius: '0.25rem',
                      backgroundColor: '#5B8DF533',
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: '#5B8DF5',
                      fontWeight: 'bold'
                    }),
                    multiValueRemove: (base) => ({
                      ...base,
                      color: '#5B8DF5',
                    }),
                    menuList: (base) => ({
                      ...base,
                      maxHeight: '200px'
                    })
                  }}
                />
              </div>
            </div> 
            <div className="modal-footer d-flex">
                <button onClick={addStudents}  type="button" className="btn btn-estorya" disabled={!canSaveAddStudents || addStudentsIsSubmitting} >Add Student Account</button>
            </div>
        </>
    )
}


export default ExistingStudentForm;
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink, useSearchParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APIENDPOINT_AUTH, axiosInstance } from '../../config';
// import { ROLES } from '../../config';
import logo from '../../assets/img/logo.svg';
import iconShowPassword from './Icon-show-eye-fill.svg';
import iconHidePassword from './icon-hide-eye-fill.svg';
import './ResetPassword.css';

const minLength = 8;
const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

function ResetPassword() {

  const [searchParams, ] = useSearchParams();
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState([]);

  const token = searchParams.get("token");
  const email = searchParams.get("email");

  const validate = async(e) => {
    e.preventDefault();

    const { password: { value: password }, password_confirmation: { value: password_confirmation } } = e.target.elements;
    setErrors([]);

    if (password.length < minLength) {
      setErrors([`Password must be at least ${minLength} characters long`]);
      return;
    }
    if (!specialCharPattern.test(password)) {
      setErrors(['Password must contain at least one special character']);
      return;
    }
    if (password !== password_confirmation) {
      setErrors(['Passwords do not match.'])
      return;
    }

    setSubmitting(true);
    try {

      const formData = new FormData();
      formData.append("token", token);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("password_confirmation", password_confirmation);

      await axiosInstance({
        url:`${APIENDPOINT_AUTH}/password/reset`,
        method: 'POST',
        data: formData,
      });

      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '400px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-1 text-estorya">Your password has been updated</h6>
        </div>
      </div>);

      setPasswordUpdated(true);
    } catch(e) {
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '400px' }}>
        <div>
          <FontAwesomeIcon icon="times-circle" size="2x" className="me-3 text-danger" />
        </div>
        <div>
          <h6 className="fw-bold mb-1 text-danger">There's an error updating your password</h6>
          <p className="mb-0">Please try again later.</p>
        </div>
      </div>);
      console.log(e);
    } finally {
      setSubmitting(false);
    }

  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div id="login" className="animated fadeIn flex-grow-1 d-flex flex-column bg-estoryalight" style={{ minHeight: 'calc(100vh)' }}>
      <Helmet>
        <title>{`eStorya: Reset Password`}</title>
      </Helmet>

      <div className="container-fluid flex-grow-1 d-flex">
        <div className="row flex-grow-1 no-gutters justify-content-center align-items-center">
          <div className="col-lg-3 col-md-5 col-sm-6 col-xs-12">
            <div className="card shadow border-0" style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)', backdropFilter: 'blur(5px)' }}>
              <div className="card-body p-5">
                <img alt="eStorya" src={logo} className="mb-4 d-block mx-auto w-75" />
                {!passwordUpdated ?
                  <>
                    <h5 className="text-center text-estorya mb-3">Reset Password</h5>
                    {errors.length > 0 &&
                      <div className="alert alert-warning">
                        {errors.map((error, errorIdx) => (<span key={`error-${errorIdx}`}>{error}</span>))}
                      </div>
                    }
                    <form onSubmit={validate} className="mb-4">
                      <div className="mb-3 position-relative">
                        <label htmlFor="password" className="form-label fw-bold">Password</label>
                        <input type={!showPassword ? 'password' : 'text'} className="form-control" id="password" disabled={submitting} />
                        <button type="button" className="position-absolute px-2 py-2 border-0 bg-transparent" style={{ right: '1px', marginTop: '-37px' }} onClick={() => { setShowPassword(!showPassword); }}><img src={!showPassword ? iconShowPassword : iconHidePassword} alt="show/hide password" /></button>
                      </div>
                      <div className="mb-3 position-relative">
                        <label htmlFor="password" className="form-label fw-bold">Confirm Password</label>
                        <input type={!showPassword ? 'password' : 'text'} className="form-control" id="password_confirmation" disabled={submitting} />
                        <button type="button" className="position-absolute px-2 py-2 border-0 bg-transparent" style={{ right: '1px', marginTop: '-37px' }} onClick={() => { setShowPassword(!showPassword); }}><img src={!showPassword ? iconShowPassword : iconHidePassword} alt="show/hide password" /></button>
                      </div>
                      <button type="submit" className="btn w-100 btn-estorya mb-2" disabled={submitting}>Reset Password</button>
                    </form>
                  </>
                  :
                  <>
                    <h5 className="text-center text-estorya mb-3">Password Updated</h5>
                    <p className="text-center">
                      You successfully updated your password.<br/>Click the button below to log in to estorya portal.
                    </p>
                    <NavLink to="/login" className="btn w-100 btn-estorya mb-4">Login</NavLink>
                  </>
                }
                <a href="https://skunkworks.ai/Privacy-Policy" className="text-secondary text-center d-block">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default ResetPassword;
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams  } from "react-router-dom";
import moment from 'moment';
import { useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from 'react-chartjs-2';

// import { faker } from '@faker-js/faker';

import { APIENDPOINT, axiosInstance } from '../../config';
import iconCLassroomSolid from './icon-classroom-solid.svg';
import { toRoman } from '../../utils/utls';
import iconActions from './icon-actions.svg';
import classroomBg from './classroomBg.svg';
import star from './star.svg';
import OverAllScoreOverlay from './OverAllScoreOverlay';

const READINGLEVELS = [
  { name: 'No Assessment' },
  { name: 'Beginner Level' },
  { name: 'Letters Level' },
  { name: 'Word Level' },
  { name: 'Paragraph Level' },
  { name: 'Story Level' },
];

const CATEGORIES = [
  { name: 'Romance' },
  { name: 'Non-fiction' },
  { name: 'Folk Tale' },
  { name: 'Fiction' },
  { name: 'Mystery' }
];


ChartJS.register(ArcElement, Tooltip, Legend);
const sampleReadingLevelData = {
  options: {
    plugins: {
      legend: {
        display: false
      },
    },
    cutout: '65%',
    responsive: true,
    // maintainAspectRatio: false,
  },
  data: {
    labels: READINGLEVELS.map(level => level.name),
    datasets: [
      {
        label: ' Students',
        data: [2, 3, 5, 6, 15, 0],
        backgroundColor: [
          '#B3B7C1',
          '#9F97F7',
          '#FFB44F',
          '#F99BAB',
          '#9BDFC4',
          '#62B2FD',
        ],
        borderColor: [
          '#B3B7C1',
          '#9F97F7',
          '#FFB44F',
          '#F99BAB',
          '#9BDFC4',
          '#62B2FD',
        ],
        borderWidth: 1,
      },
    ],
  }
}

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function Classroom() {

  const params = useParams();
  const navigate = useNavigate ();
  const token = useSelector(state => state.app.token);
  const [classroom, setClassroom] = useState({});
  const [classroomTeacher, setClassroomTeacher] = useState(null);
  const [classroomStudents, setClassroomsStudents] = useState([]);
  const [classroomReadingLevelDistribution, setClassroomReadingLevelDistribution] = useState({});
  const [classroomBooksUsage, setClassroomBooksUsage] = useState([]);
  const [classroomReadLevel, setClassroomReadLevel] = useState([]);
  const [classroomBooksDownloaded, setClassroomBooksDownloaded] = useState([]);
  const [selectedStudentIDX, setSelectedStudentIDX] = useState(-1);
  const [studentStatisticsSummary, setStudentStatisticsSummary] = useState({ booksCompleted: null, bestReadingSpeed: null, mostActiveMonth: null });
  const [studentStatisticsRatings, setStudentStatisticsRatings] = useState([]);
  const [showOVerAllScore, setShowOverAllScore] = useState(false)
  const [overAllScore, setOverAllScore] = useState({})
  const [categories, setCategories] = useState([])

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchClassroom = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms/${params.id}`, { headers: { 'Authorization': `Bearer ${token}`} });
        
        setClassroom(response.data.data.classroom);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchClassroomStudents = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms/${params.id}/students`, { headers: { 'Authorization': `Bearer ${token}`} });
        setClassroomsStudents(response.data?.data ? response.data?.data.sort((studentA, studentB) => { return studentA.lastname.localeCompare(studentB.lastname); }) : []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchClassroomReadingLevelDistribution = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms/${params.id}/statistics/readdistribution`, { headers: { 'Authorization': `Bearer ${token}`} });

        const remappedResponse = {
          ...response,
          data: {
            ...response.data,
            data: {
              ...response.data.data,
              distributions: [...response.data.data.distributions.filter(distribution => distribution.reader_level !== READINGLEVELS[0].name), ...response.data.data.distributions.filter(distribution => distribution.reader_level === READINGLEVELS[0].name)]
            }
          }
        };

        const backgroundColor = remappedResponse.data.data?.distributions?.map((distribution, distributionIdx) => distribution.reader_level === READINGLEVELS[0].name ? sampleReadingLevelData.data.datasets[0].backgroundColor[0] : sampleReadingLevelData.data.datasets[0].backgroundColor[distributionIdx + 1])
        const borderColor = remappedResponse.data.data?.distributions?.map((distribution, distributionIdx) => distribution.reader_level === READINGLEVELS[0].name ? sampleReadingLevelData.data.datasets[0].borderColor[0] : sampleReadingLevelData.data.datasets[0].borderColor[distributionIdx + 1])

        const data = {
          studentCount: remappedResponse.data.data?.studentcount,
          labels: remappedResponse.data.data?.distributions?.map(distribution => distribution.reader_level),
          datasets: [
            {
              label: ' Students',
              data: remappedResponse.data.data?.distributions?.map(distribution => distribution.user_count),
              backgroundColor,
              borderColor,
              borderWidth: 1,
            }
          ]
        };

        setClassroomReadingLevelDistribution(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchClassroomBooksUsage = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms/${params.id}/statistics/bookusage`, { headers: { 'Authorization': `Bearer ${token}`} });

        const data = response.data.data?.rows?.sort((a, b) => { return Number.parseFloat(b.usage_percentage) - Number.parseFloat(a.usage_percentage); })

        setClassroomBooksUsage(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    const fetchClassroomStudentReadingLevel = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms/${params.id}/statistics/readlevel`, { headers: { 'Authorization': `Bearer ${token}`} });
        
        setClassroomReadLevel(response.data.data?.rows.sort((studentA, studentB) => { return studentA.lastname.localeCompare(studentB.lastname)}));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchClassroomDownloaded = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms/${params.id}/statistics/bookdownload`, { headers: { 'Authorization': `Bearer ${token}`} });

        const data = response.data.data?.rows?.sort((a, b) => { return b.download_count - a.download_count; })
        const newCategories = [...new Set(data.map(book => book.book_genre))].map(genre => ({ name: genre }));
        setCategories(newCategories)
        setClassroomBooksDownloaded(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchClassroom();
    fetchClassroomStudents();
    fetchClassroomReadingLevelDistribution();
    fetchClassroomBooksUsage();
    fetchClassroomStudentReadingLevel();
    fetchClassroomDownloaded();
  }, [params.id, token]);

  useEffect(() => {
    const fetchClassroomTeacher = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/users/${classroom.teacher_id}`, { headers: { 'Authorization': `Bearer ${token}`} });
        setClassroomTeacher(response.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    if(classroom.teacher_id) {
      fetchClassroomTeacher();
    }
  }, [classroom.teacher_id, token]);

  useEffect(() => {
    const fetchStudentStatisticsSummary = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/students/${classroomStudents[selectedStudentIDX]?.id}/statistics/summary`, { headers: { 'Authorization': `Bearer ${token}`} });
        
        const data = {
          booksCompleted: response.data.data.completed,
          bestReadingSpeed: response.data.data.best_speed ,
          mostActiveMonth: response.data.data.most_active
        };
        setStudentStatisticsSummary(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchStudentStatisticsRatings = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/students/${classroomStudents[selectedStudentIDX]?.id}/statistics/books`, { headers: { 'Authorization': `Bearer ${token}`} });
        
        setStudentStatisticsRatings(response.data.data.books);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if(selectedStudentIDX !== -1) {
      fetchStudentStatisticsSummary();
      fetchStudentStatisticsRatings();
    }
  }, [classroomStudents, selectedStudentIDX, token]);

  // const total = sampleReadingLevelData.data.datasets[0].data.reduce(function (acc, obj) { return acc + obj; }, 0);
  const total = classroomStudents.length ? classroomStudents.length : '-';
  // const topThreeMostReadBooks = [...sampleClassroom.books].sort((a, b) => a.downloads - b.downloads).slice(0, 3);
  const topThreeMostReadBooks =  classroomBooksUsage.length > 0 ? [...classroomBooksUsage].slice(0, 3) : [];
  
  const toggleSelectedStudentIDX = (idx) => {
    if(selectedStudentIDX === idx) { setSelectedStudentIDX(-1); }
    else { setSelectedStudentIDX(idx); }
  };

  let selectedStudent = null;
  if(selectedStudentIDX !== -1) {
    selectedStudent = classroomStudents[selectedStudentIDX];
  };

  // const selectedStudentBooks = faker.number.int({ min: 1, max: sampleClassroom.books.length });
  // let shuffledBooks = shuffleArray([...sampleClassroom.books]).slice(0, selectedStudentBooks).sort((a, b) => a.title.localeCompare(b.title));

  // console.log(classroom.classroom)
  const [searchStudent, setSearchStudent] = useState("");

  const [readingLevelFilter, setReadingLevelFilter] = useState("all");
  const [booksCategoryFilter, setBooksCategoryFilter] = useState("all");
  const [studentRadingBookProgressFilter, setStudentRadingBookProgressFilter] = useState("all");

  return (
    <div id="classroom" className="animated fadeIn flex-grow-1 d-flex flex-column">
      <Helmet>
        <title>{`eStorya: Classroom Management - Classrooms - Classroom ${classroom.name}`}</title>
      </Helmet>

      <div className="header container-fluid align-items-center d-flex px-4" style={{ height: '140px', backgroundColor: '' }}>
        <div className="row">
          <div className="col">
            <div className="text-muted mb-3 d-flex align-items-center"><img alt="" src={iconCLassroomSolid} className="me-1" /> Classroom Management<span className="mx-2">/</span><span className="text-dark">Grade {toRoman(classroom.grade_level_id)} -&nbsp; <span className="text-capitalize">{classroom.name}</span></span></div>
            <h3 className="fw-bold text-estorya d-flex">
              <button type="button" className="btn btn-link text-estorya me-2 py-0 my-0" onClick={goBack}><FontAwesomeIcon icon="arrow-left" /></button>
              <span>Grade {toRoman(classroom.grade_level_id)} -&nbsp;</span><span className="text-capitalize">{classroom.name}</span></h3>
            <p className="mb-0 text-muted">Your classroom and student data at a glance</p>
          </div>
        </div>
      </div>

      <div className="page-content flex-grow-1 container-fluid pt-4 bg-light px-4" style={{ height: 'calc(100vh - 218px)', overflowY: 'scroll' }}>

        <div className="row">

          <div className="col-4">
            <div className="card border-0 shadow-sm mb-4" style={{ position: 'sticky', top: 0 }}>
              <div className="card-body p-4">
                <div className="mb-4">
                  {selectedStudentIDX !== -1 ?
                    <h5 className="fw-bold cursor-pointer mb-3" onClick={() => { toggleSelectedStudentIDX(-1); }}>
                      <span>Grade {toRoman(classroom.grade_level_id)} -&nbsp;</span><span className="text-capitalize">{classroom.name}</span>
                    </h5>
                    :
                    <h5 className="fw-bold text-estorya mb-3">
                      <span>Grade {toRoman(classroom.grade_level_id)} -&nbsp;</span><span className="text-capitalize">{classroom.name}</span>
                    </h5>
                  }
                  <p className='mb-1'><span className='text-muted'>Classroom Teacher:</span> <strong>{classroomTeacher?.user?.firstname} {classroomTeacher?.user?.lastname}</strong></p>
                  <p className='mb-1'><span className='text-muted'>Total Students: </span> <strong>{classroomStudents.length}</strong></p>
                  <p className='mb-1'><span className='text-muted'>Date Created: </span> <strong>{classroom.created_at ? moment(classroom.created_at).format('MMMM DD, YYYY') : '-'}</strong></p>
                </div>
                <div className="px-2 py-3 border">
                  <div className="input-group mb-3">
                    <label className="input-group-text bg-transparent border-right-0 border-end-0" htmlFor="searchClassroom"><FontAwesomeIcon icon="search" /></label>
                    <input type="text" className="form-control border-left-0 border-start-0" id="searchClassroom" placeholder="Search for Student's name" style={{ width: '20rem' }} value={searchStudent} onChange={(e) => { setSearchStudent(e.target.value) }} />
                  </div>
                  <ul className="list-group list-group-flush" style={{ height: 'calc(100vh - 515px)', overflowY: 'auto' }}>
                    {/* {sampleClassroom.students.map((student, idx) => { */}
                    {classroomStudents.map((student, idx) => {
                      const regex = new RegExp(escapeRegExp(searchStudent), 'gi');
                      const matched = `${student.firstname} ${student.lastname} ${student.middlename} ${student.middlename[0]}.`.match(regex);

                      if(matched) {
                        return (
                          <li key={`classroom-${params.id}-student-${idx}`} className={`list-group-item d-flex align-items-center justify-content-between ${selectedStudentIDX === idx ? 'bg-light text-estorya' : ''}`}>
                            <div className="d-flex align-items-center cursor-pointer flex-grow-1" onClick={() => { toggleSelectedStudentIDX(idx); }}>
                                <Avatar src={student.picture} name={`${student.firstname} ${student.lastname}`} round={true} size={30} className="me-2" />
                              <div>
                                <strong>{student.lastname},</strong>&nbsp;
                                {student.firstname} {student.middlename[0] ? `${student.middlename[0]}.` : ''} 
                              </div>
                            </div>
                            <div>
                              <UncontrolledDropdown>
                                <DropdownToggle color="outline-light" className="py-1 px-2">
                                  <img src={iconActions} alt="options" />
                                </DropdownToggle>
                                <DropdownMenu end className="py-1" style={{ minWidth: '180px' }}>
                                  <DropdownItem className="px-3 py-1 btn btn-sm w-100 text-start border-0 btn-outline-dark rounded-0" onClick={() => { toggleSelectedStudentIDX(idx); }}>
                                    {selectedStudentIDX !== idx ? 'Select Student' : 'Deselect Student'}
                                  </DropdownItem >
                                  <DropdownItem  className="px-3 disabled py-1 btn btn-sm w-100 text-start border-0 btn-outline-dark rounded-0" disabled>Edit Student</DropdownItem >
                                  <DropdownItem  className="px-3 disabled py-1 btn btn-sm w-100 text-danger text-start border-0 btn-outline-danger rounded-0" disabled>Archive Student</DropdownItem >
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                          </li>
                        );
                      }

                      return null;
                    })}

                  </ul>
                </div>
              </div>
            </div>
          </div>

          {selectedStudentIDX === -1 &&
            <div className="col">
              <div className="card border-0 shadow-sm mb-4">
                <div className="px-3 mt-5 d-flex align-items-center mb-3 shadow-sm" style={{ backgroundImage: `url(${classroomBg})`, backgroundSize: 'cover', height: 60 }}>
                  <div>
                    <h5 className="text-white fw-bold mb-0"><span>Grade {toRoman(classroom.grade_level_id)} -&nbsp;</span><span className="text-capitalize">{classroom.name}</span></h5>
                    <div className="">
                      {/* {sampleClassroom.assignedTeachers.map((assignedTeacher, idx) => (
                        <span key={`classroom-${classroom.id}-group-${idx}`} className="d-inline-block text-white me-2">{assignedTeacher}{idx === sampleClassroom.assignedTeachers.length - 1 ? '' : ','}</span>
                      ))} */}
                        <span className="d-inline-block text-white me-2">{classroomTeacher?.user?.firstname} {classroomTeacher?.user?.lastname}</span>
                    </div>
                  </div>
                </div>
                <div className="card-body">

                  <div className="row mb-3">
                    <div className="col">
                      <div className="card border h-100">
                        <div className="card-body">
                          <div className="row mb-4">
                            <div className="col d-flex">
                              <p className="text-uppercase text-muted fw-bold my-auto">Reading Level Distribution</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 position-relative">
                              {/* <Doughnut data={sampleReadingLevelData.data} options={sampleReadingLevelData.options} /> */}
                              {Object.keys(classroomReadingLevelDistribution).length > 0 &&
                                <Doughnut data={classroomReadingLevelDistribution} options={sampleReadingLevelData.options} />
                              }
                              <div className="position-absolute text-center" style={{ left: '50%', top: '50%', marginTop: '-30px', marginLeft: '-30px' }}>
                                <span className="fs-4 fw-bold">
                                  {total}
                                </span><br/>
                                <span className="fs-6 text-muted">Students</span>
                              </div>
                            </div>
                            <div className="col-6 d-flex align-items-center">
                              <div className="w-100">
                                {/* {sampleReadingLevelData.data.labels.map((level, levelIdx) => {
                                  return (
                                    <div key={`label-${level}`} className="d-flex align-items-center justify-content-between mb-1">
                                      <div className="d-flex align-items-center">
                                        <div className="me-2" style={{ width: 10, height: 10, backgroundColor: sampleReadingLevelData.data.datasets[0].backgroundColor[levelIdx] }} />
                                        <span>{level}</span>
                                      </div>
                                      <div>
                                        <span className='fs-6 fw-bold'>{sampleReadingLevelData.data.datasets[0].data[levelIdx]}</span>
                                        <span className="text-muted small">/{total}</span>
                                      </div>
                                    </div>
                                  )
                                })} */}
                                {Object.keys(classroomReadingLevelDistribution).length > 0 && 
                                  <>
                                    {classroomReadingLevelDistribution.labels.map((level, levelIdx) => {
                                      return (
                                        <div key={`label-${level}`} className="d-flex align-items-center justify-content-between mb-1">
                                          <div className="d-flex align-items-center">
                                            <div className="me-2" style={{ width: 10, height: 10, backgroundColor: classroomReadingLevelDistribution.datasets[0].backgroundColor[levelIdx] }} />
                                            <span>{level}</span>
                                          </div>
                                          <div>
                                            <span className='fs-6 fw-bold'>{classroomReadingLevelDistribution.datasets[0].data[levelIdx]}</span>
                                            <span className="text-muted small">/{total}</span>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card border h-100">
                        <div className="card-body">
                          <div className="row mb-4">
                            <div className="col d-flex">
                              <p className="text-uppercase text-muted fw-bold my-auto">Top 3 Most Read Books</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <ol className="mx-3">
                                {topThreeMostReadBooks.map((book, idx) => {
                                  return (
                                    <li key={`topThreeMostReadBooks-${idx}`} className={`fw-bold text-estorya ps-2 ${idx === topThreeMostReadBooks.length - 1 ? '' : 'mb-3'}`}>
                                      <span className="fs-6 text-capitalize">{book.book_title}</span><br/>
                                      <span className="fw-normal text-muted"><strong>{book.usage_count}/{total}</strong> students read this book</span>
                                    </li>
                                  )
                                })}
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col">
                      <div className="card border">
                        <div className="card-body">
                          <div className="row mb-2">
                            <div className="col d-flex">
                              <p className="text-uppercase text-muted fw-bold my-auto">Student Reading Level</p>
                            </div>
                            <div className="col-4">
                              <div className="row g-3 align-items-center">
                                <label htmlFor="selectLevel" className="col-auto">Show Entries</label>
                                <div className="col">
                                  <select id="selectLevel" className="form-select rounded" onChange={(e) => { setReadingLevelFilter(e.target.value) }} value={readingLevelFilter}>
                                    <option value={'all'}>All</option>
                                    {READINGLEVELS.map((level, idx) => {
                                      return (<option key={`readingLevel-${idx}`} value={level.name}>{level.name}</option>)
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <table className="table mb-0">
                                <thead>
                                  <tr className="table-secondary">
                                    <th className="border-light position-sticky" style={{ top: '-25px', width: '33.3%' }}>
                                      <span className="me-2">Student Name</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort-up" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '33.3%'  }}>
                                      <span className="me-2">Reading Level</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '33.3%'}}>
                                      <span className="me-2">Date of Assessment</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {/* {sampleClassroom.students.map((student, idx) => {
                                    return (
                                      <tr key={`studentReadingLevel_classroom-${params.id}-student-${idx}`} classroom={classroom}>
                                        <td className="align-middle">{student.lastname}, {student.firstname}</td>
                                        <td className="align-middle text-center text-capitalize">{student.level.name}</td>
                                        <td className="align-middle text-center text-capitalize">{moment(student.date_assessment).format('DD-MMM-YYYY')}</td>
                                      </tr>
                                    )
                                  })} */}
                                  {classroomReadLevel.map((student, idx) => {
                                    
                                    const isVisible = (level) => {
                                      if(readingLevelFilter === 'all') {
                                        return true;
                                      } else {
                                        return level === readingLevelFilter;
                                      }
                                    }

                                    return (
                                      <tr key={`studentReadingLevel_classroom-${params.id}-student-${idx}`} className={`${isVisible(student.level) ? '' : 'd-none'}`}>
                                        <td className="align-middle">{student.lastname}, {student.firstname}</td>
                                        <td className="align-middle text-center text-capitalize">{student.level}</td>
                                        <td className="align-middle text-center text-capitalize">{student.last_rated_at ? moment(student.last_rated_at).format('DD-MMM-YYYY') : '-'}</td>
                                        {/* <td className="align-middle text-center text-capitalize">{student.last_rated_at_formatted}</td> */}
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="card border">
                        <div className="card-body">
                          <div className="row mb-2">
                            <div className="col d-flex">
                              <p className="text-uppercase text-muted fw-bold my-auto">Downloaded Books</p>
                            </div>
                            <div className="col-4">
                              <div className="row g-3 align-items-center">
                                <label htmlFor="selectLevel" className="col-auto">Show Entries</label>
                                <div className="col">
                                  <select id="selectLevel" className="form-select rounded" onChange={(e) => { setBooksCategoryFilter(e.target.value) }} value={booksCategoryFilter}>
                                    <option value={'all'}>All</option>
                                    {categories.map((category, idx) => {
                                      return (<option key={`category-${idx}`} value={category.name}>{category.name}</option>)
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <table className="table mb-0">
                                <thead>
                                  <tr className="table-secondary">
                                    <th className="border-light position-sticky" style={{ top: '-25px', width: '33.3%' }}>
                                      <span className="me-2">Book Title</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort-up" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '33.3%' }}>
                                      <span className="me-2">Category</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '33.3%' }}>
                                      <span className="me-2">Download Counts</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {/* {sampleClassroom.books.map((book, idx) => {
                                    return (
                                      <tr key={`downloadedBooks_classroom-${params.id}-book-${idx}`} classroom={classroom}>
                                        <td className="align-middle text-capitalize">{book.title}</td>
                                        <td className="align-middle text-center text-capitalize">{book.category.name}</td>
                                        <td className="align-middle text-center text-capitalize">{book.downloads} times</td>
                                      </tr>
                                    )
                                  })} */}
                                  {classroomBooksDownloaded.map((book, idx) => {

                                    const isVisible = (category) => {
                                      if(booksCategoryFilter === 'all') {
                                        return true;
                                      } else {
                                        return category === booksCategoryFilter;
                                      }
                                    }

                                    return (
                                      <tr key={`downloadedBooks_classroom-${params.id}-book-${idx}`} className={`${isVisible(book.book_genre) ? '' : 'd-none'}`}>
                                        <td className="align-middle text-capitalize">{book.book_title}</td>
                                        <td className="align-middle text-center text-capitalize">{book.book_genre}</td>
                                        <td className="align-middle text-center text-capitalize">{book.download_count} times</td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          }

          {selectedStudentIDX !== -1 &&
            <div className="col">
              <div className="card border-0 shadow-sm mb-4">
                <div className="py-2 px-3 mt-5 position-relative d-flex align-items-center mb-3 shadow-sm" style={{ backgroundImage: `url(${classroomBg})`, backgroundSize: 'cover', height: 60 }}>
                  <Avatar src={selectedStudent.picture} name={`${selectedStudent.firstname} ${selectedStudent.lastname}`} round={true} size={100} className="position-absolute me-2 shadow" style={{ marginTop: 0 }} />
                  <div style={{ marginLeft: 120 }}>
                    <h5 className="text-white fw-bold mb-0"><span>{selectedStudent.lastname}, {selectedStudent.firstname} {selectedStudent.middlename[0] ? `${selectedStudent.middlename[0]}.` : ''}</span></h5>
                    <p className="text-white mb-0">{selectedStudent.reader_level.level} Reader</p>
                  </div>
                </div>
                <div className="card-body">
                  <div className='d-flex mb-3'>
                    <div className="card border h-100 me-3" style={{minWidth:'300px'}}>
                      <div className="card-body">
                        <div className="row my-3">
                          <div className="col d-flex align-items-center justify-content-center">
                            <div>
                              <h5 className="fs-2 mb-0 text-estorya text-center fw-bold">{studentStatisticsSummary.booksCompleted ? studentStatisticsSummary.booksCompleted : '-'}</h5>
                              <p className="text-uppercase text-muted fw-bold my-auto text-center mb-0">Books Completed</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card border h-100" style={{minWidth:'300px'}}>
                      <div className="card-body">
                        <div className="row my-3">
                          <div className="col d-flex align-items-center justify-content-center">
                            <div>
                              <h5 className="fs-2 mb-0 text-estorya text-center fw-bold">{studentStatisticsSummary.mostActiveMonth ? studentStatisticsSummary.mostActiveMonth : '-'}</h5>
                              <p className="text-uppercase text-muted fw-bold my-auto text-center mb-0">Most Active Month</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div className="row mb-3">
                    <div className="col">
                      <div className="card border h-100">
                        <OverAllScoreOverlay
                          overAllScore={overAllScore}
                          setShowOverAllScore={setShowOverAllScore}
                          showOVerAllScore={showOVerAllScore}
                        />
                        <div className="card-body">
                          <div className="row mb-4">
                            <div className="col d-flex">
                              <p className="text-uppercase text-muted fw-bold my-auto">AI Assist Ratings</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <table className="table mb-0">
                                <thead>
                                  <tr className="table-secondary">
                                    <th className="border-light position-sticky" style={{ top: '-25px' }}>
                                      <span className="me-2">Book Title</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort-up" size="xs" /></button>
                                    </th>
                                    <th className="border-light position-sticky" style={{ top: '-25px' }}>
                                      <span className="me-2">Current Page</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort-up" size="xs" /></button>
                                    </th>
                                    <th className="border-light position-sticky" style={{ top: '-25px' }}>
                                      <span className="me-2">Score</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort-up" size="xs" /></button>
                                    </th>
                                    <th className="border-light position-sticky" style={{ top: '-25px' }}>
                                      <span className="me-2">Mistakes</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort-up" size="xs" /></button>
                                    </th>
                                    <th className="border-light position-sticky" style={{ top: '-25px' }}>
                                      <span className="me-2">Date Scored</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort-up" size="xs" /></button>
                                    </th>
                                    {/* <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '250px' }}>
                                      <span className="me-2">Rating</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '160px' }}>
                                      <span className="me-2">Reading Speed</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '160px' }}>
                                      <span className="me-2">Mistakes</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th> */}
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {studentStatisticsRatings.map((book, idx) => {
                                    return (
                                      <tr key={`downloadedBooks_classroom-${params.id}-bookratings-${idx}`}>
                                        <td className={`align-middle text-capitalize cursor-pointer ${overAllScore?.book?.title == book.book.title ? 'text-estorya' : ''}`} onClick={()=>{setShowOverAllScore(true); setOverAllScore(book)}}>
                                          {book.book?.title}
                                        </td>
                                        <td className={`align-middle text-capitalize `} onClick={()=>{setShowOverAllScore(true); setOverAllScore(book)}}>
                                          {book?.current_page}
                                        </td>
                                        <td className={`align-middle text-center`} onClick={()=>{setShowOverAllScore(true); setOverAllScore(book)}}>
                                          -
                                        </td>
                                        <td className={`align-middle text-center text-danger fw-bold`} onClick={()=>{setShowOverAllScore(true); setOverAllScore(book)}}>
                                          {book?.mistakes}
                                        </td>
                                        <td className={`align-middle text-center`} onClick={()=>{setShowOverAllScore(true); setOverAllScore(book)}}>
                                          -
                                        </td>
                                        {/* <td className="align-middle text-center text-capitalize">
                                          {book.rate !== "0" ?
                                            <>
                                              {[...Array(parseInt(book.rate, 10)).keys()].map((rating, idx2) => {
                                                return <img key={`book-${idx}-rating-${idx2}`} src={star} alt="star" />;
                                              })}
                                            </>
                                            :
                                            <span>No Rating</span>
                                          }
                                        </td>
                                        <td className="align-middle text-center">
                                          <strong className="fs-6">{Math.ceil(parseFloat(book.speed))}</strong> <span className="text-muted">words/min</span>
                                        </td>
                                        <td className="align-middle text-center text-capitalize fw-bold">
                                          <span className="text-danger">{book.mistakes}</span><span className="text-muted">/{book.total_words}</span>
                                        </td> */}
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col">
                      <div className="card border h-100">
                        <div className="card-body">
                          <div className="row mb-4">
                            <div className="col d-flex">
                              <p className="text-uppercase text-muted fw-bold my-auto">Reading Book Progress</p>
                            </div>
                            <div className="col-4">
                              <div className="row g-3 align-items-center">
                                <label htmlFor="selectLevel" className="col-auto">Show Entries</label>
                                <div className="col">
                                  <select id="selectLevel" className="form-select rounded" onChange={(e) => { setStudentRadingBookProgressFilter(e.target.value); }} value={studentRadingBookProgressFilter}>
                                    <option value={'all'}>All</option>
                                    <option value={'In-Progress'}>In-progress</option>
                                    <option value={'completed'}>Completed</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col">
                              <table className="table mb-0">
                                <thead>
                                  <tr className="table-secondary">
                                    <th className="border-light position-sticky" style={{ top: '-25px' }}>
                                      <span className="me-2">Book Title</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort-up" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '250px' }}>
                                      <span className="me-2">Status</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '160px' }}>
                                      <span className="me-2">Date Started</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                    <th className="text-center border-light position-sticky" style={{ top: '-25px', width: '160px' }}>
                                      <span className="me-2">Date Finished</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted disabled" disabled><FontAwesomeIcon icon="sort" size="xs" /></button>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody className="bg-white">
                                  {studentStatisticsRatings.map((book, idx) => {
                                    const isVisible = (status) => {
                                      if(studentRadingBookProgressFilter === 'all') {
                                        return true;
                                      } else {
                                        return status === studentRadingBookProgressFilter;
                                      }
                                    }

                                    return (
                                      <tr key={`downloadedBooks_classroom-${params.id}-bookprogress-${idx}`} className={`${isVisible(book.status) ? '' : 'd-none'}`}>
                                        <td className="align-middle text-capitalize">{book.book?.title}</td>
                                        <td className="align-middle text-center text-capitalize">
                                          {book.status}
                                        </td>
                                        <td className="align-middle text-center">
                                          {book.downloaded_at ? moment(book.downloaded_at).format('DD-MMM-YYYY') : '-'}
                                        </td>
                                        <td className="align-middle text-center">
                                          -
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          }

        </div>
      </div>

    </div>
  );
};

export default Classroom;
import React, { useState } from "react";
import {
    Modal
  } from 'reactstrap';
import iconExit from './icon-exit.svg';
import Select, { components } from "react-select";
import iconRoundAdd from './icon-round-add.svg'
import { PERMISSIONS } from "../../../../config";
import { hasPermission } from "../../../../utils/user";

// Custom rendering for options
const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      style={{
        display: "flex",
        alignItems: "center",
        padding: "10px",
        cursor: "pointer",
      }}
    >
      {data.value == 'default' &&
        <>
          <img src={iconRoundAdd} />
          <span className="text-muted" style={{ marginLeft: 5 }}>{data.label}</span>
        </>
      }

      {data.value != 'default' &&
        <span>{data.label}</span>
      }
      
    </div>
  );
};

const CustomSingleValue = ({ children, ...props }) => { 

  return ( 
    <components.SingleValue {...props}> 
      <span className={`${props.data.value == 'default' ? 'text-muted' : ''}`}> {children} </span> 
    </components.SingleValue> 
  ); };

const AddUserModal = ({
    permissions,
    addUser,
    classroomOptions,
    addUserModalIsOpen, 
    setAddUserModalIsOpen,
    USEREDITMODE, 
    addUserValues, 
    setAddUserValues,
    canSaveAddUser, 
    addUserIsSubmitting}) => {

  return (
    <Modal isOpen={addUserModalIsOpen} size="md" centered={true} contentClassName="border-0 shadow">
    <div className="modal-content border-0 overflow-hidden">
      <form autoComplete="off" onSubmit={addUser}>
        <div className="modal-header border-0">
          {addUserValues.mode === USEREDITMODE.ADD &&
            <h6 className="modal-title fw-bold">Add New User</h6>
          }
          {addUserValues.mode === USEREDITMODE.EDIT &&
            <h6 className="modal-title fw-bold">Edit User</h6>
          }
          <button type="button" className="position-absolute btn btn-light btn-sm bg-transparent border-0" style={{ right: '10px', top: '10px' }} onClick={() => setAddUserModalIsOpen(false)}><img alt="" src={iconExit} /></button>
        </div>
        <div className="modal-body">

          <div className="mb-2">
            <label htmlFor="firstName" className="form-label fw-bold">First Name <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={addUserValues.firstname}
              onChange={(e) => { setAddUserValues({...addUserValues, firstname: e.target.value}) }}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="lastName" className="form-label fw-bold">Last Name <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              id="lastName"
              value={addUserValues.lastname}
              onChange={(e) => { setAddUserValues({...addUserValues, lastname: e.target.value}) }}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="middleName" className="form-label fw-bold">Middle Name </label>
            <input
              type="text"
              className="form-control"
              id="middlename"
              value={addUserValues.middlename}
              onChange={(e) => { setAddUserValues({...addUserValues, middlename: e.target.value}) }}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="email" className="form-label fw-bold">Email <span className="text-danger">*</span></label>
            <input
              type="email"
              className="form-control"
              id="email" 
              value={addUserValues.email}
              onChange={(e) => { setAddUserValues({...addUserValues, email: e.target.value}) }}
              disabled={addUserValues.mode === USEREDITMODE.EDIT}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="role" className="form-label fw-bold">Roles <span className="text-danger">*</span></label>
            <select id="role" className="form-select" value={addUserValues.role} onChange={(e) => { setAddUserValues({...addUserValues, role: e.target.value}) }}>
              {hasPermission(permissions, [PERMISSIONS.CREATE_PRINCIPAL]) &&
                <option value='Principal'>Principal</option>
              }
              {hasPermission(permissions, [PERMISSIONS.CREATE_SCHOOL_MODERATOR]) &&
                <option value='School Moderator'>School Moderator</option>
              }
               {hasPermission(permissions, [PERMISSIONS.CREATE_TEACHER]) &&
                <option value='Teacher'>Teacher</option>
              }
            </select>
          </div>

          {addUserValues.mode === USEREDITMODE.ADD &&
            <>
            {addUserValues.role == "Teacher" &&
              <>
              <div className="my-3 form-check">
                  <input 
                      className="form-check-input text-estorya" 
                      type="checkbox" 
                      name="isAssignClassroom" 
                      id="isAssignClassroom" 
                      checked={addUserValues.isAssignClassroom} 
                      onChange={(e) => { setAddUserValues({...addUserValues, isAssignClassroom: !addUserValues.isAssignClassroom}) }} />
                  <label className="form-check-label" htmlFor="isAssignClassroom">
                      Assign Classroom?
                  </label>
              </div>
              {addUserValues.isAssignClassroom &&
                  <div className="mb-3">
                      <label htmlFor="classroom" className="form-label fw-bold">Assign Classroom<span className="text-danger">*</span></label>
                      <Select
                          value={addUserValues.classroom}
                          onChange={(e)=> { setAddUserValues({...addUserValues, classroom: e}) }}
                          options={classroomOptions}
                          menuPlacement="top"
                          placeholder="Select  Classroom"
                          components={{ Option: CustomOption, SingleValue: CustomSingleValue  }}
                      />
                  </div>
              }
              </>
            }
            </>
          }
        </div> 
        <div className="modal-footer d-flex">
          <button type="submit" className="btn btn-estorya" disabled={!canSaveAddUser || addUserIsSubmitting}>Save User</button>
        </div>
      </form>
    </div>
  </Modal>
  );
};

export default AddUserModal;

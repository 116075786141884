import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ROLES, APIENDPOINT_INSIGHTS, axiosInstance, SCHOOLID, PERMISSIONS } from '../../config';
import { toRoman } from '../../utils/utls';
import iconDashboard from './icon-dashboard-normal.svg';
import schoolImg from './school.png';
import { NavLink } from 'react-router-dom';
import { handleError } from '../../actions/networkActions';
import { hasPermission } from '../../utils/user';

// const sampleBeginnerLevels = [
//   { grade: 2, section: 'Sunflower', level: 20 },
//   { grade: 1, section: 'Gumamela', level: 10 },
//   { grade: 4, section: 'Rizal', level: 10 },
//   { grade: 5, section: 'Mabini', level: 10 },
//   { grade: 3, section: 'Eagle', level: 7 },
//   { grade: 6, section: 'Orion', level: 3 },
// ];

function Home() {

  const { firstname, lastname } = useSelector(state => state.app.user);
  const token = useSelector(state => state.app.token);
  const role = useSelector(state => state.app.role);
  const permissions = useSelector(state => state.app.permissions);
  const [schoolSummary, setSchoolSummary] = useState({ total_teachers: null, total_students: null, total_assigned_classrooms: null })
  const [topStoryReader, setTopStoryReader] = useState({ classroom: null, teacher: null, students: null, totalStudents: null })
  const [topReader, setTopReader] = useState({ student: null, classroom: null, books: null })
  // lists
  const [topStoryReaders, setTopStoryReaders] = useState([])
  const [mostReadBooks, setMostReadBooks] = useState([])

  useEffect(() => {
    const fetchSchoolSummary = async () => {
      try {
        const responseSchoolSummary = await axiosInstance.get(`${APIENDPOINT_INSIGHTS}/school/${SCHOOLID}/statistics/summary`, { headers: { 'Authorization': `Bearer ${token}`} });
        
        setSchoolSummary(responseSchoolSummary.data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        handleError(error?.response?.status);
      }
    };

    const fetchTopStoryReader = async () => {
      try {
        const responseTopStoryReader = await axiosInstance.get(`${APIENDPOINT_INSIGHTS}/school/${SCHOOLID}/statistics/top-story-reader`, { headers: { 'Authorization': `Bearer ${token}`} });
        const data = {
          classroom: responseTopStoryReader.data.data?.rows[0].classroom_name,
          teacher: `${responseTopStoryReader.data.data?.rows[0].firstname} ${responseTopStoryReader.data.data?.rows[0].lastname}`,
          students: responseTopStoryReader.data.data?.rows[0].classroom_readers_cnt,
          totalStudents: responseTopStoryReader.data.data?.rows[0].total_students,
          grade_level_id: responseTopStoryReader.data.data?.rows[0].grade_level_id,
          grade_level: responseTopStoryReader.data.data?.rows[0].grade_level
        };
        setTopStoryReader(data);
        setTopStoryReaders(responseTopStoryReader.data.data?.rows)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchMostReadBook = async () => {
      try {
        const responseMostReadBook = await axiosInstance.get(`${APIENDPOINT_INSIGHTS}/school/${SCHOOLID}/statistics/top-book`, { headers: { 'Authorization': `Bearer ${token}`} });
        setMostReadBooks(responseMostReadBook.data.data?.rows)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchTopReader = async () => {
      try {
        const responseTopReader = await axiosInstance.get(`${APIENDPOINT_INSIGHTS}/school/${SCHOOLID}/statistics/top-reader`, { headers: { 'Authorization': `Bearer ${token}`} });
        
        const data = {
          student: `${responseTopReader.data.data?.rows[0].firstname} ${responseTopReader.data.data?.rows[0].lastname}`,
          classroom: responseTopReader.data.data?.rows[0].classroom_name,
          books: responseTopReader.data.data?.rows[0].read_cnt,
          grade_level_id: responseTopReader.data.data?.rows[0].classroom_grade_level_id
        };
        setTopReader(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSchoolSummary();
    fetchTopStoryReader();
    fetchMostReadBook();
    fetchTopReader();

  }, [token]);

  const getCurrentMonthYear = () => {
    const date = new Date();
    const options = { month: 'long', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);
    return formattedDate;
  }

  return (
    <div id="dashboard" className="animated fadeIn flex-grow-1 d-flex flex-column" style={{ minHeight: 'calc(100vh - 75px)' }}>
      <Helmet>
        <title>{`eStorya: Dashboard`}</title>
      </Helmet>

      <div className="header container-fluid align-items-center d-flex px-4" style={{ height: '140px', backgroundColor: '' }}>
        <div className="row">
          <div className="col">
            <div className="text-muted mb-3 d-flex align-items-center"><img alt="" src={iconDashboard} className="me-1" /> Dashboard</div>
            <h3 className="fw-bold text-estorya">Welcome, <span style={{ textTransform: 'capitalize' }}>{firstname} {lastname}</span>!</h3>
            {role === ROLES.SUPERADMIN &&
              <p className="mb-0 text-muted">Your teachers and students at a glance</p>
            }
            {role === ROLES.SCHOOLMODERATOR &&
              <p className="mb-0 text-muted">Your School’s performance and data at a glance</p>
            }
            {role === ROLES.TEACHER &&
              <p className="mb-0 text-muted">Your students at a glance</p>
            }
          </div>
        </div>
      </div>

      <div className="page-content flex-grow-1 container-fluid pt-4 bg-light px-4" style={{ height: 'calc(100vh - 218px)', overflowY: 'scroll' }}>
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-12">

            <div className="row mb-4">
              <div className="col">
                <div className="card border-0 shadow-sm p-2">
                  <div className="row g-0">
                    <div className="col-lg-6 col-md-12 col-sm-12 d-flex">
                    <img src={schoolImg} className="rounded-start pe-3" alt="..." />
                      <div className="my-auto">
                        <p className="text-uppercase fw-bold mb-4 mt-2">Here's what's in your school.</p>
                        <h4 className="card-title fw-bold text-estorya">{schoolSummary?.school?.name}</h4>
                        <p className="text-uppercase mb-0 small text-muted">School assigned to you</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                      <div className="mt-5 mb-3">
                        <h5 className="text-estorya fw-bold fs-2">{schoolSummary.total_teachers ? schoolSummary.total_teachers : '-'}</h5>
                        <p className="text-uppercase small text-muted mb-0">TOTAL TEACHERS IN YOUR <br/> SCHOOL</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                      <div className="mt-5 mb-3">
                        <h5 className="text-estorya fw-bold fs-2">{schoolSummary.total_assigned_classrooms ? schoolSummary.total_assigned_classrooms : '-'}</h5>
                        <p className="text-uppercase small text-muted mb-0">TOTAL CLASSROOMS WITH <br/> ASSIGN TEACHERS AND STUDENT</p>
                      </div>
                    </div>
                    <div className="col-lg-2 col-md-4 col-sm-4 d-flex">
                      <div className="mt-5 mb-3">
                        <h5 className="text-estorya fw-bold fs-2">{schoolSummary.total_students ? schoolSummary.total_students : '-'}</h5>
                        <p className="text-uppercase small text-muted mb-0">TOTAL STUDENTS IN YOUR<br/> SCHOOL</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>            

            <div className="row mb-4">
              <div className='mb-2 fst-italic text-muted'>As of <strong className='text-estorya'>{getCurrentMonthYear()}</strong>, here are the classrooms with the most numbered story-level readers, this month’s most-read books, and this month’s best-performing teacher and top reader.</div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="card border-0 shadow-sm flex-grow-1">
                  <div className="card-body p-4">
                    <div className="row mb-4">
                      <div className="col d-flex">
                        <p className="text-uppercase text-muted fw-bold my-auto">MOST NUMBERED STORY LEVEL READERS</p>
                      </div>
                    </div>
                    {topStoryReaders.map((reader, key)=>{
                      return(
                        <div className='d-flex mb-3' key={key}>
                          <p className='mb-0 fs-6'>{key+1}</p>
                          <div className='ps-2'>
                            <h5 className="fs-4 text-estorya fw-bold mb-0">{`Grade ${toRoman(reader?.grade_level_id)} - ${reader.classroom_name}`}</h5>
                            <p className='mb-0'>{reader.classroom_readers_cnt} <span className='text-secondary'>Story Level Reader</span></p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="card border-0 shadow-sm flex-grow-1">
                  <div className="card-body p-4">
                    <div className="row mb-4">
                      <div className="col d-flex">
                        <p className="text-uppercase text-muted fw-bold my-auto">MOST READ BOOKS</p>
                      </div>
                    </div>
                    {mostReadBooks.map((book, key)=>{
                      return(
                        <div className='d-flex mb-3' key={key}>
                          <p className='mb-0 fs-6'>{key+1}</p>
                          <div className='ps-2'>
                            <h5 className="fs-4 text-estorya fw-bold mb-0">{book?.title}</h5>
                            <p className='mb-0'>{book?.read_cnt}/{book?.students_count} <span className='text-secondary'>read this book</span></p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="card border-0 shadow-sm flex-grow-1 mb-4">
                  <div className="card-body p-4">
                    <div className="row mb-4">
                      <div className="col d-flex">
                        <p className="text-uppercase text-muted fw-bold my-auto">BEST PERFORMING TEACHER</p>
                      </div>
                    </div>
                    <div>
                      <h5 className="fs-4 text-estorya fw-bold mb-1">{topStoryReader.teacher ? topStoryReader.teacher : '-'}</h5>
                      <p className='text-uppercase text-secondary'>{topStoryReader.classroom ? `Grade ${toRoman(topStoryReader.grade_level_id)} - ${topStoryReader.classroom}` : '-'}</p>
                      <h5 className="fs-5 mb-1 text-muted"><span className='text-estorya'>{topStoryReader.students}</span> out of {topStoryReader.totalStudents} students</h5>
                      <p className='text-uppercase text-secondary'>STORY LEVEL READER IN THIER CLASSROOM</p>
                    </div>
                  </div>
                </div>
                <div className="card border-0 shadow-sm flex-grow-1">
                  <div className="card-body p-4">
                    <div className="row mb-4">
                      <div className="col d-flex">
                        <p className="text-uppercase text-muted fw-bold my-auto">TOP READER</p>
                      </div>
                    </div>
                    <div>
                      <h5 className="fs-4 text-estorya fw-bold mb-1">{topReader.student ? topReader.student : '-'}</h5>
                      <p className='text-uppercase text-secondary'>{topReader.classroom ? `Grade ${toRoman(topReader.grade_level_id)} - ${topReader.classroom}` : '-'}</p>
                      <h5 className="fs-4 text-estorya fw-bold mb-1">{topReader.books ? topReader.books : '-'}</h5>
                      <p className='text-uppercase text-secondary'>TOTAL BOOKS READ</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            {/* <div className="row mb-4">
              <div className="col">
                <div className="card border-0 shadow-sm">
                  <div className="card-body">

                    <div className="row mb-4">
                      <div className="col d-flex">
                        <p className="text-uppercase text-muted fw-bold my-auto">Students Reading Level Count</p>
                      </div>
                      <div className="col-auto d-flex align-items-center">
                        <label htmlFor="gradeLevels" className="form-label mb-0" style={{ width: 200 }}>Show entries</label>
                        <select id="gradeLevels" className="form-select">
                          <option selected>All</option>
                          <option value="1">Grade 1</option>
                          <option value="2">Grade 2</option>
                          <option value="3">Grade 3</option>
                          <option value="3">Grade 4</option>
                          <option value="3">Grade 5</option>
                          <option value="3">Grade 6</option>
                        </select>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <table className="table">
                          <thead>
                            <tr className="table-light">
                              <th><span className="me-2">Beginner Level</span> <FontAwesomeIcon icon="fa-solid fa-sort" size="xs" /></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {sampleBeginnerLevels.map(level => (
                              <tr key={`beginnerLevel-${level.grade}-${level.section}`}>
                                <td>Grade {toRoman(level.grade)} - {level.section}</td>
                                <td>{level.level}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="col">
                        <table className="table">
                          <thead>
                            <tr className="table-light">
                              <th><span className="me-2">Letters Level</span> <FontAwesomeIcon icon="fa-solid fa-sort" size="xs" /></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {sampleBeginnerLevels.map(level => (
                              <tr key={`beginnerLevel-${level.grade}-${level.section}`}>
                                <td>Grade {toRoman(level.grade)} - {level.section}</td>
                                <td>{level.level}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="col">
                        <table className="table">
                          <thead>
                            <tr className="table-light">
                              <th><span className="me-2">Word Level</span> <FontAwesomeIcon icon="fa-solid fa-sort" size="xs" /></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {sampleBeginnerLevels.map(level => (
                              <tr key={`beginnerLevel-${level.grade}-${level.section}`}>
                                <td>Grade {toRoman(level.grade)} - {level.section}</td>
                                <td>{level.level}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="col">
                        <table className="table">
                          <thead>
                            <tr className="table-light">
                              <th><span className="me-2">Paragraph Level</span> <FontAwesomeIcon icon="fa-solid fa-sort" size="xs" /></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {sampleBeginnerLevels.map(level => (
                              <tr key={`beginnerLevel-${level.grade}-${level.section}`}>
                                <td>Grade {toRoman(level.grade)} - {level.section}</td>
                                <td>{level.level}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="col">
                        <table className="table">
                          <thead>
                            <tr className="table-light">
                              <th><span className="me-2">Story Level</span> <FontAwesomeIcon icon="fa-solid fa-sort" size="xs" /></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {sampleBeginnerLevels.map(level => (
                              <tr key={`beginnerLevel-${level.grade}-${level.section}`}>
                                <td>Grade {toRoman(level.grade)} - {level.section}</td>
                                <td>{level.level}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div> */}

          </div>

          {/* Right navbar */}
          <div className="col-lg-2 col-md-2 col-sm-12">
            {hasPermission(permissions, [PERMISSIONS.CREATE_TEACHER]) &&
              <div className="mb-3">
                <NavLink to="/admin/accesscontrol/users" className="btn btn-estoryalight w-100">Create Account for Teachers</NavLink>
              </div>
            }
            <div className="mb-3">
              <h5 className="fw-bold">Quick Tasks</h5>
              <ul className="list-group list-group-flush">
                {hasPermission(permissions, [PERMISSIONS.VIEW_CLASSROOM]) &&
                  <li className="list-group-item bg-transparent p-0 border-0"><NavLink to="/classroommanagement/classrooms" className="btn text-estorya text-start">Manage Classrooms</NavLink></li>
                }
                {hasPermission(permissions, [PERMISSIONS.VIEW_TEACHER]) &&
                  <li className="list-group-item bg-transparent p-0 border-0"><NavLink to="/classroommanagement/teachers" className="btn text-estorya text-start">Manage Teachers</NavLink></li>
                }
                {hasPermission(permissions, [PERMISSIONS.VIEW_STUDENT]) &&
                  <li className="list-group-item bg-transparent p-0 border-0"><NavLink to="/classroommanagement/students" className="btn text-estorya text-start">Manage Students</NavLink></li>
                }
              </ul>
            </div>
            {hasPermission(permissions, [PERMISSIONS.CREATE_CLASSROOM, PERMISSIONS.CREATE_STUDENT]) &&
              <div className="mb-3">
                <h5 className="fw-bold">Add new Components</h5>
                <ul className="list-group list-group-flush">
                  {hasPermission(permissions, [PERMISSIONS.CREATE_CLASSROOM]) &&
                    <li className="list-group-item bg-transparent p-0 border-0"><NavLink to="/classroommanagement/classrooms" state={{ classroomModalIsOpen: true }} className="btn text-estorya text-start"><FontAwesomeIcon icon="fa-solid fa-plus" size="xs" className="me-2" /> Add Classroom</NavLink></li>
                  }
                  {hasPermission(permissions, [ PERMISSIONS.CREATE_STUDENT]) &&
                    <li className="list-group-item bg-transparent p-0 border-0"><NavLink to="/classroommanagement/students" state={{ studentModalIsOpen: true }} className="btn text-estorya text-start"><FontAwesomeIcon icon="fa-solid fa-plus" size="xs" className="me-2" /> Add Student</NavLink></li>
                  }
                </ul>
              </div>
            }
          </div>
        </div>

      </div>
    </div>
  );
};

export default Home;
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lottie from 'react-lottie-player';
import { DebounceInput } from 'react-debounce-input';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import Avatar from 'react-avatar';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu, 
  DropdownItem, 
  Modal
} from 'reactstrap';
// import Select, { components } from 'react-select';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import './Users.css';
import iconUser from './icon-identity-mgt.svg';

import iconActions from './icon-actions.svg';
import empty from '../../../../assets/img/empty.png';
import { APIENDPOINT, /* APIENDPOINT_AUTH, */ axiosInstance, PERMISSIONS, SCHOOLID } from '../../../../config';
import loadingAnimation from '../../../../assets/lottie/loadingAnimation.json';
// import iconSite from './icon-site-normal-black.svg';
// import iconRbac from './icon-rbac.svg';
import iconExit from './icon-exit.svg';
import DataFilters from '../../../../utils/DataFilters';

import { faker } from '@faker-js/faker';
import AddUserDropdownMenu from './AddUserDropdownMenu';
import AddUserModal from './AddUserModal';
import { toRoman } from '../../../../utils/utls';
import AddClassroomModal from './AddClassroomModal';
import AddUserBulkModal from './AddUserBulkModal';
import { hasPermission } from '../../../../utils/user';
import AccessControlLayout from '../../../Layouts/AccessControlLayout';

const USEREDITMODE = {
  ADD: 'add',
  EDIT: 'edit',
  TEMPORARY: 'temporary'
};

const ADDUSERMODE = {
  ADDUSER: 'add-user',
  ADDUSERANDASSIGN: 'add-user-and-assign',
  BULKADDUSERANDASSiGN: 'bulk-add-user-and-assign'
}


const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};


const RESETUSERACCESSMODE = {
  RESENDACTIVATIONEMAIL: 'Resend Activation Email',
  SENDPASSWORDRESETINSTRUCTIONSTHROUGHEMAIL: 'Send password reset instructions through email',
  SETATEMPORARYPASSWORD: 'Set a Temporary Password'
};


function UserListItem({ permissions, user, editUser, resetUserAccess, showDeleteConfirmation }) {

  const renderEditDropdownItem = (userData) => {
    return <DropdownItem className="px-3 py-1 btn btn-sm btn-block text-start border-0 btn-outline-dark rounded-0 w-100" onClick={() => { editUser(userData); }}>Edit User</DropdownItem>
  }

  const renderDeleteDropdownItem = (userData) => {
    return <DropdownItem className="px-3 py-1 btn btn-sm btn-block text-start border-0 btn-outline-danger rounded-0 w-100" onClick={() => { showDeleteConfirmation(userData); }}>Delete User</DropdownItem>
  }
  return (
    <tr className=''>
      <td className="text-end">
        <Avatar name={`${user.firstname} ${user.lastname}`} size="40" round={true} />
      </td>
      <td>
        <p className="fw-bold fs-6 mb-0">{user.firstname} {user.lastname} {user.archived && <span className="ms-2 text-muted fw-bold">(Archived)</span>}</p>
        <p className="text-muted mb-0"><em>{user.email}</em></p>
      </td>
      <td className="align-middle">
        <h6 className="d-inline me-1 mb-1">
          <span className={`badge user-role-badge py-1 px-2 role-${user.role}`}>
            <span className="align-middle text-uppercase">{user.role}</span>
          </span>
        </h6>
        {/* {user.roles.map((role, roleIdx) => {
          return (
            <h6 key={`user-${user.id}-role-${roleIdx}`} className="d-inline me-1 mb-1">
              <span className="badge user-role-badge py-1 px-2">
                <span className="align-middle text-uppercase">{role.name}</span>
              </span>
            </h6>
          )
        })} */}
      </td>
      {/* <td>
        <p className="mb-0">{user.date_joined ? moment(user.date_joined).format('D MMM YYYY, hh:mm A') : '-'}</p>
        {user.date_joined &&
          <p className="mb-0 text-muted"><em>{moment(user.date_joined).fromNow()}</em></p>
        }
      </td> */}
      <td className="align-middle">
           {user?.teacher_class_rooms?.length > 0 &&
          <p className="mb-0">{user?.teacher_class_rooms[0]?.description}</p>
        }

        {user?.teacher_class_rooms?.length <= 0 &&
          <p className="mb-0">Not yet Assigned</p>
        }
        
      </td>
      <td className="align-middle">
        <p className="mb-0">{user.created_at ? moment(user.created_at).format('D MMM YYYY, hh:mm A') : '-'}</p>
      </td>
      <td>
        <UncontrolledDropdown className="position-static">
          <DropdownToggle color="outline-light">
            <img alt="" src={iconActions} style={{ pointerEvents: 'none' }} />
          </DropdownToggle>
          <DropdownMenu className="py-1" style={{ maxWidth: '80px' }}>
       
            {(user.role == 'Teacher' && hasPermission(permissions, [PERMISSIONS.UPDATE_TEACHER]))&&
              renderEditDropdownItem(user)
            }
            {(user.role == 'School Moderator' && hasPermission(permissions, [PERMISSIONS.UPDATE_SCHOOL_MODERATOR]))&&
              renderEditDropdownItem(user)
            }
            {(user.role == 'Principal' && hasPermission(permissions, [PERMISSIONS.UPDATE_PRINCIPAL]))&&
              renderEditDropdownItem(user)
            }
            
            {/* <button className="px-3 py-1 btn btn-sm btn-block text-start border-0 btn-outline-dark rounded-0 w-100 disabled" disabled onClick={() => { resetUserAccess(user); }}>Reset Access</button> */}
            {/* <hr className="my-1 border-secondary"/> */}
            {(user.role == 'Teacher' && hasPermission(permissions, [PERMISSIONS.DELETE_TEACHER]))&&
              renderDeleteDropdownItem(user)
            }
            {(user.role == 'School Moderator' && hasPermission(permissions, [PERMISSIONS.DELETE_SCHOOL_MODERATOR]))&&
              renderDeleteDropdownItem(user)
            }
            {(user.role == 'Principal' && hasPermission(permissions, [PERMISSIONS.DELETE_PRINCIPAL]))&&
              renderDeleteDropdownItem(user)
            }
            
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr>
  )
};

function Users() {

  const token = useSelector(state => state.app.token);
  const permissions = useSelector(state => state.app.permissions);
  // const [teachers, ] = useState(sampleTeachers.map(teacher => ({ ...teacher, checked: false })));
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [usersPerPage, setUsersPerPage] = useState(20);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('firstname');
  const [sortDirection, setSortDirection] = useState('asc');
  const [filters, setFilters] = useState({
    rolesFilters: [],
  });
  const [rolesFilters, setRolesFilters] = useState([]);

  const [classroomOptions, setClassroomOptions] = useState([])
  // 
  const defaultEmptyClassroom = {
    mode: 'add',
    id: null,
    name: '',
    currentName: '',
    grade_level_id: 1,
    teacher: null
  };
  const [classroomModalIsOpen, setClassroomModalIsOpen] = useState(false);
  const [classroomModalIsSaving, setClassroomModalIsSaving] = useState(false);
  const [classroomModalValues, setClassroomModalValues] = useState(defaultEmptyClassroom);
  const [classroomsFilters, setClassroomsFilters] = useState([]);

  const isClassroomNameAlreadyExists = useMemo(() => {
    if(classroomModalValues.name.length > 0) {
      let classroomNames = classroomsFilters.map(classroom => classroom.nameRaw);
      return classroomNames.indexOf(classroomModalValues.name) !== -1;
    }
    return false;
  }, [classroomModalValues.name, classroomModalValues.currentName, classroomsFilters]);
  
  const canCreateClassroom = (classroomModalValues.grade_level_id && classroomModalValues.name && !isClassroomNameAlreadyExists);

  const addClassroom = async(e) => {
    e.preventDefault();
    setClassroomModalIsSaving(true);
    try {
      const formData = new FormData();
      formData.append("name", classroomModalValues.name);
      formData.append("description", `Grade ${toRoman(classroomModalValues.grade_level_id)} - ${classroomModalValues.name}`);
      formData.append("teacher_id", classroomModalValues.teacher?.id ? classroomModalValues.teacher.id : null);
      formData.append('teacher_ids[]', classroomModalValues.teacher.id);
      formData.append("grade_level_id", classroomModalValues.grade_level_id);
      formData.append("school_id", SCHOOLID);

      await axiosInstance.post(
        `${APIENDPOINT}/classrooms`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );


      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-1 text-estorya">Successfully Created User And Assign</h6>
          <p className="mb-0">The Activation Email has been sent to {classroomModalValues.teacher.email}</p>
        </div>
      </div>)

    } catch(e) {
      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="times-circle" size="2x" className="me-3 text-danger" />
        </div>
        <div>
          <h6 className="fw-bold mb-0 text-danger">There's an error on creating your classroom</h6>
          <p className="mb-0">Please try again later.</p>
        </div>
      </div>);
    }

    setClassroomModalIsSaving(false);
    setClassroomModalIsOpen(false);
    setClassroomModalValues(defaultEmptyClassroom);
    fetchUsers();
  };

  useEffect(()=>{
 
    let newRolesFilter = [];
    if(hasPermission(permissions, [PERMISSIONS.CREATE_PRINCIPAL])) {
      newRolesFilter.push({id: 'Principal', name: 'Principal'})
    }

    if(hasPermission(permissions, [PERMISSIONS.CREATE_SCHOOL_MODERATOR])) {
      newRolesFilter.push({id: 'School Moderator', name: 'School Moderator'})
    }

    if(hasPermission(permissions, [PERMISSIONS.CREATE_TEACHER])) {
      newRolesFilter.push({id: 'Teacher', name: 'Teacher'})
    }

    setRolesFilters(newRolesFilter)

  },[permissions])

  useEffect(()=>{
    const fetchClassrooms = () =>{
        axiosInstance.get(`${APIENDPOINT}/classrooms?page=1&limit=-1&direction=asc&sortBy=grade_level_id&isUnassigned=1`, { headers: { 'Authorization': `Bearer ${token}`} }).then((res)=>{
            if(res?.data?.data?.data){
                let classroomOptionsTemp = res.data.data.data.map(classroom => ({ ...classroom, value: classroom.id, label:`Grade ${classroom.grade_level_id ? toRoman(classroom.grade_level_id) : '-'} - ${classroom.name}`, nameRaw: classroom.name, name: `Grade ${classroom.grade_level_id ? toRoman(classroom.grade_level_id) : '-'} - ${classroom.name}` })).sort((classroomA, classroomB) => { return classroomA.name.localeCompare(classroomB.name); })
                classroomOptionsTemp = [{value:'default', label:'Create a new Classroom'}, ...classroomOptionsTemp]
                setClassroomOptions(classroomOptionsTemp)
            }
        })
    }
    
    const fetchClassroomsForFilter = async () => {
      try {
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms?page=${1}&limit=${100}`, { headers: { 'Authorization': `Bearer ${token}`} });
        setClassroomsFilters(response.data.data.data.map(classroom => ({ ...classroom, nameRaw: classroom.name, name: `Grade ${classroom.grade_level_id ? toRoman(classroom.grade_level_id) : '-'} - ${classroom.name}` })).sort((classroomA, classroomB) => { return classroomA.name.localeCompare(classroomB.name); }));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchClassrooms();
    fetchClassroomsForFilter()
},[token])

  const fetchUsers = useCallback(async () => {
    try {
      setLoadingUsers(true);

      let rolesIdFilters = ''

  
      if(filters.rolesFilters.length > 0) {
        rolesIdFilters = filters.rolesFilters.map(obj => obj.id).join(',');
      } else {
        rolesIdFilters = rolesFilters.map(obj => obj.id).join(',');
      }

      if(rolesIdFilters.length > 0) {
        const response = await axiosInstance.get(`${APIENDPOINT}/users?page=${currentPage}&limit=${usersPerPage}&sortBy=${sortBy}&direction=${sortDirection}${rolesIdFilters.length > 0 ? `&roleIds=${rolesIdFilters}` : ''}${search.length > 0 ? `&key=${search}` : ''}`, { headers: { 'Authorization': `Bearer ${token}`} });
        setUsers(response.data.data.data);
        setTotal(response.data.data.total);
      }
    
      setLoadingUsers(false);
    
    } catch (error) {
      setLoadingUsers(false);
      console.error('Error fetching data:', error);
    }
  }, [currentPage, usersPerPage, sortBy, sortDirection, search, filters.rolesFilters, rolesFilters, token]);


  useEffect(() => {
    if(currentPage && usersPerPage && token) {
      fetchUsers();
    }
  }, [currentPage, usersPerPage, token, fetchUsers]);

  const pageCount = Math.ceil(total / usersPerPage);

  const handlePageClick = (i) => {
    setCurrentPage(i.selected + 1);
  };

  const updateSort = (attribute) => {
    if(sortBy === attribute) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(attribute);
      setSortDirection('asc');
    }
  };

  const tableHeaders = <thead>
    <tr className="table-light">
      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0', width: '70px' }}>
      </th>
      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
        <span className="me-2">Name</span>
        <button type="button" className="border-0 p-0 m-0 bg-transparent text-muted" onClick={() => { updateSort('firstname'); }}><FontAwesomeIcon icon={sortBy === 'firstname' ? sortDirection === 'asc' ? 'sort-up' : 'sort-down' : 'sort'} size="xs" /></button>
      </th>
      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
        <span className="me-2">Roles</span>
        <DataFilters
          name="Roles"
          filters={rolesFilters}
          selectedItems={filters.rolesFilters}
          setFilters={(filterData) => {
            setCurrentPage(1)
            setFilters({ ...filters, rolesFilters: filterData });
          }}
          searchable={true}
          loading={false}
        />
        {/* <button type="button" className="border-0 p-0 m-0 bg-transparent text-muted" onClick={() => { updateSort('role'); }}><FontAwesomeIcon icon={sortBy === 'role' ? sortDirection === 'asc' ? 'sort-up' : 'sort-down' : 'sort'} size="xs" /></button> */}
      </th>
      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
        <span className="me-2">Assign Classroom</span>
        <DataFilters
          name="Classroom"
          filters={[]}
          selectedItems={[]}
          setFilters={(filterData) => {
            // setFilters({ ...filters, rolesFilters: filterData });
          }}
          searchable={true}
          loading={false}
        />
        {/* <button type="button" className="border-0 p-0 m-0 bg-transparent text-muted" onClick={() => { updateSort('role'); }}><FontAwesomeIcon icon={sortBy === 'role' ? sortDirection === 'asc' ? 'sort-up' : 'sort-down' : 'sort'} size="xs" /></button> */}
      </th>
      {/* <th className="border-light position-sticky" style={{ top: '0' }}>
        <span className="me-2">Date Joined</span>
        <FontAwesomeIcon icon="sort" />
      </th> */}
      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
        <span className="me-2">Account Created</span>
        <button type="button" className="border-0 p-0 m-0 bg-transparent text-muted" onClick={() => { updateSort('created_at'); }}><FontAwesomeIcon icon={sortBy === 'created_at' ? sortDirection === 'asc' ? 'sort-up' : 'sort-down' : 'sort'} size="xs" /></button>
      </th>
      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0', width: '70px', zIndex: 1 }}>Actions</th>
    </tr>
  </thead>;

  const [addUserModalIsOpen, setAddUserModalIsOpen] = useState(false);
  const [addUserBulkModalIsOpen, setAddUserBulkModalIsOpen] = useState(false)
  const [addUsersIsSubmitting, setaddUsersIsSubmitting] = useState(false)
  const [file, setFile] = useState(null);
  const [progressStatus, setProgressStatus] = useState(50)

  const canSaveAddUsers = (file);

  const defaultEmptyUser = {
    mode: USEREDITMODE.ADD,
    firstname: '',
    lastname: '',
    email: '',
    role: 'Teacher',
    sites: [],
    setATemporaryPassword: false,
    isAssignClassroom: false,
    classroom:null
  };

  const [addUserValues, setAddUserValues] = useState(defaultEmptyUser);
  const [addUserIsSubmitting, setAddUserIsSubmitting] = useState(false);

  const canSaveAddUser = (addUserValues.firstname && addUserValues.lastname && addUserValues.email && validateEmail(addUserValues.email));

  const addUser = async(e) => {
    e.preventDefault();
    setAddUserIsSubmitting(true);

    if(addUserValues.mode === USEREDITMODE.ADD) {
      if(!addUserValues.setATemporaryPassword) {

        try {
          const formData = new FormData();
          formData.append("firstname", addUserValues.firstname);
          formData.append("lastname", addUserValues.lastname);
          formData.append("middlename", addUserValues.middlename);
          formData.append("role", addUserValues.role);
          formData.append("email", addUserValues.email);
          
    
          let addUserResp = await axiosInstance({
            url:`${APIENDPOINT}/users`,
            method: 'POST',
            data: formData,
            headers: { 'Authorization': `Bearer ${token}`}
          });

          if(addUserValues.classroom && addUserValues.classroom.value != "default" && addUserValues.isAssignClassroom) {
            const formData = new FormData();
            // formData.append("teacher_id", addUserResp.data.data.user.id);
            formData.append("teacher_id", null);
            formData.append("name", addUserValues.classroom.nameRaw);
            formData.append("description", addUserValues.classroom.description);
            formData.append("grade_level_id", addUserValues.classroom.grade_level_id);
            formData.append("school_id", addUserValues.classroom.school_id);

            for (let i = 0; i < addUserValues.classroom.teachers.length; i++) {
              formData.append('teacher_ids[]', addUserValues.classroom.teachers[i].id);
            }

            formData.append('teacher_ids[]', addUserResp.data.data.user.id);
            const queryString = new URLSearchParams(formData).toString();

            await axiosInstance.put(
              `${APIENDPOINT}/classrooms/${addUserValues.classroom.value}?${queryString}`,
              formData,
              {
                headers: {
                  'Authorization': `Bearer ${token}`
                }
              }
            );
            toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
              <div>
                <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
              </div>
              <div>
                <h6 className="fw-bold mb-1 text-estorya">Successfully Created User And Assign</h6>
                <p className="mb-0">The Activation Email has been sent to {addUserValues.email}</p>
              </div>
            </div>);

            setClassroomModalValues(defaultEmptyClassroom)
          } else if(addUserValues.isAssignClassroom) {
            let teacher = addUserResp.data.data.user;
            teacher.value = teacher.id;
            teacher.label = `${teacher.firstname} ${teacher.lastname}`
            setClassroomModalValues({...classroomModalValues, teacher: teacher})
            setClassroomModalIsOpen(true)
          } else {
            toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
              <div>
                <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
              </div>
              <div>
                <h6 className="fw-bold mb-1 text-estorya">Successfully Created User</h6>
                <p className="mb-0">The Activation Email has been sent to {addUserValues.email}</p>
              </div>
            </div>);
          }

        } catch (e) {
          console.log(e);
        }
      } else {
        toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
          <div>
            <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
          </div>
          <div>
            <h6 className="fw-bold mb-1 text-estorya">Successfully Created User</h6>
          </div>
        </div>);
      }
    } else {

      try {
        const formData = new FormData();
        formData.append("firstname", addUserValues.firstname);
        formData.append("lastname", addUserValues.lastname);
        formData.append("middlename", addUserValues.middlename);
        formData.append("role", addUserValues.role);
        formData.append("email", addUserValues.email);

        const queryString = new URLSearchParams(formData).toString();
  
        await axiosInstance({
          url:`${APIENDPOINT}/users/${addUserValues.id}?${queryString}`,
          method: 'PUT',
          data: formData,
          headers: { 'Authorization': `Bearer ${token}`}
        });

        toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
          <div>
            <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
          </div>
          <div>
            <h6 className="fw-bold mb-1 text-estorya">Successfully Updated User</h6>
          </div>
        </div>);
      } catch (e) {
        console.log(e);
      }
    }

    fetchUsers();
    setAddUserModalIsOpen(false);
    setAddUserIsSubmitting(false);
    setAddUserValues(defaultEmptyUser);
  };

  const addUsers = async(e) => {
   
  };


  const [temporaryPasswordModalIsOpen, setTemporaryPasswordModalIsOpen] = useState(false);
  const temporaryPassword = useMemo(() => {
    return faker.string.alphanumeric({ length: 12 });
  }, [])
  const doneSetTemporaryPassword = () => {
    setTemporaryPasswordModalIsOpen(false);
  };
  const copyTemporaryPassword = () => {
    navigator.clipboard.writeText(temporaryPassword);
    toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
        <p className="mb-0 fw-bold">Temporary password copied</p>
        </div>
      </div>);
  };

  const editUser = (user) => {
    setAddUserValues({...user, mode: USEREDITMODE.EDIT});
    setAddUserModalIsOpen(true);
  };

  const [resetUserAccessModalIsOpen, setResetUserAccessModalIsOpen] = useState(false);
  const [resetUserAccessMode, setResetUserAccessMode] = useState(RESETUSERACCESSMODE.RESENDACTIVATIONEMAIL);
  const resetUserAccess = (user) => {
    setAddUserValues({...user, mode: USEREDITMODE.TEMPORARY});
    if(user.date_joined) {
      setResetUserAccessMode(RESETUSERACCESSMODE.SENDPASSWORDRESETINSTRUCTIONSTHROUGHEMAIL);
    } else {
      setResetUserAccessMode(RESETUSERACCESSMODE.RESENDACTIVATIONEMAIL)
    }
    setResetUserAccessModalIsOpen(true);
  }

  const sendResetUserAccess = () => {
    setResetUserAccessModalIsOpen(false);
    switch(resetUserAccessMode) {
      case RESETUSERACCESSMODE.RESENDACTIVATIONEMAIL:
        toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
            <div>
              <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
            </div>
            <div>
              <p className="mb-0 fw-bold">The activation email has been sent to {addUserValues.email}</p>
            </div>
          </div>);
        break;
      case RESETUSERACCESSMODE.SENDPASSWORDRESETINSTRUCTIONSTHROUGHEMAIL:
        toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
            <div>
              <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
            </div>
            <div>
              <p className="mb-0 fw-bold">Password reset instructions have been sent to {addUserValues.email}</p>
            </div>
          </div>);
        break;
      case RESETUSERACCESSMODE.SETATEMPORARYPASSWORD:
        setTemporaryPasswordModalIsOpen(true);
        break;
      default:
        break;
    }
  };

  
  const [toDeleteUser, setToDeleteUser] = useState(null);
  const [toDeleteUserModalIsOpen, setToDeleteUserModalIsOpen] = useState(false);

  const showDeleteConfirmation = (user) => {
    setToDeleteUser(user);
    setToDeleteUserModalIsOpen(true);
  };

  const deleteUser = async() => {
    try {
      const formData = new FormData();

      await axiosInstance({
        url:`${APIENDPOINT}/users/${toDeleteUser.id}`,
        method: 'DELETE',
        data: formData,
        headers: { 'Authorization': `Bearer ${token}`}
      });

      toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
        <div>
          <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
        </div>
        <div>
          <h6 className="fw-bold mb-1 text-estorya">Successfully Deleted User</h6>
        </div>
      </div>);
    } catch (e) {
      console.log(e);
    }

    fetchUsers();
  }

  return (
    <AccessControlLayout>
    <div id="users" className="animated fadeIn flex-grow-1 d-flex flex-column" style={{ minHeight: 'calc(100vh - 75px)' }}>
      <Helmet>
        <title>{`eStorya: Administration - User Management`}</title>
      </Helmet>

      <div className="px-4">
        <div className="card rounded-1">
          <div className="card-header bg-white d-flex justify-content-between align-items-center py-3">
            <div className="d-flex align-items-start">
              <img alt="" src={iconUser} className="me-3" />
              <div>
                <h5 className="fw-bold mb-0">User Management</h5>
                <p className="mb-0 text-muted">Add or remove users from your network</p>
              </div>
            </div>
            <div>
              {/* <button type="button" className="btn btn-estorya" onClick={() => { setAddUserValues(defaultEmptyUser); setAddUserModalIsOpen(true); }}>
                <img alt="" src={iconAddUser} className="me-2" />
                Add User
              </button> */}
              <AddUserDropdownMenu
                permissions={permissions}
                ADDUSERMODE={ADDUSERMODE}
                openAddUserModal = {(val) => {
                 
                  if(val == ADDUSERMODE.ADDUSER) {
                    setAddUserValues(defaultEmptyUser); setAddUserModalIsOpen(true); 
                  } else if(val == ADDUSERMODE.ADDUSERANDASSIGN) {
                    let newDefaultEmptyUser = defaultEmptyUser;
                    newDefaultEmptyUser.isAssignClassroom = true
                    setAddUserValues(newDefaultEmptyUser); 
                    setAddUserModalIsOpen(true); 
                  } else if(ADDUSERMODE.BULKADDUSERANDASSiGN) {
                    setAddUserBulkModalIsOpen(true)
                  }
                }}
              />
            </div>
          </div>
          <div className="card-body">
            
            <div className="container-fluid px-0">
              <div className="mb-0">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto">
                    <h5 className="fw-bold mb-0">Users <span className="text-muted fw-normal ms-3">{total} result/s</span></h5>
                  </div>
                </div>
              </div>

              <div className="mb-0">
                <div className="row align-items-center justify-content-between" style={{ minHeight: '60px' }}>

                  <div className="col-auto">
                    <div className="input-group">
                      <label className="input-group-text bg-transparent border-right-0" htmlFor="searchUser"><FontAwesomeIcon icon="search" /></label>
                      {/* <input type="text" className="form-control border-left-0" id="searchTeacher" placeholder="Search for Teacher" style={{ width: '20rem' }} value={search} onChange={(e) => { setSearch(e.target.value) }} /> */}
                      <DebounceInput className="form-control border-left-0" id="searchUser" placeholder="Search for user name" style={{ width: '20rem' }} value={search} debounceTimeout={1000} onChange={(e) => { setSearch(e.target.value); setCurrentPage(1) }} />
                    </div>
                  </div>

                  <div className="col-auto">
                    <div className="row">

                      {/* <div className="col-auto">
                        <button className="disabled btn btn-light" disabled>
                          <FontAwesomeIcon icon="filter" className="text-muted" />
                        </button>
                      </div> */}

                      {/* <div className="col-auto p-0 my-2 mx-2" style={{ borderLeft: '1px solid #CCCCCC' }}>
                      </div> */}

                      <div className="col-auto">
                        <div className="input-group">
                          <label htmlFor="page" className="form-label input-group-text mb-0 border-0 ps-0 bg-transparent">Rows:</label>
                          <select id="page" className="form-select rounded" value={usersPerPage} onChange={(e) => { setCurrentPage(1); setUsersPerPage(parseInt(e.target.value, 10)) }}>
                            <option value={1}>1</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                          </select>
                        </div>
                      </div>

                      {/* Pagination */}
                      <nav className="col-auto d-flex align-items-center">
                        <ReactPaginate
                          previousLabel={<FontAwesomeIcon icon="chevron-left" />}
                          nextLabel={<FontAwesomeIcon icon="chevron-right" />}
                          pageClassName="page-item"
                          previousLinkClassName="page-link rounded text-estorya me-1"
                          nextLinkClassName="page-link rounded text-estorya ms-1"
                          pageLinkClassName="page-link border-estorya text-estorya rounded mx-1"
                          breakLinkClassName="page-link rounded text-estorya mx-1"
                          breakLabel="..."
                          breakClassName="break-me"
                          pageCount={pageCount}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={1}
                          onPageChange={handlePageClick}
                          containerClassName="pagination mb-0"
                          activeLinkClassName="bg-estorya text-white"
                          disabledClassName="disabled"
                          activeClassName="active"
                          forcePage={currentPage - 1}
                        />
                      </nav>
                    </div>
                  </div>

                </div>
              </div>

              {loadingUsers ?
                <div className="flex-grow-1 d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 295px)', overflowY: 'scroll', overflowX: 'hidden' }}>
                  <div className="text-center">
                    <Lottie
                      className="mt-2 mb-4"
                      loop
                      animationData={loadingAnimation}
                      speed={0.75}
                      play
                      style={{ width: 300, height: 300, margin: "0 auto" }}
                    />          
                  </div>
                </div>
                :
                <>
                  {users.length > 0 &&
                    <div className="flex-grow-1" style={{ height: 'calc(100vh - 295px)', overflowY: 'scroll', overflowX: 'hidden' }}>
                      <table className="table mb-0">
                        {tableHeaders}
                        <tbody className="bg-white">
                          {users.map((user, userIdx) => {
                            return <UserListItem permissions={permissions} key={`user-${user.id}-${userIdx}`} user={user} editUser={editUser} resetUserAccess={resetUserAccess} showDeleteConfirmation={showDeleteConfirmation} />;
                          })}
                        </tbody>
                      </table>
                    </div>
                  }
                  {users.length === 0 &&
                    <div className="flex-grow-1 d-flex align-items-center justify-content-center" style={{ height: 'calc(100vh - 295px)', overflowY: 'scroll', overflowX: 'hidden' }}>
                      <div className="text-center">                
                        <img alt="" src={empty} width={150} />
                        <h5 className="fw-bold mb-3">Add your first user here.</h5>
                        <p>
                          You can add new user by clicking on the <br/>
                          <strong>Add user</strong> button.
                        </p>
                        <button type="button" className="btn btn-estorya text-white" onClick={() => {setAddUserValues(defaultEmptyUser); setAddUserModalIsOpen(true); }}>Add User</button>
                      </div>
                    </div>
                  }
                </>
              }
            </div>

          </div>
        </div>
      </div>

      <AddUserModal
        permissions={permissions}
        addUser={addUser}
        classroomOptions={classroomOptions}
        addUserModalIsOpen={addUserModalIsOpen}
        setAddUserModalIsOpen={setAddUserModalIsOpen}
        USEREDITMODE={USEREDITMODE}
        addUserValues={addUserValues}
        setAddUserValues={setAddUserValues} 
        canSaveAddUser={canSaveAddUser} 
        addUserIsSubmitting={addUserIsSubmitting}
      />
      <AddUserBulkModal
        addUserBulkModalIsOpen={addUserBulkModalIsOpen}
        setAddUserBulkModalIsOpen={setAddUserBulkModalIsOpen}
        addUsersIsSubmitting={addUsersIsSubmitting}
        file={file}
        setFile={setFile}
        progressStatus={progressStatus}
        addUsers={addUsers}
        canSaveAddUsers={canSaveAddUsers}
        openAddUserModal={()=>{setAddUserValues(defaultEmptyUser); setAddUserModalIsOpen(true)}}
      />

      <AddClassroomModal
         addClassroom={addClassroom}
         classroomModalIsOpen={classroomModalIsOpen}
         setClassroomModalIsOpen={setClassroomModalIsOpen}
         isClassroomNameAlreadyExists={isClassroomNameAlreadyExists}
         canCreateClassroom={canCreateClassroom}
         classroomModalValues={classroomModalValues}
         setClassroomModalValues={setClassroomModalValues}
         classroomModalIsSaving={classroomModalIsSaving}
         defaultEmptyClassroom={defaultEmptyClassroom}
      />

      <Modal isOpen={temporaryPasswordModalIsOpen} size="md" centered={true} contentClassName="border-0 shadow">
        <div className="modal-content border-0 overflow-hidden">
          <div className="modal-header border-0">
            <h6 className="modal-title fw-bold">Set a Temporary Password</h6>
            <button type="button" className="position-absolute btn btn-light btn-sm bg-transparent border-0" style={{ right: '10px', top: '10px' }} onClick={doneSetTemporaryPassword}><img alt="" src={iconExit} /></button>
          </div>
          <div className="modal-body">
            <p>We've generated a temporary password, with the details below.</p>
            <p>Logging in with these credentials will prompt the user to change their password.</p>

            <div className="card bg-light mb-2">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <p className="mb-0">Login to</p>
                    <p className="mb-0 fw-bold">https://web.estorya.ph</p>
                  </div>
                  <div className="col">
                    <p className="mb-0">Email</p>
                    <p className="mb-0 fw-bold">{addUserValues.email}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card bg-light">
              <div className="card-body">
                <div className="row">
                  <div className="col">
                    <p className="mb-0">Temporary Password</p>
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="mb-0 fw-bold text-estorya">{temporaryPassword}</h4>
                      <button type="button" className="btn btn-link text-estorya btn-sm text-decoration-none text-uppercase text-oxanium" onClick={copyTemporaryPassword}>Copy</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer d-flex">
            <button type="button" className="btn btn-estorya" onClick={doneSetTemporaryPassword}>Done</button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={resetUserAccessModalIsOpen} size="md" centered={true} contentClassName="border-0 shadow">
        <div className="modal-content border-0 overflow-hidden">
          <div className="modal-header border-0">
            <h6 className="modal-title fw-bold">Reset User Access</h6>
            <button type="button" className="position-absolute btn btn-light btn-sm bg-transparent border-0" style={{ right: '10px', top: '10px' }} onClick={() => { setResetUserAccessModalIsOpen(false) }}><img alt="" src={iconExit} /></button>
          </div>
          <div className="modal-body">
            <p>This process allows you to reset user access if they are not able to access their account. Choose an access reset option to continue.</p>
            <div className="mb-3">
              <label for="resetUserAccessMode" class="form-label">Reset Option</label>
              <select id="resetUserAccessMode" class="form-select" value={resetUserAccessMode} onChange={(e) => { setResetUserAccessMode(e.target.value) }}>
                {addUserValues.date_joined ?
                  <option value={RESETUSERACCESSMODE.SENDPASSWORDRESETINSTRUCTIONSTHROUGHEMAIL}>{RESETUSERACCESSMODE.SENDPASSWORDRESETINSTRUCTIONSTHROUGHEMAIL}</option>
                  :
                  <option value={RESETUSERACCESSMODE.RESENDACTIVATIONEMAIL}>{RESETUSERACCESSMODE.RESENDACTIVATIONEMAIL}</option>
                }
                <option value={RESETUSERACCESSMODE.SETATEMPORARYPASSWORD}>{RESETUSERACCESSMODE.SETATEMPORARYPASSWORD}</option>
              </select>
            </div>
          </div>
          <div className="modal-footer d-flex">
            <button type="button" className="btn btn-estorya" onClick={sendResetUserAccess}>Done</button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={toDeleteUserModalIsOpen} toggle={() => { setToDeleteUserModalIsOpen(!toDeleteUserModalIsOpen); }} centered={true} contentClassName="border-0 shadow">
        <div className="modal-content border-0 overflow-hidden">
          <div className="modal-header border-0">
            <h6 className="modal-title fw-bold">Delete User</h6>
            <button type="button" className="position-absolute btn btn-light btn-sm bg-transparent border-0" style={{ right: '10px', top: '10px' }} onClick={() => setToDeleteUserModalIsOpen(false)}><img alt="" src={iconExit} /></button>
          </div>
          <div className="modal-body">
            <p className="mb-0">
              Are you sure you want to delete this user <strong>({toDeleteUser?.firstname} {toDeleteUser?.lastname})</strong>?<br/>
              You cannot undo this action.
            </p>
          </div>
          <div className="modal-footer border-0 d-flex">
            <button type="button" className="btn btn-light" onClick={() => { setToDeleteUserModalIsOpen(false); }} >Cancel</button>
            <button type="button" className="btn btn-danger" onClick={() => { deleteUser(); setToDeleteUserModalIsOpen(false); }} >Delete User</button>
          </div>
        </div>
      </Modal>

    </div>
    </AccessControlLayout>
  )
}

export default Users;
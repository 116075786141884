import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie-player';
import { DebounceInput } from 'react-debounce-input';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, Modal } from 'reactstrap';
import iconActions from './icon-actions.svg';
// import { faker } from '@faker-js/faker';
import './Students.css';
import empty from '../../assets/img/empty.png';
import { toRoman } from '../../utils/utls';
import { APIENDPOINT, axiosInstance, PERMISSIONS } from '../../config';
// import iconPersonSearch from './icon-person-search.svg';
import loadingAnimation from '../../assets/lottie/loadingAnimation.json';
import DataFilters from '../../utils/DataFilters';
import AddStudentModal from './AddStudent/AddStudentModal';
import AddStudentNotificationPanel from './NotificationPanel/AddStudentNotificationPanel';
import { useLocation } from 'react-router-dom';
import DeleteStudentModal from './DeleteStudentModal';
import EditStudentModal from './AddStudent/EditStudentModal';
import { hasPermission } from '../../utils/user';
import ClassroomManagementLayout from '../Layouts/ClassroomManagementLayout';



function StudentListItem({permissions, student, isHighlighted, deleteStudent, updateStudent }) {
  return (
    <tr className={`border-light ${isHighlighted ? 'table-row-highlight' : ''}`}>
      <td className="align-middle text-lowercase">
        {student.lastname}, {student.firstname} {student.middlename} 
      </td>
      <td className="align-middle text-capitalize">
        {student.classroom ? `Grade ${toRoman(student.classroom.grade_level_id)} - ${student.classroom.name}` : '-'}
      </td>
      <td className="align-middle text-capitalize">
        {student.classroom?.teacher ? `${student.classroom?.teacher?.firstname} ${student.classroom?.teacher?.lastname}` : '-'}
      </td>
      <td className="align-middle">
        {student.lrn_id}
      </td>
      <td className="align-middle">
        {student.email}
      </td>
      <td className="align-middle">
        {student.created_at ? moment(student.created_at).format('DD-MMM-YYYY') : '-'}
      </td>
      <td className="align-middle">
        <UncontrolledDropdown>
          <DropdownToggle color="outline-light">
            <img src={iconActions} alt="options" />
          </DropdownToggle>
          <DropdownMenu className="py-1" style={{ minWidth: '155px' }}>
            {hasPermission(permissions, [PERMISSIONS.UPDATE_STUDENT]) &&
              <button className="px-3 py-1 btn w-100 text-start border-0 btn-outline-dark rounded-0" onClick={()=>updateStudent(student)} >Edit Student</button>
            }
            {hasPermission(permissions, [PERMISSIONS.DELETE_STUDENT]) &&
                <button className="px-3 py-1 btn w-100 text-start border-0 btn-outline-danger rounded-0" onClick={()=>deleteStudent(student)}>Remove Student</button>
            }
          </DropdownMenu>
        </UncontrolledDropdown>
      </td>
    </tr> 
  )
};

function Students() {

  const token = useSelector(state => state.app.token);
  const permissions = useSelector(state => state.app.permissions);
  const location = useLocation()
  const [students, setStudents] = useState([]);
  const [studentModalIsOpen, setStudentModalIsOpen] = useState(location.state?.studentModalIsOpen ? location.state.studentModalIsOpen : false);
  const [toDeleteStudentModalIsOpen, setToDeleteStudentModalIsOpen] = useState(false)
  const [toDeleteStudent, setToDeleteStudent] = useState(null)
  const [toEditStudentModalIsOpen, setToEditStudentModalIsOpen] = useState(false)
  const [toEditStudent, setToEditStudent] = useState(null)
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentsPerPage, setStudentsPerPage] = useState(20);
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('created_at');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filters, setFilters] = useState({
    classroomsFilters: [],
    teachersFilters: [],
  });
  const [classroomsFilters, setClassroomsFilters] = useState([]);
  const [loadingClassroomsFilters, setLoadingClassroomsFilters] = useState(false);
  const [teachersFilters, setTeachersFilters] = useState([]);
  const [loadingTeachersFilters, setLoadingTeachersFilters] = useState(false);
  const [newStudentsCreated, setNewStudentsCreated] = useState([])
  const [bulkCreationStudents, setBulkCreationStudents] = useState([]);
  const [existingStudentAdded, setExistingStudentAdded] = useState([]);
  const [singleCreationStudent, setSingleCreationStudent] = useState({})
  const [deletedStudent, setDeletedStudent] = useState({})
  const [updatedStudent, setUpdatedStudent] = useState({})
  const [showAddStudentNotificationPanel, setShowAddStudentNotificationPanel] = useState(false);


  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoadingStudents(true);

        let classroomFilters = '';
        if(filters.classroomsFilters.length > 0) {
          classroomFilters = filters.classroomsFilters.map(obj => obj.id).join(',');
        }

        let teachersFilters = '';
        if(filters.teachersFilters.length > 0) {
          teachersFilters = filters.teachersFilters.map(obj => obj.id).join(',');
        }

        const response = await axiosInstance.get(`${APIENDPOINT}/users?role=Student&page=${currentPage}&limit=${studentsPerPage}&sortBy=${sortBy}&direction=${sortDirection}${classroomFilters.length > 0 ? `&classroomIds=${classroomFilters}` : ''}${teachersFilters.length > 0 ? `&teacherIds=${teachersFilters}` : ''}${search.length > 0 ? `&key=${search}` : ''}`, { headers: { 'Authorization': `Bearer ${token}`} });

        setLoadingStudents(false);
        setStudents(response.data.data.data);
        setTotal(response.data.data.total);
      } catch (error) {
        setLoadingStudents(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchStudents();
  }, [currentPage, studentsPerPage, sortBy, sortDirection, search, filters.classroomsFilters, filters.teachersFilters, token, singleCreationStudent, bulkCreationStudents, existingStudentAdded, deletedStudent, updatedStudent]);

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        setLoadingTeachersFilters(true);
        
        const response = await axiosInstance.get(`${APIENDPOINT}/users?role=Teacher&page=${1}&limit=${100}`, { headers: { 'Authorization': `Bearer ${token}`} });

        setLoadingTeachersFilters(false);
        setTeachersFilters(response.data.data.data.map(teacher => ({...teacher, name: `${teacher.firstname} ${teacher.lastname}` })).sort((teacherA, teacherB) => { return teacherA.name.localeCompare(teacherB.name); }));
      } catch (error) {
        setLoadingTeachersFilters(false);
        console.error('Error fetching data:', error);
      }
    };

    const fetchClassroomsForFilter = async () => {
      try {
        setLoadingClassroomsFilters(true);
        const response = await axiosInstance.get(`${APIENDPOINT}/classrooms?page=${1}&limit=${100}`, { headers: { 'Authorization': `Bearer ${token}`} });

        setLoadingClassroomsFilters(false);
        setClassroomsFilters(response.data.data.data.map(classroom => ({ ...classroom, name: `Grade ${classroom.grade_level_id ? toRoman(classroom.grade_level_id) : '-'} - ${classroom.name}` })).sort((classroomA, classroomB) => { return classroomA.name.localeCompare(classroomB.name); }));
      } catch (error) {
        setLoadingClassroomsFilters(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchTeachers();
    fetchClassroomsForFilter();
  }, [token]);

  useEffect(() => {
    let newStudentsCreatedTemp = []
    bulkCreationStudents.map((newStudent) => {
      newStudentsCreatedTemp.push({
        lrn_id: newStudent?.LRN_ID
      })
      return true
    })
    setNewStudentsCreated((prevItems) => [...prevItems, ...newStudentsCreatedTemp])
  },[bulkCreationStudents])

  useEffect(() => {
    let newStudentsCreatedTemp = []
    existingStudentAdded.map((newStudent) => {
      newStudentsCreatedTemp.push({
        lrn_id: newStudent?.lrn_id
      })
      return true
    })
    setNewStudentsCreated((prevItems) => [...prevItems, ...newStudentsCreatedTemp])
  },[existingStudentAdded])


  useEffect(() => {
    if(singleCreationStudent?.lrn_id){
      setNewStudentsCreated((prevItems) => [...prevItems, {lrn_id: singleCreationStudent.lrn_id }])
    }
  },[singleCreationStudent])

  const pageCount = Math.ceil(total / studentsPerPage);

  const handlePageClick = (i) => {
    setCurrentPage(i.selected + 1);
  };

  const updateSort = (attribute) => {
    if(sortBy === attribute) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(attribute);
      setSortDirection('asc');
    }
  };

  const isHighlighted = (student) => {
    let isNew = false
    isNew = newStudentsCreated.some(newStudent => newStudent?.lrn_id === student?.lrn_id);
    
    return isNew;
  }

  return (
    <ClassroomManagementLayout>
    <div id="students" className="animated fadeIn flex-grow-1 d-flex bg-white" style={{ height: 'calc(100vh - 400px)' }}>
      <Helmet>
        <title>{`eStorya: Classroom Management - Students`}</title>
      </Helmet>

      <div className="flex-grow-1 d-flex bg-white p-4">
        <div className="container-fluid d-flex flex-column">
          <div className="mb-0">
            <div className="row align-items-center justify-content-between" style={{ height: 40 }}>
              <div className="col-auto">
                <h5 className="fw-bold mb-0">Students <span className="text-muted fw-normal ms-3">{total} total</span></h5>
              </div>
              {hasPermission(permissions, [PERMISSIONS.CREATE_STUDENT]) &&
                <div className="col-auto">
                  <button type="button" className="btn btn-estorya" onClick={() => { setStudentModalIsOpen(true)}}><FontAwesomeIcon icon="fa-solid fa-plus" size="xs" className="me-2" /> Add a student</button>
                </div>
              }
             
            </div>
          </div>
          <div className="mb-0">
            <div className="row align-items-center justify-content-between" style={{ minHeight: '60px' }}>

              <div className="col-auto">
                <div className="input-group">
                  <label className="input-group-text bg-transparent border-right-0" htmlFor="searchStudent"><FontAwesomeIcon icon="search" /></label>
                  {/* <input type="text" className="form-control border-left-0" id="searchTeacher" placeholder="Search for Student" style={{ width: '20rem' }} value={search} onChange={(e) => { setSearch(e.target.value) }} /> */}
                  <DebounceInput type="text" className="form-control border-left-0" id="searchTeacher" placeholder="Search for Student" style={{ width: '20rem' }} value={search} debounceTimeout={1000} onChange={(e) => { setSearch(e.target.value); setCurrentPage(1) }} />
                </div>
              </div>

              <div className="col-auto">
                <div className="row">

                  {/* <div className="col-auto">
                    <button className="disabled btn btn-light" disabled>
                      <FontAwesomeIcon icon="filter" className="text-muted" />
                    </button>
                  </div> */}

                  {/* <div className="col-auto p-0 my-2 mx-2" style={{ borderLeft: '1px solid #CCCCCC' }}>
                  </div> */}

                  <div className="col-auto">
                    <div className="input-group">
                      <label htmlFor="page" className="form-label input-group-text mb-0 border-0 ps-0 bg-transparent">Rows:</label>
                      <select id="page" className="form-select rounded" value={studentsPerPage} onChange={(e) => { setCurrentPage(1); setStudentsPerPage(parseInt(e.target.value, 10)) }}>
                        <option value={1}>1</option>
                        <option value={5}>5</option>
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                      </select>
                    </div>
                  </div>

                  {/* Pagination */}
                  <nav className="col-auto d-flex align-items-center">
                    <ReactPaginate
                      previousLabel={<FontAwesomeIcon icon="chevron-left" />}
                      nextLabel={<FontAwesomeIcon icon="chevron-right" />}
                      pageClassName="page-item"
                      previousLinkClassName="page-link rounded text-estorya me-1"
                      nextLinkClassName="page-link rounded text-estorya ms-1"
                      pageLinkClassName="page-link border-estorya text-estorya rounded mx-1"
                      breakLinkClassName="page-link rounded text-estorya mx-1"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={1}
                      onPageChange={handlePageClick}
                      containerClassName="pagination mb-0"
                      activeLinkClassName="bg-estorya text-white"
                      disabledClassName="disabled"
                      activeClassName="active"
                      forcePage={currentPage - 1}
                    />
                  </nav>
                </div>
              </div>

            </div>
          </div>
          
          {loadingStudents ?
            <div className="flex-grow-1 d-flex align-items-center justify-content-center">
              <div className="text-center">
                <Lottie
                  className="mt-2 mb-4"
                  loop
                  animationData={loadingAnimation}
                  speed={0.75}
                  play
                  style={{ width: 300, height: 300, margin: "0 auto" }}
                />          
              </div>
            </div>
            :
            <>
              <div className="flex-grow-1 overflow-auto" style={{ height: 'calc(100vh - 395px)' }}>
                <table className="table mb-0">
                  <thead>
                    <tr className="table-light">
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Student Name</span>
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Assigned Classroom</span>
                        <DataFilters
                          name="Classrooms"
                          filters={classroomsFilters}
                          selectedItems={filters.classroomsFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, classroomsFilters: filterData });
                          }}
                          searchable={true}
                          loading={loadingClassroomsFilters}
                        />
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Teacher</span>
                        <DataFilters
                          name="Teachers"
                          filters={teachersFilters}
                          selectedItems={filters.teachersFilters}
                          setFilters={(filterData) => {
                            setFilters({ ...filters, teachersFilters: filterData });
                          }}
                          searchable={true}
                          loading={loadingTeachersFilters}
                        />
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">LRN</span>
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Email Address</span>
                      </th>
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0' }}>
                        <span className="me-2">Date Joined</span><button type="button" className="border-0 p-0 m-0 bg-transparent text-muted"  onClick={() => { updateSort('created_at'); }}><FontAwesomeIcon icon={sortBy === 'created_at' ? sortDirection === 'asc' ? 'sort-up' : 'sort-down' : 'sort'} size="xs" /></button>
                      </th>   
                      <th className="border border-top-0 border-end-0 border-start-0 border-light-subtle position-sticky" style={{ top: '0', width: '30px', zIndex: 1 }}>Actions</th> 
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {students.map(student => {
                      return (
                        <StudentListItem 
                          permissions={permissions}
                          key={`student-${student.id}`} 
                          student={student} 
                          isHighlighted={isHighlighted(student)} 
                          updateStudent={(val) => {setToEditStudent(val); setToEditStudentModalIsOpen(true)}} 
                          deleteStudent={(val) => {setToDeleteStudent(val); setToDeleteStudentModalIsOpen(true)}}/>
                      )
                    })}
                  </tbody>
                </table>
                {students.length === 0 &&
                <div className="flex-grow-1 d-flex align-items-center justify-content-center" style={{height:'500px'}}>
                  <div className="text-center">                
                    <img src={empty} alt="empty" width={150} />
                    <h5 className="fw-bold mb-3">Sorry, we couldn’t find any match.</h5>
                    <p>You can add new learner by clicking on<br/>the <strong>Add New Student</strong> button.</p>
                    <button type="button" className="disabled btn btn-estorya" onClick={() => {  }}><FontAwesomeIcon icon="plus" className="me-2" />Add new Student</button>
                  </div>
                </div>
              }
              </div>
              
            </>
          }
        </div>
      </div>
      
      <DeleteStudentModal
        toDeleteStudentModalIsOpen={toDeleteStudentModalIsOpen}
        setToDeleteStudentModalIsOpen={setToDeleteStudentModalIsOpen}
        toDeleteStudent={toDeleteStudent}
        setToDeleteStudent={setToDeleteStudent}
        setDeletedStudent={setDeletedStudent}
        token={token}
      />
      <AddStudentModal 
        studentModalIsOpen={studentModalIsOpen} 
        setStudentModalIsOpen={setStudentModalIsOpen}
        setShowAddStudentNotificationPanel={setShowAddStudentNotificationPanel}
        setSingleCreationStudent={setSingleCreationStudent}
        setBulkCreationStudents={setBulkCreationStudents}
        setExistingStudentAdded={setExistingStudentAdded}
      />

      <EditStudentModal
        toEditStudentModalIsOpen={toEditStudentModalIsOpen}
        setToEditStudentModalIsOpen={setToEditStudentModalIsOpen}
        toEditStudent={toEditStudent}
        setToEditStudent={setToEditStudent}
        updatedStudent={updatedStudent}
        setUpdatedStudent={setUpdatedStudent}
      />
      {showAddStudentNotificationPanel &&
        <AddStudentNotificationPanel
          setShowAddStudentNotificationPanel={setShowAddStudentNotificationPanel}
          bulkCreationStudents={bulkCreationStudents}
        />
      }
     
      {/* <div className="flex-grow-1 d-flex bg-light p-4">
        Classrooms
        <pre>
        {JSON.stringify(sampleClassrooms, "", 2)}
        </pre>
      </div> */}
    </div>
    </ClassroomManagementLayout>
  );
};

export default Students;
import { useEffect } from 'react';
import iconExit from './icon-exit.svg';

function OverAllScoreOverlay({overAllScore, showOVerAllScore, setShowOverAllScore}) {
    return(
        <div className={`border shadow-sm bg-white p-4 ${showOVerAllScore ? '' : 'd-none'}`} style={{width:'500px', maxHeight:'500px', position:'absolute', right:'-1px', top:'-1px', zIndex:1}}>
            <div className='d-flex align-items-center justify-content-between'>
                <p className='mb-0 text-estorya fw-bold'>
                    {overAllScore?.book?.title}
                </p>
                <img alt="" src={iconExit} width={20} onClick={()=>setShowOverAllScore(false)} />
            </div>
            <p><small>Overall Score</small></p>
            <div className='overflow-auto' style={{height:'350px'}}>
                <table className="table mb-0">
                    <thead style={{boxShadow:'none'}}>
                        <tr className="table-light">
                            <th className="border-light position-sticky" style={{ top: '0' }}>
                                <span className="me-2">Page</span>
                            </th>
                            <th className="text-center border-light position-sticky" style={{ top: '0', width: '250px' }}>
                                <span className="me-2">Score</span>
                            </th>
                            <th className="text-center border-light position-sticky" style={{ top: '0', width: '160px' }}>
                                <span className="me-2">Mistakes</span>
                            </th>
                            <th className="text-center border-light position-sticky" style={{ top: '0', width: '160px' }}>
                                <span className="me-2">Date Scored</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white">
                        
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16, 17].map((num, key)=>{
                            return (
                                <tr key={key}>
                                    <td className="align-middle">{num}</td>
                                    <td className="align-middle text-center">
                                    79/<span className='text-muted'>80</span>
                                    </td>
                                    <td className="align-middle text-center text-danger">
                                        1
                                    </td>
                                    <td className="align-middle text-center">
                                        09-Oct-2024
                                    </td>
                                </tr>
                            )
                        })}
        
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default OverAllScoreOverlay
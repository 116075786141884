import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu, 
} from 'reactstrap';
import iconChevronLeft from './icons-chevron-left.svg'
import iconAddUser from './icon-add-users.svg';
import { hasPermission } from "../../../../utils/user";
import { PERMISSIONS } from "../../../../config";

const AddUserDropdownMenu = ({openAddUserModal, ADDUSERMODE, permissions}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen((prevState) => !prevState);

  const closeDropdown = () => setIsOpen(false);

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle color="btn btn-estorya">
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <img src={iconAddUser} className="me-1"/>
          </div>
          <div className="border  border-top-0  border-bottom-0  border-start-0 pe-4" style={{ cssText: 'border-color: #9DD821 !important' }}>
            <span>Add User</span> 
          </div>
          <div className="d-flex align-items-center">
            <img src={iconChevronLeft} className="ms-2" height={20}/>
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu className="px-3 py-4 add-user-menu" style={{ width: '221px' }}>
        <p className="cursor-pointer" onClick={()=>{openAddUserModal(ADDUSERMODE.ADDUSER); closeDropdown()}}>Add User</p>
        {hasPermission(permissions, [PERMISSIONS.CREATE_CLASSROOM]) && 
          <>
          <p className="cursor-pointer" onClick={()=> {openAddUserModal(ADDUSERMODE.ADDUSERANDASSIGN); closeDropdown()}} >Add User and Assign</p>
          <p className="cursor-pointer" onClick={()=> {openAddUserModal(ADDUSERMODE.BULKADDUSERANDASSiGN); closeDropdown()}}>Bulk Add User and Assign</p>
          </>
        } 
        
      </DropdownMenu>
    </Dropdown>
  );
};

export default AddUserDropdownMenu;

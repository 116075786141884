import React, { useState } from "react";
import {
    Modal
  } from 'reactstrap';
import iconExit from './icon-exit.svg';
import Select, {components} from "react-select";
import { toRoman } from "../../../../utils/utls";
import toast from 'react-hot-toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomTeachersLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      <span className="align-middle">{props.children}</span>
    </components.MultiValueLabel>
  );
};


const AddClassroomModal = ({
    addClassroom,
    classroomModalIsOpen,
    setClassroomModalIsOpen,
    isClassroomNameAlreadyExists,
    canCreateClassroom,
    classroomModalValues,
    setClassroomModalValues,
    classroomModalIsSaving,
    defaultEmptyClassroom
}) => {

  return (
    <Modal isOpen={classroomModalIsOpen} size="lg" centered={true} contentClassName="border-0 shadow">
        <div className="modal-content border-0 overflow-hidden">
          <form autoComplete="off" onSubmit={addClassroom}>
            <div className="modal-header border-0">
                <h6 className="modal-title fw-bold">Add a new Classroom</h6>
              <button 
                type="button" 
                className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                style={{ right: '10px', top: '10px' }} 
                onClick={() => {
                  setClassroomModalIsOpen(false);
                  toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
                    <div>
                      <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
                    </div>
                    <div>
                      <h6 className="fw-bold mb-1 text-estorya">Successfully Created User</h6>
                      <p className="mb-0">The Activation Email has been sent to {classroomModalValues.teacher.email}</p>
                    </div>
                  </div>);
                  setClassroomModalValues(defaultEmptyClassroom);
                }}>
                    <img alt="" src={iconExit} />
                </button>
            </div>
            <div className="modal-body">

              <div className="mb-3 row">
                <div className="col">
                  <label className="form-label fw-bold">Classroom Name</label>
                  <h5 className="fs-4">{canCreateClassroom ? <span className="fw-bold">Grade {toRoman(classroomModalValues.grade_level_id)} - {classroomModalValues.name}</span> : <span className="fst-italic text-estoryalight">set a grade level and section name first</span>}</h5>
                </div>
                {isClassroomNameAlreadyExists}
              </div>

              <div className="mb-2 row">
                <div className="col">
                  <label htmlFor="level" className="form-label fw-bold">Grade Level <span className="text-danger">*</span></label>
                  <select id="level" className="form-select" value={classroomModalValues.grade_level_id} onChange={(e) => { setClassroomModalValues({...classroomModalValues, grade_level_id: e.target.value}) }}>
                    <option value={1}>Grade {toRoman(1)}</option>
                    <option value={2}>Grade {toRoman(2)}</option>
                    <option value={3}>Grade {toRoman(3)}</option>
                    <option value={4}>Grade {toRoman(4)}</option>
                    <option value={5}>Grade {toRoman(5)}</option>
                    <option value={6}>Grade {toRoman(6)}</option>
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="lastName" className="form-label fw-bold">Section <span className="text-danger">*</span></label>
                  <input
                    type="text"
                    className={`form-control ${isClassroomNameAlreadyExists ? 'is-invalid' : ''}`}
                    id="lastName"
                    value={classroomModalValues.name}
                    onChange={(e) => { setClassroomModalValues({...classroomModalValues, name: e.target.value}) }}
                  />
                  {isClassroomNameAlreadyExists &&
                    <div className="invalid-feedback">
                      Section name already exist
                    </div>
                  }
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="teacher" className="form-label fw-bold">Teacher <span className="text-danger">*</span></label>
                <Select
                  name="teacher"
                  inputId="teacher"
                  options={[]}
                  placeholder="Select a Teacher"
                  components={{MultiValueLabel: CustomTeachersLabel}}
                  styles={{
                    menuPortal: (base, state) => ({
                      ...base,
                      zIndex: "9999"
                    }),
                    multiValue: (base) => ({
                      ...base,
                      border: 'solid 1px #5B8DF5',
                      borderRadius: '0.25rem',
                      backgroundColor: '#5B8DF533',
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      color: '#5B8DF5',
                      fontWeight: 'bold'
                    }),
                    multiValueRemove: (base) => ({
                      ...base,
                      color: '#5B8DF5',
                    }),
                    menuList: (base) => ({
                      ...base,
                    })
                  }}
                  value={classroomModalValues.teacher}
                  getOptionLabel={option => `${option.firstname} ${option.lastname}`}
                  onChange={(e) => { setClassroomModalValues({...classroomModalValues, teacher: e}) }}
                  menuPortalTarget={document.body}
                  isSearchable={false}
                />
              </div>
             
            </div> 
            <div className="modal-footer d-flex">
                <button type="submit" className="btn btn-estorya" disabled={!canCreateClassroom || classroomModalIsSaving}>Save Classroom</button>
            </div>
          </form>
        </div>
      </Modal>
  );
};

export default AddClassroomModal;

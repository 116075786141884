import { toast } from 'react-hot-toast';
import { APIENDPOINT, axiosInstance } from '../config';
import { store } from './store';
import { logout } from './appSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const handleError = (status) => {

  switch(status) {
    case 401:
      const logOut = async() => {
        try {
          await axiosInstance({
            url:`${APIENDPOINT}/logout`,
            method: 'POST'
          });
          toast.custom(<div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
            <div>
              <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
            </div>
            <div>
              <h6 className="fw-bold mb-0 text-estorya">You have been logged out due to inactivity.</h6>
            </div>
          </div>);
        } catch(e) {
          console.log(e);
        } finally {
          store.dispatch(logout())
        }
      };

      logOut();
      break;
    default:
      break;
  }
};
import axios from "axios";

export const LOCAL = false;

let apiConfig = {
  apiEndpoint: process.env.REACT_APP_SERVICES_API_URL,
  apiEndpointInsights: process.env.REACT_APP_INSIGHTS_API_URL,
  apiEndpointAuth: process.env.REACT_APP_AUTH_API_URL
}

// if(LOCAL) {
//   apiConfig.apiEndpoint = 'https://estorya-services-staging.azurewebsites.net/api';
//   apiConfig.apiEndpointAuth = 'https://estorya-services-staging.azurewebsites.net/api';
//   apiConfig.apiEndpointInsights = 'https://estorya-insights-staging.azurewebsites.net/api';
//   // apiConfig.apiEndpointAuth = 'https://asia-east1-ace-sector-251421.cloudfunctions.net/estorya'
// }

// export const APIENDPOINT = 'https://estorya-api-n5yb9.ondigitalocean.app/api';
export let APIENDPOINT_AUTH = apiConfig.apiEndpointAuth;
export const APIENDPOINT = apiConfig.apiEndpoint;
export const APIENDPOINT_INSIGHTS = apiConfig.apiEndpointInsights;
export const axiosInstance = axios.create();

export const ROLES = {
  SUPERADMIN: 'Superadmin',
  TEACHER: 'Teacher',
  STUDENT: 'Student',
  SCHOOLMODERATOR: 'School Moderator',
  DIVISION:'School Division',
  PRINCIPAL:'Principal'
};

// permissions conts here
export const  PERMISSIONS = {
  CREATE_SCHOOL: 'create school',
  CREATE_PRINCIPAL: 'create principal',
  CREATE_SCHOOL_MODERATOR: 'create school mod',
  CREATE_TEACHER: 'create teacher',
  CREATE_CLASSROOM: 'create classroom',
  CREATE_STUDENT: 'create student',
  VIEW_DASHBOARD: 'view dashboard',
  VIEW_DIVISION: 'view division',
  VIEW_SCHOOL: 'view school',
  VIEW_PRINCIPAL: 'view principal',
  VIEW_SCHOOL_MODERATOR: 'view school mod',
  VIEW_TEACHER: 'view teacher',
  VIEW_CLASSROOM: 'view classroom',
  VIEW_STUDENT: 'view student',
  UPDATE_SCHOOL: 'update school',
  UPDATE_PRINCIPAL: 'update principal',
  UPDATE_SCHOOL_MODERATOR:'update school mod',
  UPDATE_TEACHER:  'update teacher',
  UPDATE_CLASSROOM: 'update classroom',
  UPDATE_STUDENT: 'update student',
  DELETE_SCHOOL : 'delete school',
  DELETE_PRINCIPAL: 'delete principal',
  DELETE_SCHOOL_MODERATOR: 'delete school mod',
  DELETE_TEACHER: 'delete teacher',
  DELETE_CLASSROOM: 'delete classroom',
  DELETE_STUDENT: 'delete student',
}

export const SCHOOLID = 1;

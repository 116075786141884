import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';
import iconExit from './../icon-exit.svg';


function ExisitingStudentResult ({closeModal, exisitingStudentResult}) {

    const handleFinish = () => {
        closeModal();
        toast.custom(
            <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
                <div>
                    <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
                </div>
                <div>
                    <h6 className="fw-bold mb-1 text-estorya">Successfully added students</h6>
                    <p className="mb-0">You added {exisitingStudentResult.length} students to this school.</p>
                </div>
            </div>
        );
    }
    
    return(
        <>
            <div className="modal-header border-0">
                <h6 className="modal-title fw-bold">Successfully added accounts</h6>
                <button 
                    type="button" 
                    className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                    style={{ right: '10px', top: '10px' }} 
                    onClick={() => closeModal()}>
                    <img alt="" src={iconExit} />
                </button>
            </div>
            <div className="modal-body">
                <p className='fw-bold'>You have successfully added student accounts to Estorya portal.</p>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th className='fw-bold '>Email Address</th>
                                <th className='fw-bold '>Student Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {exisitingStudentResult.map((student, key)=>{
                                return(
                                    <tr key={key}>
                                        <td>
                                            <p className='mb-0'>{student.email}</p>
                                        </td>
                                        <td>
                                            <p className='mb-0'>
                                            {student?.lastname}, {student?.firstname} {student?.middlename} {student?.extension}
                                            </p>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div> 
            <div className="modal-footer d-flex">
                <button onClick={()=>handleFinish()} type="button" className="btn btn-estorya" >Finished</button>
            </div>
        </>
    )
}

export default ExisitingStudentResult;
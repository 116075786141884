import React, { useState, useEffect } from 'react';
import { Popover } from 'react-tiny-popover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Lottie from 'react-lottie-player';

import loadingAnimation from '../assets/lottie/loadingAnimation.json';

const DataFilters = ({
  filters,
  setFilters,
  selectedItems,
  name = '',
  searchable = false,
  realign = false,
  selectableAll = true,
  useCodeAsData = false,
  refetch = null,
  loading = false
}) => {
  const [isOpen, setOpen] = useState(false);
  const [data, setData] = useState(selectedItems);
  const [filteredFilters, setFilteredFilters] = useState([...filters]);
  const [search, setSearch] = useState('');

  const toggleItem = (item) => {
    const newData = data.includes(item)
      ? data.filter((i) => i !== item)
      : [...data, item];
    setData(newData);
  };

  const selectAll = () => {
    setData(filters.map((item) => useCodeAsData ? item.code : item.value || item));
  };

  const generateKey = (pre) => {
    return `${name}_${pre.id}_${new Date().getTime()}`;
  };

  const onClosed = () => {
    setData(selectedItems);
    setOpen(false);
    setSearch("");
    onSearch({ target: { value: '' } }); // synthetic event object
  };

  const onSearch = e => {
    if (refetch) refetch(e.target.value)
    setSearch(e.target.value)
  }

  useEffect(() => {
    let filts = [...filters];
    if (search.length > 0) {
      filts = filts.filter(x => {
        if (typeof x === 'object') {
          return x?.name?.match(new RegExp(`${search}`, 'gi'));
        } else {
          return x.toString().match(new RegExp(`${search}`, 'gi'));
        }
      })
    }
    setFilteredFilters(filts);
  }, [search, filters]);

  return (
    <Popover
      positions={["bottom"]} // preferred position
      align={realign ? 'start' : 'center'}
      isOpen={isOpen}
      onClickOutside={() => {
        onClosed();
      }}
      transitionDuration={0}
      content={(
        <div
          className="card filter-card rounded-0"
          style={{ minWidth: '300px', marginTop: '8px' }}
        >
          <div className="card-body">
            <div className="row mb-3">
              <div className="col col fw-bold">Filter {name ? name : ''}</div>
              <div className="col col-6 text-end">
                {selectableAll &&
                  <button
                    type="button"
                    className="link-button text-estorya me-2"
                    onClick={() => selectAll()}
                  >
                    Select All
                  </button>
                }
                <button
                  type="button"
                  className="link-button text-estorya"
                  onClick={() => setData([])}
                >
                  Clear All
                </button>
              </div>
            </div>
            {searchable &&
              <div className="row mb-3">
                <div className="col">
                  <div className="input-group input-group-sm">
                    <div className="input-group-prepend">
                      <div className="input-group-text rounded-0 bg-white border-end-0"><FontAwesomeIcon icon="search" className="text-muted" style={{ height: '18px' }} /></div>
                    </div>
                    <input type="text" className="form-control rounded-0 border-start-0" value={search} onChange={onSearch} placeholder={name.includes("Product Number") ? `Enter Product Number` : `Search ${name}`} />
                  </div>
                </div>
              </div>
            }
            <div
              className="filter-selections mb-3 pl-1"
              style={{
                maxHeight: '200px',
                maxWidth: '250px',
                overflowY: 'auto',
                overflowX: 'hidden',
              }}
            >
              {/* <div className="row">
                <div className="col">
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="select-all"
                      checked={data.length === filters.length}
                      onChange={() => selectAll()}
                    />
                    <label
                      className="custom-control-label"
                      style={{ lineHeight: '24px' }}
                      htmlFor="select-all"
                    >
                      Select All
                    </label>
                  </div>
                </div>
              </div> */}
              {loading ?
                <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                  <Lottie
                    className="mt-2 mb-4"
                    loop
                    animationData={loadingAnimation}
                    speed={0.75}
                    play
                    style={{ width: 150, height: 100, margin: '0 auto' }}
                  />
                </div>
                : filteredFilters.map((item, key) => {
                  if (!item?.value && !item) return null;
                  return (
                    <div className="row" key={generateKey(item)}>
                      <div className="col">
                        <div className="custom-control custom-checkbox mb-1 form-check">
                          <input
                            type="checkbox"
                            className="custom-control-input form-check-input"
                            id={generateKey(item)}
                            checked={data.includes(useCodeAsData ? item.code : item.value || item)}
                            value={item}
                            onChange={(event) => {
                              event.stopPropagation();
                              toggleItem(useCodeAsData ? item.code : item.value || item);
                            }}
                          />
                          <label
                            className="custom-control-label form-check-label"
                            htmlFor={generateKey(item)}
                            style={{ lineHeight: '24px' }}
                          >
                            {item?.icon && <img src={item.icon} className="mr-2" style={{ height: '16px', maxWidth: '20px', marginTop: '-3px' }} alt={item.value || item} />}
                            <span className={item.filterItemclassNames} style={item.styles}>{item?.name || item}</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="row">
              <div className="col">
                <button
                  type="button"
                  className="btn btn-light btn-sm w-100"
                  onClick={() => onClosed()}
                >
                  Cancel
                </button>
              </div>
              <div className="col">
                <button
                  type="button"
                  className="btn btn-estorya btn-sm w-100"
                  disabled={loading || filteredFilters.length === 0}
                  onClick={() => {
                    setFilters(data);
                    setOpen(false);
                  }}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    >
      <div className="d-inline-block position-relative">
        <FontAwesomeIcon
          id="EventFilterButton"
          icon="filter"
          size="xs"
          className={`mt-1 ${!selectedItems.length && "text-muted"} cursor-pointer`}
          style={{ color: selectedItems.length ? "#689802" : "" }}
          onClick={() => setOpen(!isOpen)}
        />
      </div>
    </Popover>
  );
};

export default DataFilters;

import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconExit from '../AddStudent/icon-exit.svg';
import { APIENDPOINT, axiosInstance } from '../../../config';

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


function EditStudentModal({toEditStudentModalIsOpen, setToEditStudentModalIsOpen, setUpdatedStudent, toEditStudent}) {
    const defaultEmptyStudent = {
        id:'',
        firstname: '',
        lastname: '',
        middlename: '',
        extension: '',
        learnerReferenceNumber: '',
        email:'',
      };

    const token = useSelector(state => state.app.token);
    const [editStudentValues, setEditStudentValues] = useState(defaultEmptyStudent);
    const [editStudentIsSubmitting, setEditStudentIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")

    useEffect(()=>{

        if(toEditStudent) {
            setEditStudentValues({
                id: toEditStudent.id,
                firstname: toEditStudent.firstname,
                lastname: toEditStudent.lastname,
                middlename: toEditStudent.middlename,
                extension: toEditStudent.extension,
                learnerReferenceNumber: toEditStudent.lrn_id,
                email: toEditStudent.email,
            })
        }
    },[toEditStudent])

    const canSaveEditStudent = (
      editStudentValues.firstname 
      && editStudentValues.lastname 
      && editStudentValues.middlename
      && editStudentValues.learnerReferenceNumber
      && editStudentValues.email 
      && validateEmail(editStudentValues.email));

    const editStudent = async(e) => {
        e.preventDefault();
        setEditStudentIsSubmitting(true);
        setErrorMessage("")
        const formData = new FormData();

        formData.append("firstname", editStudentValues.firstname);
        formData.append("lastname", editStudentValues.lastname);
        formData.append("middlename", editStudentValues.middlename);
        formData.append("extension", editStudentValues.extension);
        formData.append("lrn_id", editStudentValues.learnerReferenceNumber);
        formData.append("email", editStudentValues.email);
        formData.append("role", 'Student');

        const queryString = new URLSearchParams(formData).toString();
        
        axiosInstance({
          url:`${APIENDPOINT}/users/${editStudentValues.id}?${queryString}`,
          method: 'PATCH',
          data: formData,
          headers: { 'Authorization': `Bearer ${token}`}
        }).then((res)=>{
            setUpdatedStudent(res.data.data.user)
            setToEditStudentModalIsOpen(false)
            setEditStudentIsSubmitting(false);
            toast.custom(
                <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
                    <div>
                        <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
                    </div>
                    <div>
                        <h6 className="fw-bold mb-0 text-estorya">Successfully Updated a Student</h6>
                    </div>
                </div>
            );
        }).catch((e)=>{
          setEditStudentIsSubmitting(false);
          if(e.response.data?.message) {
            setErrorMessage(e.response.data?.message)
          }
          toast.custom(
            <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm" style={{ width: '350px' }}>
                <div>
                    <FontAwesomeIcon icon="times-circle" size="2x" className="me-3 text-danger" />
                </div>
                <div>
                    <h6 className="fw-bold mb-0 text-danger">There's an error on updating the Student</h6>
                    <p className="mb-0">Please try again.</p>
                </div>
            </div>
        );
        })
        
    };


    return (
        <Modal isOpen={toEditStudentModalIsOpen} contentClassName='modal-size' centered={true}>
            <div className="modal-content border-0 overflow-hidden">
                
                <form autoComplete="off" onSubmit={editStudent}>
                    <div className="modal-header border-0">
                        <h6 className="modal-title fw-bold">Edit Student</h6>
                        <button 
                            type="button" 
                            className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                            style={{ right: '10px', top: '10px' }} 
                            disabled={editStudentIsSubmitting}
                            onClick={() => setToEditStudentModalIsOpen(false)}>
                            <img alt="" src={iconExit} />
                        </button>
                    </div>
                    <div className="modal-body">
                    {errorMessage !== "" &&
                        <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm">
                            <div>
                            <FontAwesomeIcon icon="exclamation-circle" size="2x" className="me-3 text-danger" />
                            </div>
                            <div>
                            <p className="mb-0">{errorMessage}</p>
                            </div>
                        </div>
                    }
                    
                    <div className="mb-2">
                        <label htmlFor="firstName" className="form-label fw-bold">First Name <span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        value={editStudentValues.firstname}
                        onChange={(e) => { setEditStudentValues({...editStudentValues, firstname: e.target.value}) }}
                        />
                    </div>
                    <div className="mb-2 row">
                        <div className='col-md-6 pe-1'>
                            <label htmlFor="lastName" className="form-label fw-bold">Last Name <span className="text-danger">*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                value={editStudentValues.lastname}
                                onChange={(e) => { setEditStudentValues({...editStudentValues, lastname: e.target.value}) }}
                            />
                        </div>
                        <div className='col-md-6 ps-1'>
                            <label htmlFor="lastName" className="form-label fw-bold">Extension   <span className="text-muted fst-italic">(ex. Jr, II. III)</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                value={editStudentValues.extension}
                                onChange={(e) => { setEditStudentValues({...editStudentValues, extension: e.target.value}) }}
                            />
                        </div>
                    </div>
                    <div className="mb-2">
                        <label htmlFor="middleName" className="form-label fw-bold">Middle Name <span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className="form-control"
                        id="middlename"
                        value={editStudentValues.middlename}
                        onChange={(e) => { setEditStudentValues({...editStudentValues, middlename: e.target.value}) }}
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="learnerReferenceNumber" className="form-label fw-bold">Learner Reference Number<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className="form-control"
                        id="learnerReferenceNumber"
                        value={editStudentValues.learnerReferenceNumber}
                        onChange={(e) => { setEditStudentValues({...editStudentValues, learnerReferenceNumber: e.target.value}) }}
                        />
                    </div>
                    <div className="mb-2">
                        <label htmlFor="email" className="form-label fw-bold">Email Address<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className="form-control"  
                        id="email"
                        value={editStudentValues.email}
                        onChange={(e) => { setEditStudentValues({...editStudentValues, email: e.target.value}) }}
                        />
                    </div>

                    
                    </div> 
                    <div className="modal-footer d-flex justify-content-end">
                        <button type="submit" className="btn btn-estorya" disabled={!canSaveEditStudent || editStudentIsSubmitting}>Save Changes</button>
                    </div>
                </form>
                
            </div>
        </Modal>
    )
}

export default EditStudentModal;
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconUser from './icon-user.svg'
import { useSelector } from 'react-redux';

function AddStudentNotificationPanel ({setShowAddStudentNotificationPanel, bulkCreationStudents}) {
    const { email } = useSelector(state => state.app.user);
    const [isOpen, setIsOpen] = useState(true)

    return(
        <div className='px-4' style={{position:'fixed', bottom:0, right:0, width:'400px', zIndex:'10'}}>
            <div className='border rounded-top bg-white'>
                <div className='p-3 d-flex justify-content-between align-items-center' style={{backgroundColor:'#CADBA6'}}>
                    <p className='mb-0 fw-bold'>Accounts Created</p>
                    <div>
                        <FontAwesomeIcon onClick={()=>setIsOpen(!isOpen)} icon={`${isOpen ? 'chevron-down' : 'chevron-up'}`}  className="me-3 cursor-pointer" />
                        <FontAwesomeIcon onClick={()=>setShowAddStudentNotificationPanel(false)} icon="xmark"  className="me-1" />
                    </div>
                </div>
                {isOpen &&
                    <>
                    <div className='d-flex justify-content-between align-items-center p-3' style={{backgroundColor:'#EFF1F7'}}>
                        <FontAwesomeIcon icon="check-circle" size="2x" className="me-3 text-estorya" />
                        <p className='mb-0'>
                            You have successfully added {bulkCreationStudents.length} students. The account credentials of the students will be sent to your Gmail account, <span className='text-estorya fw-bold'>{email}.</span>
                        </p>
                    </div>
                    <div className='overflow-auto' style={{maxHeight:'350px'}}>
                        {bulkCreationStudents.map((student, key)=>{
                            return(
                                <div key={key} className='d-flex justify-content-between align-items-center p-3 border-bottom'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <img className='me-2' src={iconUser} alt=''/>
                                        <p className='mb-0'>{student?.LASTNAME + ", " + student?.FIRSTNAME + " " + student?.MIDDLENAME + " " + student?.EXTENSION}</p>
                                    </div>
                                    <FontAwesomeIcon icon="check-circle" size="2x" className="text-estorya mx-1" />
                                </div>
                            )
                        })}
                        
                    </div>
                    </>
                }
                
            </div>
        </div>
    )
}

export default AddStudentNotificationPanel;
import React from 'react';
import iconExit from './icon-exit.svg';
import iconAddUser from './icon-add-user.svg';
import iconRoundPlus from  './icon-round-plus.svg'

function AddStudentOptions({closeModal, ADDSTUDENTMODES, selectedOption, setSelectedOption, setShowForm}) {
  
    return(
        <>
        <div className="modal-header border-0">
            <h6 className="modal-title fw-bold">Add New Stduent</h6>
            <button type="button" className="position-absolute btn btn-light btn-sm bg-transparent border-0" style={{ right: '10px', top: '10px' }} onClick={() => closeModal()}><img alt="" src={iconExit} /></button>
        </div>
        <div className="modal-body">
            <div className='mb-5 mt-3'>
                <p className='text-center mb-4'>First, let’s choose which method you’d like to start adding students.</p>
                <div className='row text-center'>
                    <div className='col'>
                        <div onClick={()=> setSelectedOption(ADDSTUDENTMODES.ADDSINGLE)} className={`border border-2 cursor-pointer pb-4 rounded-1 px-1 ${selectedOption === ADDSTUDENTMODES.ADDSINGLE || selectedOption === ADDSTUDENTMODES.ADDBULK ? 'border-active': ''}`}>
                            <div className={`rounded-circle border border-2  ${selectedOption === ADDSTUDENTMODES.ADDSINGLE || selectedOption === ADDSTUDENTMODES.ADDBULK ? 'border-active': 'border-secondary-subtle'}  mt-1`} style={{width:'14px', height:'14px'}}/>
                            <img alt='' className='mb-2' src={iconAddUser} style={{width:'21px'}}/>
                            <p className='fw-b mb-0'>Create account for student</p>
                        </div>
                    </div>
                    <div className='col'>
                        <div  onClick={()=> setSelectedOption(ADDSTUDENTMODES.ADDEXISTING)} className={`border border-2 cursor-pointer pb-4 rounded-1 px-1 ${selectedOption === ADDSTUDENTMODES.ADDEXISTING ? 'border-active': ''}`}>
                            <div className={`rounded-circle border border-2  ${selectedOption === ADDSTUDENTMODES.ADDEXISTING ? 'border-active': 'border-secondary-subtle'}  mt-1`} style={{width:'14px', height:'14px'}}/>
                            <img alt='' className='mb-2' src={iconRoundPlus} style={{width:'21px'}}/>
                            <p className='fw-b mb-0'>Add existing student account</p>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        <div className="modal-footer d-flex">
            <button 
                onClick={()=>setShowForm(true)}
                type="button" 
                className="btn btn-estorya" >Next
            </button>
        </div>
        </>
    )
}

export default AddStudentOptions;
import React, { useState } from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconExit from './../icon-exit.svg';
import { APIENDPOINT, axiosInstance } from '../../../../config';


const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

function SingleCreationForm ({ADDSTUDENTMODES, closeModal, showResult, setSelectedOption, classroomOptions}) {
    const defaultEmptyStudent = {
        classroom:'',
        firstname: '',
        lastname: '',
        middlename: '',
        extension: '',
        learnerReferenceNumber: '',
        email:'',
        role:'Student'
      };

    const token = useSelector(state => state.app.token);
    const [addStudentValues, setAddStudentValues] = useState(defaultEmptyStudent);
    const [addStudentIsSubmitting, setAddStudentIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")

    const canSaveAddStudent = (
      addStudentValues.firstname 
      && addStudentValues.lastname 
      && addStudentValues.middlename
      && addStudentValues.learnerReferenceNumber
      && addStudentValues.email 
      && addStudentValues.classroom
      && validateEmail(addStudentValues.email));

    const addStudent = async(e) => {
        e.preventDefault();
        setAddStudentIsSubmitting(true);
        setErrorMessage("")
        const formData = new FormData();
        formData.append("classroom_id", addStudentValues.classroom.value);
        formData.append("firstname", addStudentValues.firstname);
        formData.append("lastname", addStudentValues.lastname);
        formData.append("middlename", addStudentValues.middlename);
        formData.append("extension", addStudentValues.extension);
        formData.append("lrn_id", addStudentValues.learnerReferenceNumber);
        formData.append("role", addStudentValues.role);
        formData.append("email", addStudentValues.email);

       axiosInstance({
          url:`${APIENDPOINT}/import/users`,
          method: 'POST',
          data: formData,
          headers: { 'Authorization': `Bearer ${token}`}
        }).then((res)=>{
          showResult(res?.data?.user)
        }).catch((e)=>{
          setAddStudentIsSubmitting(false);
          if(e.response.data?.message) {
            setErrorMessage(e.response.data?.message)
          }
        })
        
    };

    return(
        <form autoComplete="off" onSubmit={addStudent}>
        <div className="modal-header border-0">
            <h6 className="modal-title fw-bold">Add New Student</h6>
            <button 
                type="button" 
                className="position-absolute btn btn-light btn-sm bg-transparent border-0" 
                style={{ right: '10px', top: '10px' }} 
                disabled={addStudentIsSubmitting}
                onClick={() => closeModal()}>
                <img alt="" src={iconExit} />
            </button>
        </div>
        <div className="modal-body">
          {errorMessage !== "" &&
             <div className="alert alert-light d-flex align-items-center justify-content-start shadow-sm">
                <div>
                  <FontAwesomeIcon icon="exclamation-circle" size="2x" className="me-3 text-danger" />
                </div>
                <div>
                  <p className="mb-0">{errorMessage}</p>
                </div>
              </div>
          }
         
          <div className="mb-2">
            <label htmlFor="firstName" className="form-label fw-bold">First Name <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              id="firstName"
              value={addStudentValues.firstname}
              onChange={(e) => { setAddStudentValues({...addStudentValues, firstname: e.target.value}) }}
            />
          </div>
          <div className="mb-2 row">
            <div className='col-md-6 pe-1'>
                <label htmlFor="lastName" className="form-label fw-bold">Last Name <span className="text-danger">*</span></label>
                <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={addStudentValues.lastname}
                    onChange={(e) => { setAddStudentValues({...addStudentValues, lastname: e.target.value}) }}
                />
            </div>
            <div className='col-md-6 ps-1'>
                <label htmlFor="lastName" className="form-label fw-bold">Extension   <span className="text-muted fst-italic">(ex. Jr, II. III)</span></label>
                <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    value={addStudentValues.extension}
                    onChange={(e) => { setAddStudentValues({...addStudentValues, extension: e.target.value}) }}
                />
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="middleName" className="form-label fw-bold">Middle Name <span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              id="middlename"
              value={addStudentValues.middlename}
              onChange={(e) => { setAddStudentValues({...addStudentValues, middlename: e.target.value}) }}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="learnerReferenceNumber" className="form-label fw-bold">Learner Reference Number<span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"
              id="learnerReferenceNumber"
              value={addStudentValues.learnerReferenceNumber}
              onChange={(e) => { setAddStudentValues({...addStudentValues, learnerReferenceNumber: e.target.value}) }}
            />
          </div>
          <div className="mb-2">
            <label htmlFor="email" className="form-label fw-bold">Email Address<span className="text-danger">*</span></label>
            <input
              type="text"
              className="form-control"  
              id="email"
              value={addStudentValues.email}
              onChange={(e) => { setAddStudentValues({...addStudentValues, email: e.target.value}) }}
            />
          </div>

          <div className="mb-2">
            <label htmlFor="classroom" className="form-label fw-bold">Assign Classroom<span className="text-danger">*</span></label>
            <Select
              value={addStudentValues.classroom}
              onChange={(e)=> {setAddStudentValues({...addStudentValues, classroom: e}) }}
              options={classroomOptions}
              menuPlacement="top"
            />
          </div>
         
        </div> 
        <div className="modal-footer d-flex justify-content-between">
            <p onClick={()=>{
              if(!addStudentIsSubmitting) {
                setSelectedOption(ADDSTUDENTMODES.ADDBULK)
              }
              
            }} className='mb-0 text-estorya cursor-pointer'>
                Add multiple students?
            </p> 
            <button type="submit" className="btn btn-estorya" disabled={!canSaveAddStudent || addStudentIsSubmitting}>Save a Student</button>
        </div>
      </form>
    )
}

export default SingleCreationForm;